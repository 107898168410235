import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import {
    withStyles,
    Container,
    Fab
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LayersIcon from '@material-ui/icons/Layers';
import LanguageTabs from './../../../components/LanguageTabs';
import Form from './../Form'
import LoadingScreen from './../../../components/LoadingScreen'
import * as apiService from './../../../utils/api';



const Edit = ({
    classes,
    match
}) => {

    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [languageTab, setLanguageTab] = useState('pt')
    const [product, setProduct] = useState(null)
    const [loading, setLoading] = useState(true)

    const onChangeLanguageHandler = (lng) => {
        setLanguageTab(lng.id)
    }


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const id = match.params.id
            try {
                const resp = await apiService.getProduct(id)
                setProduct(resp.data.content)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchData()
        return () => {
            //
        }
    }, [match.params.id])

  


    if (loading) {
        return <LoadingScreen />
    }
    



    return (
        <div className={classes.root}>
            <div className={classes.iconBox}>
                <LayersIcon />
            </div>
            <div className={classes.headerBox}>
                <h3>{t('download')}</h3>
                <Fab onClick={() => history.push('/products')}>
                    <ArrowBackIcon />
                </Fab>
            </div>
            <div className={classes.contentBox}>
                <Container maxWidth="xl">
                    <LanguageTabs onChangeLanguage={onChangeLanguageHandler} />
                    <Form lng={languageTab} product={product} edit={true}/>
                </Container>
            </div>
        </div>
    )
}

Edit.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Edit)
