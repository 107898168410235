import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import {
    withStyles,
    Container,
    FormGroup,
    FormControlLabel,
    Switch,
    Fab
} from '@material-ui/core';
import BookIcon from '@material-ui/icons/Book';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LanguageTabs from './../../../components/LanguageTabs';
import Form from './../Form'
import LoadingScreen from './../../../components/LoadingScreen'
import * as apiService from './../../../utils/api';



const Edit = ({
    classes,
    match
}) => {
    const { t } = useTranslation();
    const [languageTab, setLanguageTab] = useState('pt')
    const [training, setTraining] = useState(null)
    const [directLink, setDirectLink] = useState(false)
    const [loading, setLoading] = useState(true)
    const history = useHistory();


    const onChangeLanguageHandler = (lng) => {
        setLanguageTab(lng.id)
    }


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const id = match.params.id
            try {
                const resp = await apiService.getTraining(id)
                setTraining(resp.data.content)
                const directLink = resp.data.content.type === 'external'
                setDirectLink(directLink)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchData()
        return () => {
            //
        }
    }, [match.params.id])


    if (loading) {
        return <LoadingScreen />
    }


    return (
        <div className={classes.root}>
            <div className={classes.iconBox}>
                <BookIcon />
            </div>
            <div className={classes.headerBox}>
                <h3>{t('trainings')}</h3>
                <Fab onClick={() => history.push('/training')}>
                    <ArrowBackIcon />
                </Fab>
            </div>
            <div className={classes.contentBox}>
                <Container maxWidth="xl">
                    <LanguageTabs onChangeLanguage={onChangeLanguageHandler} />
                    <Form lng={languageTab} directLink={directLink} edit={true} training={training} />
                </Container>
            </div>
        </div>
    )
}

Edit.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Edit)
