import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { compose } from 'recompose';
import moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/es';
import 'moment/locale/pl';
import styles from './styles';
import {
    withStyles,
    IconButton,
    Tooltip,
    Dialog
} from '@material-ui/core';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import InfoIcon from '@material-ui/icons/Info';
import BookIcon from '@material-ui/icons/Book';
import CloseIcon from '@material-ui/icons/Close';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import Info from './InfoUser'
import Weighings from './Weighings'
import Diary from './Diary'
import * as apiService from './../../../../../utils/api';


const RecMessages = ({
    classes,
    user,
    msg,
    onRefreh
}) => {


    const { t, i18n } = useTranslation();
    const history = useHistory();
    moment.locale(i18n.language);
    const [showInfo, setShowInfo] = useState(false)
    const [showWeighings, setShowWeighings] = useState(false)
    const [showDiary, setShowDiary] = useState(false)
    const [showMsg, setShowMsg] = useState(false)
    const [currentMsg, setCurrentMsg] = useState(null)
    const [_msg, set_Msg] = useState(null)




    useEffect(() => {
        set_Msg(msg)
    }, [msg])



    const handleClickReplyMessage = () => {
        history.push({
            pathname: `/client/${user._id}/message/reply`,
            state: { message: msg }
        })
    }

    const handleClickSeeInfoUser = () => {
        setShowWeighings(false)
        setShowDiary(false)
        setShowInfo(true)
    }

    const handleClickSeeInfoWeighings = () => {
        setShowDiary(false)
        setShowInfo(false)
        setShowWeighings(true)
    }

    const handleClickSeeInfoDiary = () => {
        setShowWeighings(false)
        setShowInfo(false)
        setShowDiary(true)
    }

    const onReadHandler = async (msg) => {
        setCurrentMsg(msg)
        setShowMsg(true)
        try {
            if (!msg.read) {
                //await maskAsRead(user._id)
                if (msg.receiver.email === 'admin') {
                    await apiService.markMsgAsRead(msg._id)
                }
                set_Msg({ ..._msg, read: true })
                onRefreh()
            }
          
        
        } catch (error) {}
    }


    const maskAsRead = async (id) => {
        await apiService.markAsRead(id)

    }

    const renderInfoUser = () => {
        return (
            <Dialog maxWidth="lg"
                open={true}
                onClose={() => setShowInfo(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className={classes.buttonCloseArea}>
                    <IconButton className={classes.buttonClose} size="small" onClick={() => setShowInfo(false)}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </div>
                <Info user={user} />
        </Dialog>
        )
    }

    const renderWeighings = () => {
        return (
            <Dialog maxWidth="xl"
                fullWidth
                open={true}
                onClose={() => setShowWeighings(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className={classes.buttonCloseArea}>
                    <IconButton className={classes.buttonClose} size="small" onClick={() => setShowWeighings(false)}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </div>
              
                <Weighings userId={user._id} />
            </Dialog>
        )
    }

    const renderDiary = () => {
        return (
            <Dialog maxWidth="xl"
                fullWidth
                open={true}
                onClose={() => setShowDiary(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className={classes.buttonCloseArea}>
                    <IconButton className={classes.buttonClose} size="small" onClick={() => setShowDiary(false)}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </div>
                <Diary userId={user._id} />
            </Dialog>
        )
    }


    const renderMsg = () => {
        return (
            <Dialog maxWidth="md"
                fullWidth
                open={true}
                onClose={() => setShowMsg(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className={classes.buttonCloseArea}>
                    <IconButton className={classes.buttonClose} size="small" onClick={() => setShowMsg(false)}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </div>
                <div style={{padding: '20px 30px'}}>
                    {currentMsg.text}
                </div>
            </Dialog>
        )
    }


    if(!_msg) {
        return null
    }


    return (
        <div className={classes.root}>
            <div className={classes.dateBox}>
                {moment(_msg.createdAt).format('L')}
            </div>
            <div className={classes.content}>
                <div  className={clsx(
                    { [classes.bodyMessage]: true },
                    { [classes.noRead]: (!_msg.read && _msg.receiver.email === 'admin') }
                )}
                    onClick={() => onReadHandler(_msg)}
                >
                    {_msg.text}
                </div>
                <div className={classes.boxActions}>
                    <Tooltip title={t('reply')}>
                        <IconButton size="small" onClick={handleClickReplyMessage}>
                            <ReplyAllIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('info user')}>
                        <IconButton size="small" onClick={handleClickSeeInfoUser}>
                            <InfoIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('weighings')}>
                        <IconButton size="small" onClick={handleClickSeeInfoWeighings}>
                            <AccessibilityIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('diary')}>
                        <IconButton size="small" onClick={handleClickSeeInfoDiary}>
                            <BookIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            {showInfo ? renderInfoUser() : null}
            {showWeighings ? renderWeighings() : null}
            {showDiary ? renderDiary() : null}
            {showMsg ? renderMsg() : null}
        </div>
    )
}

RecMessages.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(RecMessages)
