const classes = theme => ({
    root: {},
    success: {
        backgroundColor: 'green',
        color: 'white'
    },
    error: {
        backgroundColor: 'red',
        color: 'white'
    },
    dialogContent: {
         paddingTop: '30px'
     }
})
export default classes;