import React, { useRef, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Context } from './../../context';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import { 
    withStyles,
    Button,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import flagPolandIcon from '../../assets/icons/poland.png';
import flagPortugalIcon from '../../assets/icons/portugal.png';
import flagSpainIcon from '../../assets/icons/spain.png';
import flagUnitedKingdomIcon from '../../assets/icons/united-kingdom.png';
import { getLanguageUser, setLanguageUser } from '../../utils/storage'


const languages = [
    { id: 'en', name: 'english', icon: <img src={flagUnitedKingdomIcon} alt="" /> },
    { id: 'pt', name: 'portuguese', icon: <img src={flagPortugalIcon} alt="" />  },
    { id: 'es', name: 'spanish', icon: <img src={flagSpainIcon} alt="" /> },
    { id: 'pl', name: 'poland', icon: <img src={flagPolandIcon} alt="" /> }
]



const LanguageMenu = ({
    classes,
}) => {

    const { t, i18n } = useTranslation();
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [language, setLanguage] = useState({})


    useEffect(() => {
        const lng = languages.find(item => item.id === i18n.language)
        setLanguage(lng)
        return () => {
            //
        }
    }, [i18n.language])

    useEffect(() => {

        const setup = async () => {
            const lastLng = await getLanguageUser()
            if (lastLng) {
                i18n.changeLanguage(lastLng);
            }
        }
        setup()
        return () => {
            //
        }
    }, [])

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose =   (lang) => async (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
        i18n.changeLanguage(lang.id);
        await setLanguageUser(lang.id)
    };

 

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className={classes.root}>
                <Button
                    className={classes.buttonMenu}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    endIcon={<ArrowDropDownIcon />}
                    startIcon={language.icon}
                    >
                    {t(language.name)}
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 10}}>
                {({ TransitionProps, placement }) => (
                    <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center center' }}
                    >
                    <Paper>
                        <MenuList autoFocusItem={open} id="menu-list-grow" className={classes.menuList}>
                                    {languages.map(lang => (
                                        <MenuItem key={lang.id} onClick={handleClose(lang)}>
                                            <div className={classes.boxFlagLanguage}>{lang.icon}</div>
                                            <span>{t(lang.name)}</span>
                                        </MenuItem>
                            ))}
                        </MenuList>
                    </Paper>
                    </Grow>
                )}
                </Popper>
            </div>
        </ClickAwayListener>
    )
}

LanguageMenu.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(LanguageMenu)
