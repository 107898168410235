
export default theme => ({
    root: {
        padding: '15px 30px',
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
    },
    trasnparentBg: {
        backgroundColor: 'transparent',
        textShadow: '1px 1px 2px black, 0 0 25px #b7b7b7, 0 0 5px #9a9696',
        color: 'white',
        '& a': {
            color: 'white'
        }
    }
})