import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import { getToken, getUser, sessionIsExpired } from './../../utils/storage';
import { Context } from './../../context';
import * as actionTypes from './../../actions';


const CheckAuth = ({
    classes
}) => {

    const { state, dispatch } = useContext(Context);
    const history = useHistory();


    useEffect(() => {
        const checkAuthentication = async () => {
            const token = await getToken();
            const user = await getUser();
            const isSessionExpired = await sessionIsExpired()
            if (token && !isSessionExpired) {
                dispatch({ type: actionTypes.SESSION_CHECK })
                dispatch({ type: actionTypes.SESSION_LOGIN, payload: {user, token} })
                history.push('/')
            }
            if (isSessionExpired) {
                dispatch({ type: actionTypes.SESSION_LOGOUT })
                dispatch({ type: actionTypes.SESSION_CHECK })
                history.push('auth/login')
            }
        }
        checkAuthentication()
        return () => {
            //
        }
    }, [])

    return (
        <> </>
    )
}

CheckAuth.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(CheckAuth)
