import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import clsx from 'clsx';
import styles from './styles';
import { withStyles, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import UserIcon from '@material-ui/icons/PeopleAlt';
import StepPurchasedIcon from '@material-ui/icons/ShoppingBasket';
import ClientPurchasedStepIcon from '@material-ui/icons/GroupAdd';
import TotalMessageRevievedIcon from '@material-ui/icons/Feedback';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsNone';
import ChallengesIcon from '@material-ui/icons/DirectionsRun';
import TrainingIcon from '@material-ui/icons/DirectionsWalk';
import BLogIcon from '@material-ui/icons/Book';
import NewsIcon from '@material-ui/icons/EmojiPeople';
import DownloadIcon from '@material-ui/icons/LocalLibrary';
import LoadingScreen from './../../components/LoadingScreen';
import Card from './components/Card';
import MiniCard from './components/MiniCard';
import MessageIcon from '@material-ui/icons/Email';
import * as apiService from './../../utils/api';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, LineChart, Line } from 'recharts';


const months = ['Jan', 'Feb', 'Mar',  'Apr',  'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];



const Dashboard = ({
  classes
}) => {

   const { t, i18n } = useTranslation();


    const miniCards1 = [
      { id: 'user', icon: <UserIcon />, title: 'total users'},
      { id: 'step purchased', icon: <StepPurchasedIcon />, title: 'total of steps purchased' },
      { id: 'purcashed client', icon: <ClientPurchasedStepIcon />, title: 'clients who purchased steps' },
      { id: 'message received', icon: <TotalMessageRevievedIcon />, title: 'total messages recieved' },
    ]
    const miniCards2 = [
      { id: 'notifications active', icon: <NotificationsActiveIcon />, title: 'active notifications' },
      { id: 'challenges', icon: <ChallengesIcon />, title: 'challenges' },
      { id: 'training', icon: <TrainingIcon />, title: 'training' },
      { id: 'bLog', icon: <BLogIcon />, title: 'blogs' },
      { id: 'news', icon: <NewsIcon />, title: 'news' },
      { id: 'download', icon: <DownloadIcon />, title: 'download' },
      { id: 'product', icon: <TotalMessageRevievedIcon />, title: 'products' },

  ]
  
  const [loading, setLoading] = useState(true)




  const [totalUsers, setTotalUsers] = useState(0)
  const [totalStepUnlocks, setTotalStepUnlocks] = useState(0)
  const [payingUsers, setPayingUsers] = useState(0)
  const [totalMessages, setTotalMessages] = useState(0)
  // notificões activas
  const [challengesCount, setChallengesCount] = useState(0)
  const [trainingsCount, setTrainingsCount] = useState(0)
  const [blogCount, setBlogCount] = useState(0)
  const [newsCount, setNewsCount] = useState(0)
  const [downloadsCount, setDownloadsCount] = useState(0)
  const [productCount, setProductCount] = useState(0)

  const [mostViewedChallenge, setMostViewedChallenge] = useState(null)
  const [mostViewedBlog, setMostViewedBlog] = useState(null)
  const [mostViewedTraining, setMostViewedTraining] = useState(null)

  const [usersByStep, setUsersByStep] = useState(null)
  const [userStepsByMonth, setUserStepsByMonth] = useState(null)


  const [dataChartBar, setDataChartBar] = useState([])
  const [dataChartLine, setDataChartLine] = useState([])

  const [unreadMessages, setUnreadMessages] = useState(0)


  
  


    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true)
          const resp = await apiService.getDashboard()
          const dashboard = resp.data.content
     
          setTotalUsers(dashboard.totalUsers)
          setTotalStepUnlocks(dashboard.totalStepUnlocks)
          setPayingUsers(dashboard.payingUsers)
          setTotalMessages(dashboard.totalMessages)
          // Set notifications 
          setChallengesCount(dashboard.challengesCount)
          setTrainingsCount(dashboard.trainingsCount)
          setBlogCount(dashboard.blogCount)
          setNewsCount(dashboard.newsCount)
          setDownloadsCount(dashboard.downloadsCount)
          setProductCount(dashboard.productCount)

          setMostViewedChallenge(dashboard.mostViewedChallenge)
          setMostViewedBlog(dashboard.mostViewedBlog)
          setMostViewedTraining(dashboard.mostViewedTraining)

          setUsersByStep(dashboard.usersByStep)

          setUserStepsByMonth(dashboard.userStepsByMonth)

          setUnreadMessages(dashboard.unreadMessages)

          setLoading(false)

        } catch (error) {
          setLoading(false)
        }
      }
      fetchData()
      return () => {
        //
      }
    }, [])
  
  
  useEffect(() => {
    if (usersByStep) {
      const keys = Object.keys(usersByStep)
      const datas = keys.map(key => {
        const dt = {
          name: usersByStep[key].[`title_${i18n.language}`],
          users: usersByStep[key].userCount,
         }
        return dt;
      })
      setDataChartBar(datas)
    }

    if (userStepsByMonth) {
      const datas = months.map( (month, index )=> {
        const dt = {
          name: t(month),
          users: userStepsByMonth[index] ? userStepsByMonth[index] : 0,
        }
        return dt;
      })
      setDataChartLine(datas)
    }
  }, [usersByStep, userStepsByMonth, i18n.language, t])


  if (loading) {
    return <LoadingScreen />
  }





    return (
      <div className={classes.root}>
           <div className={classes.cardHeaderBox}>
              <h4>{t('messages')}:</h4>
              <Button
                  variant="contained"
                  disableElevation={true}
                  className={clsx({ [classes.buttonCard]: true })}
                 startIcon={<MessageIcon />}
                >
                {unreadMessages}  {t('no read messages')}
              </Button>
            </div>
        <div className={classes.containerCardView}>
          <MiniCard id={miniCards1[0].id} icon={miniCards1[0].icon} title={miniCards1[0].title} data={totalUsers}/>
          <MiniCard id={miniCards1[1].id} icon={miniCards1[1].icon} title={miniCards1[1].title} data={totalStepUnlocks}/>
          <MiniCard id={miniCards1[2].id} icon={miniCards1[2].icon} title={miniCards1[2].title} data={payingUsers}/>
          <MiniCard id={miniCards1[3].id} icon={miniCards1[3].icon} title={miniCards1[3].title} data={totalMessages} />
          <MiniCard id={miniCards2[0].id} icon={miniCards2[0].icon} title={miniCards2[0].title} data={totalUsers} />
          <MiniCard id={miniCards2[1].id} icon={miniCards2[1].icon} title={miniCards2[1].title} data={challengesCount} />
          <MiniCard id={miniCards2[2].id} icon={miniCards2[2].icon} title={miniCards2[2].title} data={trainingsCount} />
          <MiniCard id={miniCards2[3].id} icon={miniCards2[3].icon} title={miniCards2[3].title} data={blogCount} />
          <MiniCard id={miniCards2[4].id} icon={miniCards2[4].icon} title={miniCards2[4].title} data={newsCount} />
          <MiniCard id={miniCards2[5].id} icon={miniCards2[5].icon} title={miniCards2[5].title} data={downloadsCount} />
          <MiniCard id={miniCards2[6].id} icon={miniCards2[6].icon} title={miniCards2[6].title} data={productCount} />
        </div>
      
        <div className={classes.containerCardChartBar}>
          <div className={classes.chartBarBox} >
            <BarChart
              width={1000}
              height={300}
              data={dataChartBar}
              margin={{
                top: 50,
                right: 30,
                left: 20,
                bottom: 50,
              }}
              barSize={60}
            >
              <CartesianGrid stroke="#FFF" strokeDasharray="3 3" />
              <XAxis dataKey="name" stroke="#FFF" />
              <YAxis stroke="#FFF"/>
              <Legend verticalAlign="top" height={36} />
              <Bar dataKey="users" fill="#FFF" name={t('clients')}/>
            </BarChart>
          </div>
          <div className={classes.infoBox}>
            <div className={classes.infoText}>
              <p>{t('steps acquisition')}</p>
            </div>
            <div className={classes.viewBox}>
              {t('updated now')}
            </div>
          </div>
        </div>

          <div className={classes.containerCardChartBar}>
          <div className={classes.chartLineBox} >
            <LineChart
              width={1000}
              height={300}
              data={dataChartLine}
              margin={{
                top: 50,
                right: 30,
                left: 20,
                bottom: 50,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" stroke="#FFF"/>
              <YAxis stroke="#FFF"/>
              <Legend />
              <Line type="monotone" dataKey="users" stroke="#FFFFFF" activeDot={{ r: 8 }} name={t('clients')}/>
            </LineChart>
          </div>
          <div className={classes.infoBox}>
            <div className={classes.infoText}>
              <p>{t('evolution by months')}</p>
            </div>
            <div className={classes.viewBox}>
              {t('updated now')}
            </div>
          </div>
        </div>
    
        <div className={classes.containerCardView}>
          <Card data={mostViewedChallenge} />
          <Card data={mostViewedBlog} />
          <Card data={mostViewedTraining} />

        </div>
      </div>
    )
  }

  Dashboard.propTypes = {
    classes: PropTypes.object
  }

  export default compose(
    withStyles(styles)
  )(Dashboard)

