import React, { useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import { Context } from './../../context';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import {
    Container,
    withStyles
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LanguageTabs from './../../components/LanguageTabs';
import Form from './../../components/FormPageContent';
import LoadingScreen from './../../components/LoadingScreen';
import * as apiService from './../../utils/api'
import * as actionTypes from './../../actions';



const TermsAndConditions = ({
    classes
}) => {

    const { t } = useTranslation();
    const { state, dispatch } = useContext(Context);
    const [languageTab, setLanguageTab] = useState('pt')
    const [loading, setLoading] = useState(true)
    const [termsAndConditions, setTermsAndConditions] = useState(null)


    const onChangeLanguageHandler = (lng) => {
        setLanguageTab(lng.id)
    }

    const onSaveHandle = async (data) => {
        try {
            await apiService.createTerms(data)
            dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })
        } catch (error) {
            dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('updated error') } })
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const resp = await apiService.getTerms()
                const term = resp.data.content
                setTermsAndConditions(term)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchData()
        return () => {
            //
        }
    }, [])



    if (loading) {
        return <LoadingScreen />
    }


   
    return (
        <div className={classes.root}>
            <div className={classes.iconBox}>
                <AssignmentIcon />
            </div>
            <div className={classes.headerBox}>
                <h3>{t('terms and conditions')}</h3>
            </div>
            <div className={classes.contentBox}>
                <Container maxWidth="xl">
                    <LanguageTabs onChangeLanguage={onChangeLanguageHandler} />
                    <Form lng={languageTab} onSave={onSaveHandle} data={termsAndConditions}/>
                </Container>
            </div>
        </div>
    )
}

TermsAndConditions.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(TermsAndConditions)
