import { createMuiTheme } from '@material-ui/core';

import overrides from './overrides';
import palette from './palette';

const theme = createMuiTheme({
    overrides,
    palette
});

export default theme;
