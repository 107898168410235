
const API_URL = process.env.REACT_APP_DOMAIN;

export const toBase64 = async file => new Promise((resolve, reject) => {
    if (Object.keys(file).length === 0 && file.constructor === Object) {
        resolve(null)
    } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => { resolve(reader.result) }
        reader.onerror = error => resolve(null);
    }
});



export const toDataURL = url => fetch(url, { 'content-type': 'image/png' })
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
    }))


export const getFileDto = dataFile => new Promise((resolve, reject) => {
    try {
        if (!dataFile) {
            resolve(null)
            return
        } if (dataFile.hasOwnProperty('_id')) { 
            resolve(dataFile)
        } else if (!dataFile.hasOwnProperty('file') && !dataFile.file) {
            resolve(null)
            return 
        } else {
            const data = {
                type: dataFile.type,
                file: dataFile.file,
                filename: dataFile.filename
            }
            resolve(data)
        }
    } catch (error) {  }
  

   
})

export const setupImage = image => fetch(`${API_URL}/warehouse/${image.filename}`, { 'content-type': image.type ? image.type : 'image/png' })
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
    }))
    .catch(error => {
        return null
    })

export const getImages = (images) => {
    const lng = ['pt', 'en', 'es', 'pl']
    const data = {}
    for (let index = 0; index < lng.length; index++) {
        const image = images[`image_${lng[index]}`]
        if (!image) {
            data[`image_${lng[index]}`] = null
        } else if (image && !image.hasOwnProperty('_id')) {
            data[`image_${lng[index]}`] = images[`image_${lng[index]}`]
        }
    }
    return data
}

export const getRecipes = (recipes) => {
    const rcps = recipes.map(recipe => {
        let rec = { ...recipe }
        if (!recipe.image) {
            rec['image'] = null
        } else if (recipe.image && !recipe.image.hasOwnProperty('_id')) {
            rec['image'] = recipe.image
        }
        return rec
    })
    return rcps;
}


export const getfiles = (files) => {
    const lng = ['pt', 'en', 'es', 'pl']
    const data = {}
    for (let index = 0; index < lng.length; index++) {
        const file = files[`file_${lng[index]}`]
        if (!file) {
            data[`file_${lng[index]}`] = null
        } else if (file && !file.hasOwnProperty('_id')) {
            data[`file_${lng[index]}`] = files[`file_${lng[index]}`]
        }
    }
    return data
}


export const getIdYoutubeUrl = (url) => {
    var video_id = url.split('v=')[1];
    if (!video_id) {
        return ''
    }
    var ampersandPosition = video_id.indexOf('&');
    if (ampersandPosition !== -1) {
        video_id = video_id.substring(0, ampersandPosition);
    }
    return video_id
}

