import jwt_decode from 'jwt-decode';

const {
    getItem,
    setItem,
    removeItem
} = require('localforage');


export const setToken = async token => await setItem('token', token);

export const getToken = async () => await getItem('token');


export const getLanguageUser = async () => await getItem('lng');

export const setLanguageUser = async lng => await setItem('lng', lng);

export const deleteToken = async () => await removeItem('token');

export const decodeToken = async token => jwt_decode(token);

export const sessionIsExpired = async () => {
    const token = await getToken();
    if (!token) {
        return true
    }
    const tokenData = await decodeToken(token);
    const { exp } = tokenData;
    return (exp * 1000) < Date.now() ? true : false;
}
 
export const setUser = async data => await setItem('user', data);

export const getUser = async () => await getItem('user');

export const deleteUser = async () => await removeItem('user')
