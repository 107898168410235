import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import {
    withStyles,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';



const Modal = ({
    classes,
    title,
    open,
    onClose,
    children
}) => {
    return (
        <Dialog onClose={onClose} aria-labelledby="dialog-generate-step" open={open} maxWidth="md" fullWidth  >
            <MyDialogTitle id="dialog-generate-step" onClose={onClose}>
                {title}
                        </MyDialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    )
}

Modal.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Modal)

const MyDialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <DialogTitle disableTypography className={classes.dialogTitleRoot} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButtonDialogTitle} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
});


