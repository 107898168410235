
const classes =  theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        margin: '30px 0 0'
    },
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16,
        justifyContent: 'center'
    },
    thumb: {
        position: 'relative',
        padding: '5px',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        margin: '0 8px 5px 5px',
        width: 150,
        height: 150,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        width: '100%',
        overflow: 'hidden',
        marginBottom: '5px',
        '& img': {
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    },
    closeBox: {
        cursor: 'pointer'
    },
    dropzone: {
        border: '1px dashed rgba(0, 0, 0, 0.12)',
        display: 'flex',
        outline: 'none',
        padding: '48px',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer',
            opacity: 0.8,
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
    },
    iconBox: {
        margin: '0 20px 10px',
        '& img': {
            width: '90px',
            objectFit: 'cover'
        }
    },
    infoBox: {
        '& h4': {
            fontWeight: 600,
            lineHeight: 1.167,
            marginBottom: '10px',
            textAlign: 'center'
        },
        '& p': {
            fontWeight: 500,
            lineHeight: 1.5,
            letterSpacing: '0.00938em'
        }
    },
    pdfInfoBox: {
        padding: '20px',
        fontWeight: 600
    },
    fileInfo: {
        display: 'flex',
        color: '#cc3024',
        '& p': {
            marginRight: '10px',
            marginTop: '0',
            marginBottom: '0'
        },
        '& svg': {
            fontWeight: 700,
            color: 'red'
        }
    }
})
export default classes;
