import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useDropzone } from 'react-dropzone';
import styles from './styles';
import {
    withStyles,
    Card,
    CardHeader,
    CardContent,
    Divider,
    IconButton
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import imageDragFile from './../../assets/images/add-files.svg';

const API_URL = process.env.REACT_APP_DOMAIN;

const FileDropzone = ({
    classes,
    disabled,
    title,
    onAcceptFiles,
    maxFiles,
    pdf,
    data
}) => {

    const { t } = useTranslation();

    const [files, setFiles] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: maxFiles ? maxFiles : 1,
        accept: pdf ? 'application/pdf' : 'image/jpeg, image/png, image/jpg',
        disabled: disabled,
        onDrop: async acceptedFiles => {
            let files;
            if (!pdf) {
                 files = acceptedFiles.map(file =>  Object.assign(file, {
                     preview: URL.createObjectURL(file)
                    })
                  );
            }
            else { // pdf
                files = acceptedFiles
            }
            if (maxFiles) {
                setFiles(previous => [...previous, ...files])
            } else {
                setFiles([...files])
            }
            onAcceptFiles(acceptedFiles)
        },
        onDropRejected: rejectedFiles => { 
            //console.log(rejectedFiles)
        }
    });

    const removeFileHandler = (file, index) => {
        const filesCopy = [...files];
        const fls = filesCopy.filter(fl => fl.preview !== file.preview)
        setFiles(fls)
        onAcceptFiles(fls)
    }


   

    const renderThumbs = () => {
        const thumbs = files.map((file, index) => {
            return (
                <div className={classes.thumb} key={file.preview}>
                    <div className={classes.thumbInner}>
                        <img src={file.preview} alt="" />
                    </div>
                    <div className={classes.closeBox}>
                        <DeleteForeverIcon onClick={() => removeFileHandler(file, index)} />
                    </div>
                </div>
            )
        });
        return (
            <aside className={classes.thumbsContainer}>
                {thumbs}
            </aside>
        )
    }

    const renderPdf = () => {
        const filePdf = files[0]
        return (
            <div className={classes.pdfInfoBox}>
                {filePdf ? (
                    <div className={classes.fileInfo}>
                        { filePdf.hasOwnProperty('_id') ? (
                            <p>{t('file name')}:&nbsp;{filePdf.filename}</p>
                        ): (
                            <p>{ t('file name') }:&nbsp;{filePdf.name}</p>
                        )}
                        <IconButton aria-label="delete" size="small" onClick={() => removeFileHandler(filePdf, 0)}>
                            <ClearIcon fontSize="inherit" />
                        </IconButton>
                    </div>    
                ) : null}
            </div>
        )
    }

    useEffect(() => {
        try {
            if (data) {
                if (!pdf) {
                    const file = {
                        preview: data.preview ? data.preview : `${API_URL}/warehouse/${data.filename}`,
                        ...data
                    }
                    const fls = [];
                    fls.push(file)
                    setFiles(fls)
                } else {
                    const file = {
                        ...data
                    }
                    const fls = [];
                    fls.push(file)
                    setFiles(fls)
                }
            }
        } catch (error) {
            return null
        }
    }, [data])


    return (
        <div className={classes.root}>
            <Card style={{width: '100%'}}>
                <CardHeader title={title} ></CardHeader>
                <Divider/>
                <CardContent>
                    <div className={classes.cardContentRoot}>
                        <section className="container">
                            <div {...getRootProps({ className: classes.dropzone})}>
                                <input {...getInputProps()} />
                                <div className={classes.iconBox}>
                                    <img src={imageDragFile} alt="" />
                                </div>
                                <div className={classes.infoBox}>
                                    <h4>{t('select files')}</h4>
                                    <p>{t('drag files info')}</p>
                                </div>
                            </div>
                            {pdf ? renderPdf() : renderThumbs()}
                        </section>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

FileDropzone.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
    onAcceptFiles: PropTypes.func
}

export default compose(
    withStyles(styles)
)(FileDropzone)
