import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';

const Template = ({
  classes,
  transparent
}) => {
  return (
    <div className={
      clsx({ [classes.trasnparentBg]: transparent }, { [classes.root]: true})}>
      &copy;
      { (new Date().getFullYear())}&nbsp;, made with &nbsp;<FavoriteIcon /> &nbsp;by&nbsp;
      < a href="https://www.losangomultimedia.com" target="_blank" rel="noreferrer" >&nbsp;Losango Multimedia</a >&nbsp;for FitGym
    </div >
  )
}

Template.propTypes = {
  classes: PropTypes.object
}

export default compose(
  withStyles(styles)
)(Template)
