import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import backgroundImage from './../../../../assets/images/fallback-background-card.jpg';
import { useTranslation } from 'react-i18next';


const API_URL = process.env.REACT_APP_DOMAIN;


const Card = ({
    classes,
    data
}) => {

    const { t, i18n } = useTranslation();


    if(!data) {
        return null
    }

    return (
        <div className={classes.root}>
            <div className={classes.imageBox}>
                {data.image_pt ? (
                    <img src={`${API_URL}/warehouse/${data.image_pt.filename}`} alt="" />
                ) : <img src={backgroundImage} alt="" />}
            </div>
            <div className={classes.infoBox}>
                <div className={classes.infoText}>
                    <p>{data[`title_${i18n.language}`]}</p>
                </div>
                <div className={classes.viewBox}>
                    <EyeIcon />
                    <span>{data.views} {t('views')}</span>
                </div>
            </div>
       </div>
    )
}

Card.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Card)
