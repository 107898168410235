import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types'
import {
    withStyles,
    Typography,
} from '@material-ui/core'
import styles from './styles';
import NavigationList from './components/NavigationList';

const Navigation = ({
    classes,
    list
}) => {

    const { t } = useTranslation();
    const { title, pages, } = list;

    return (
        <div>
            {title && <Typography variant="overline">{t(title)}</Typography>}
            <NavigationList
                depth={0}
                pages={pages} />
        </div>  
  )
}


Navigation.propTypes = {
    classes: PropTypes.object,
    list: PropTypes.array
}

export default (
    withStyles(styles)
)(Navigation)
