import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { 
    withStyles,
    Grid,
    Box,
    Switch,
    Fab
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import LayersIcon from '@material-ui/icons/Layers';
import Table from '../../../components/Table'
import EditIcon from '@material-ui/icons/Edit';
import LoadingScreen from '../../../components/LoadingScreen'
import * as apiService from '../../../utils/api'

const API_URL = process.env.REACT_APP_DOMAIN;


const List = ({
    classes
}) => {

    const { t, i18n } = useTranslation();
    const history = useHistory();
    const confirm = useConfirm();
    const [data, setData] = useState([])
    const [recipes, setRecipes] = useState([])
    const [loading, setLoading] = useState(true)


    const actions = [
        {
            icon: 'delete',
            tooltip: t('delete'),
            onClick: (event, rowData) => { handleDeleteClick(rowData) }
        },
        {
            icon: () => <EditIcon />,
            tooltip: t('detail'),
            onClick: (event, rowData) => {
                history.push(`/recipes/edit/${rowData.id}`)
            }
        }
    ];

    let columns = [
        { title: t('id'), field: 'id', hidden: true },
        { title: t(''), field: 'image', width: "10%"},
        { title: t('title'), field: 'title' },
        { title: t('type'), field: 'type' },
        { title: t('publish'), field: 'publish' }
    ]


    useEffect(() => {
        const fetchRecipes = async () => {
            try {
                setLoading(true)
                const resp = await apiService.getRecipes();
                const recipesSorted = sortFolderByDate(resp.data.content)
                setRecipes(recipesSorted)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
          fetchRecipes()
        return () => { }
    }, [])


    const sortFolderByDate = (list) => {
        const listSorted = list.sort((a, b) => {
            return (new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        })
        return listSorted
    }

 

    useEffect(() => {
        const setupData = () => {
            const setDataTable = () => {
                return recipes.map(recipe => {
                    return {
                        id: recipe._id,
                        image: recipe.image ? 
                            <div className={classes.boxImage}>
                                <img src={`${API_URL}/warehouse/${recipe.image.filename}`} alt=""/>
                            </div> : null
                        ,
                        title: recipe[`title_${i18n.language}`],
                        type: t(recipe.type),
                        publish: (
                            <Switch
                                onChange={() => handlePublishClick(recipe)}
                                size="small"
                                checked={recipe.published}
                                color="primary"
                            />
                        )
                    }
                })
            }
            setData(setDataTable())
        }
        setupData()
        return () => {
            //
        }
    }, [i18n.language, recipes])

  

    const handlePublishClick = (data) => {
        const info = data.published ? 'are you sure to unpublish this item' : 'are you sure to publish this item'
        confirm({ title: t('are you sure'), description: t(info) })
            .then(async () => {
                try {
                    
                    setLoading(true)
                    await apiService.publishRecipe(data._id);
                    const resp = await apiService.getRecipes();
                    setRecipes(resp.data.content)
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                }
            })
            .catch(() => { });
    }

    const handleDeleteClick = (data) => {
        confirm({ title: t( 'are you sure' ), description: t( 'are you sure to delete this item') })
            .then(async () => {
                try {
                    setLoading(true)
                    await apiService.deleteRecipe(data.id);
                    const resp = await apiService.getRecipes();
                    setRecipes(resp.data.content)
                    setLoading(false)
                } catch (error) {

                }
            })
            .catch(() => { });
    }

   

    return (
        <>
            <div className={classes.root}>
                <div className={classes.iconBox}>
                    <LayersIcon />
                </div>
                <Grid container justify="space-between" spacing={3} >
                    <Grid item className={classes.gridHeader}>
                        <h3>{t('recipes')}</h3>
                    </Grid>
                    <Grid item >
                        <Fab onClick={() => history.push('/recipes/create')}>
                            <AddIcon />
                        </Fab>
                    </Grid>
                </Grid>
                <Box className={classes.boxRoot}>
                    <Table
                        title={null}
                        columns={columns}
                        actions={actions}
                        data={data}
                    />
                </Box>
                {loading ? <LoadingScreen /> : null}
            </div>
        </>
        
    )
}

List.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(List)