const classes =  theme => ({
    root: {},
    colorPickerBox: {
        marginLeft: '10px',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        '& div:nth-child(2)': {
            
        }
    },
    iframe: {
        width: '100%',
        height: '100%',
        maxHeight: '463px'
    },
    noDisplay: {
        display: 'none'
    }
})
export default classes;