import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import {
    withStyles,
    List
} from '@material-ui/core';
import NavigationItem from '../NavigationItem';


const NavigationList = ({
    classes,
    pages,
    depth
}) => {
    return (
        <List>
            {pages.reduce((items, page) => reduceChildPages({ items, page, depth }), [] ) }
        </List>
    )
}

NavigationList.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(NavigationList)



const reduceChildPages = props => {
    const { items, page, depth } = props;

    if (page.children) {
        items.push(
            <NavigationItem
                depth={depth}
                title={page.title}
                key={page.title}
                icon={page.icon}
            >
                <NavigationList
                    depth={depth + 1}
                    pages={page.children} />
                </NavigationItem>
        );
    } else {
        items.push(
            <NavigationItem
                depth={depth}
                title={page.title}
                key={page.title}
                icon={page.icon}
                link={page.link}
            />
        );
    }
    return items;
};

