import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import {
    withStyles,
    Container,
    Fab
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import LanguageTabs from './../../../../components/LanguageTabs';
import Form from './../Form'
import * as apiService from './../../../../utils/api';


const Create = ({
    classes,
    match
}) => {

 

    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [languageTab, setLanguageTab] = useState('pt')
    const [notifications, setNotifications] = useState(null)
    const [loading, setLoading] = useState(true)


    const onChangeLanguageHandler = (lng) => {
        setLanguageTab(lng.id)
    }


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const params = { type: 'auto-step', step: history.location.state.step }
                const resp = await apiService.getNotifications(params);
                setNotifications(resp.data.content)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchData()
        return () => {
            //
        }
    }, [match.params.id])


   
    return (

        <div className={classes.root}>
            <div className={classes.iconBox}>
                <InsertLinkIcon />
            </div>
            <div className={classes.headerBox}>
                <h3>{t('notifications')}</h3>
                <Fab onClick={() => history.push('/notifications/steps')}>
                    <ArrowBackIcon />
                </Fab>
            </div>
            <div className={classes.contentBox}>
                <Container maxWidth="xl">
                    <LanguageTabs onChangeLanguage={onChangeLanguageHandler} />
                    <Form lng={languageTab} edit={true} notifications={notifications} />
                </Container>
            </div>
        </div>
    )
}

Create.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Create)
