
const classes = theme => ({
    root: {
        padding: '20px 20px',
        position: 'relative',
        backgroundColor: 'white',
        margin: '70px 50px 20px',
        border: '1px solid #eee',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0, 0, 0, .12)',
        borderRadius: '6px'
    },
    headerBox: {
        paddingLeft: '130px !important',
        paddingBottom: '30px',
        display: 'flex',
        justifyContent: 'space-between',
        '& h1': {
            fontWeight: 300,
            lineHeight: '1.4em',
            marginTop: '-10px'
        }
    },
    iconBox: {
        background: 'linear-gradient(60deg,#ffa726,#fb8c00)',
        boxShadow: '0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(255,152,0,.4)',
        borderRadius: '5px',
        marginTop: '-65px',
        padding: '15px',
        display: 'flex',
        zIndex: 1,
        position: 'absolute',
        fontSize: '3.5rem',
        minWidth: '80px',
        minHeight: '80px',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        '& svg': {
            fontSize: '3.5rem'
        }
    },
    contentBox: {
        marginTop: '20px',
        padding: '10px 40px'
    }
})
export default classes;