const classes =  theme => ({
    root: {
        width: '370px',
        height: '300px',
        borderRadius: '6px',
        backgroundColor: 'white',
        boxShadow: '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
        margin: '50px 10px',
        position: 'relative'
    },
    imageBox: {
        backgroundColor: 'white',
        width: '340px',
        height: '200px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '-50px',
        borderRadius: '6px',
        boxShadow: '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
        objectFit: 'cover',
        '& img': {
            width: '100%',
            height: '100%',
            borderRadius: '6px',
            objectFit: 'cover'
        }
    },
    infoBox: {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    infoText: {
        padding: '20px 20px',
        margin: '0 20px',
        borderBottom: '1px solid #ddd',
        color: '#3c4858',
        fontSize: '1.125rem',
        fontWeight: 300
    },
    viewBox: {
        marginRight: 'auto',
        display: 'flex',
        alignItems: 'center',
        padding: '15px',
        color: 'darkgray',
        '& svg': {
            marginRight: '10px'
        }
    }
})
export default classes;