const classes = theme => ({
    root: {
        display: 'flex',
        backgroundColor: 'transparent',
        flexDirection: 'column',
        padding: '20px',
        paddingTop: '40px'
    },
    containerCardView: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    containerCardChartBar: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '1100px',
        height: '400px',
        borderRadius: '6px',
        backgroundColor: 'white',
        boxShadow: '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
        position: 'relative',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '30px',
        marginBottom: '50px',
        flexDirection: 'column'
    },
    chartBarBox: {
        width: '90%',
        height: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '-50px',
        borderRadius: '6px',
        boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(255 152 0 / 40%)',
        background: 'linear-gradient( 60deg , #ffa726, #fb8c00)'
    },
    chartLineBox: {
        width: '90%',
        height: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '-50px',
        borderRadius: '6px',
        boxShadow: ' 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(244 67 54 / 40%)',
        background: 'linear-gradient( 60deg , #ef5350, #e53935)'
    },
    infoBox: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'left'
    },
    infoText: {
        padding: '20px 20px',
        margin: '0 20px',
        borderBottom: '1px solid #ddd',
        color: '#3c4858',
        fontSize: '1.125rem',
        fontWeight: 300
    },
    viewBox: {
        padding: '20px 20px',
        margin: '0 20px',
        color: '#3c4858',
        fontWeight: 200
    },
    cardHeaderBox: {
        margin: '30px 20px',
        background: 'linear-gradient(60deg,#ffa726,#fb8c00)',
        boxShadow: '0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(255,152,0,.4)',
        borderRadius: '5px',
        padding: '15px',
        display: 'flex',
        zIndex: 1,
        fontSize: '1.5rem',
        minWidth: '40px',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: 'white',
        '& h4': {
            fontSize: '14px',
            marginRight: '10px'
        }
    },
    buttonCard: {
        marginRight: '5px',
        color: 'white',
        padding: '5px',
        textTransform: 'uppercase',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        '& .MuiButton-label': {
            padding: '5px',
            textTransform: 'uppercase',
        },
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
        }
    }
})
export default classes;