import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import {
    withStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core';




const ModalAvatars = ({
    classes,
    onClose,
    onSelect
}) => {

    const { t } = useTranslation();
   const [avatars, setAvatars] = useState([])

    const handleClose = () => {
        onClose();
    };

    const handleSelect = (item) => {
        onSelect(item)
    }

    useEffect(() => {
        const avts = [];
        const loadAvatars = () => {
            for (let index = 0; index < 15; index++) {
                const data = {
                    name: `avatar${index+1}`,
                    base64: require(`./../../../../assets/images/avatar/avatar${index+1}.png`).default
                }
                avts.push(data)
            }
            setAvatars(avts)
        }
        loadAvatars()
        return () => {
            //
        }
    }, [])



    return (
        <Dialog className={classes.dialog}
            onClose={handleClose} aria-labelledby="dialog-title" open={true} maxWidth="md" disableBackdropClick={true}>
            <DialogTitle id="dialog-title">{t('choose your avatar')}</DialogTitle>
            <DialogContent>
                <div className={classes.listAvatar}>
                    {avatars.map(item => (
                        <div className={classes.boxAvatar} onClick={() => handleSelect(item)}>
                            <img src={item.base64} alt="" />
                        </div>
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ModalAvatars.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(ModalAvatars)
