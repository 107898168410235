import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Context } from './../../../context';
import { useHistory } from 'react-router-dom';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import * as Yup from 'yup';
import {
    withStyles,
    FormControl,
    Grid,
    TextField,
    Box 
} from '@material-ui/core';
import { Formik } from 'formik';
import RichEditorText from './../../../components/RichEditorText';
import FileDropzone from './../../../components/FileDropzone';
import ColorPicker from 'material-ui-color-picker'
import SubmitButton from '../../../components/SubmitButton';
import { toBase64, getFileDto, getImages } from './../../../utils/util'
import * as apiService from './../../../utils/api';
import * as actionTypes from './../../../actions';

const defaultInitialValues = {
    title_pt: '',
    subtitle_pt: '',
    text_pt: '',
    bottom_pt: '',
    bottomColor_pt: '#000',
    link_pt: '',
    title_en: '',
    subtitle_en: '',
    text_en: '',
    bottom_en: '',
    bottomColor_en: '#000',
    link_en: '',
    title_es: '',
    subtitle_es: '',
    text_es: '',
    bottom_es: '',
    bottomColor_es: '#000',
    link_es: '',
    title_pl: '',
    subtitle_pl: '',
    text_pl: '',
    bottom_pl: '',
    bottomColor_pl: '#000',
    link_pl: '',
    published: false,
    featured: false
}

const Form = ({
    classes,
    lng,
    directLink,
    blog,
    edit
}) => {




    const { state, dispatch } = useContext(Context);
    const { t } = useTranslation();
    const history = useHistory();
    const [disabled, setDisabled] = useState(false)
    const [images, setImages] = useState({
        image_pt: null,
        image_en: null,
        image_pl: null,
        image_es: null
    })


    const onUploadImages = async (fls) => {
        const fl = fls[0]
        let preview = null;
        let resp = null;
        let file = null;
        if (fl) {
            preview = URL.createObjectURL(fl)
            resp = await toBase64(fl)
            file = {
                file: resp,
                type: fl.type,
                filename: `${new Date().getTime()}_${fl.name}`,
                preview: preview
            }
        }
        let filesCopy = { ...images }
        filesCopy[`image_${lng}`] = file
        setImages(filesCopy)
    }

    useEffect(() => {
        const setupPermissionEdit = () => {
            if (state?.role === 'admin') {
                const disabled = (state?.user.language !== lng)
                setDisabled(disabled)
            }
        }
        if (blog) {
            const imgs = {
                image_pt: blog.image_pt,
                image_en: blog.image_en,
                image_pl: blog.image_pl,
                image_es: blog.image_es
            }
            setImages(imgs)
        }
        setupPermissionEdit()
    }, [lng, state?.role, state?.user.language, blog])



    return (
        <Formik
            initialValues={blog && Object.keys(blog).length !== 0 ? blog : defaultInitialValues}
            validationSchema={
                Yup.object().shape({})
            }
            onSubmit={async (values, { resetForm }) => {
                try {
                    const imgs = {
                        image_pt: await getFileDto(images.image_pt),
                        image_en: await getFileDto(images.image_en),
                        image_pl: await getFileDto(images.image_pl),
                        image_es: await getFileDto(images.image_es)
                    }
                    let data = {
                        ...getDto(values),
                        ...getImages(imgs),
                    }
                    data['type'] = directLink ? 'external' : 'internal'

                    if (edit) {
                        await apiService.updateBlog(blog._id, data)
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })

                    } else {
                        await apiService.createBlog(data)
                        history.push('/blog')
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })
                    }
                } catch (error) {
                    if (edit) {
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('updated error') } })
                    } else {
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('updated error') } })
                    }
                }
            }}
        >   
            {props => {
                return (
                    <form onSubmit={props.handleSubmit}>
                        <div className={classes.root}>
                            <Box id="box-pt" className={clsx({ [classes.noDisplay]: lng !== 'pt' })}>
                                <Box className={clsx({ [classes.noDisplay]: directLink })}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                    variant='outlined'
                                                    id="title_pt"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('title')}`}
                                                    value={props.values.title_pt}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.title_pt && props.touched.title_pt}
                                                    helperText={(props.errors.title_pt && props.touched.title_pt) && props.errors.title_pt}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                    variant='outlined'
                                                    id="subtitle_pt"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('subtitle')}`}
                                                    value={props.values.subtitle_pt}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.subtitle_pt && props.touched.subtitle_pt}
                                                    helperText={(props.errors.subtitle_pt && props.touched.subtitle_pt) && props.errors.subtitle_pt}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                        <TextField disabled={disabled}
                                                            variant='outlined'
                                                            id="bottom_pt"
                                                            placeholder=""
                                                            autoComplete="off"
                                                            margin="dense"
                                                            label={`${t('bottom')}`}
                                                            value={props.values.bottom_pt}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            error={props.errors.bottom_pt && props.touched.bottom_pt}
                                                            helperText={(props.errors.bottom_pt && props.touched.bottom_pt) && props.errors.bottom_pt}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={3} >
                                                    <Box style={{ display: 'flex' }}>
                                                        <ColorPicker
                                                            variant='outlined'
                                                            margin="dense"
                                                            name="bottomColor_pt"
                                                            label={props.values.bottomColor_pt || "Cor"}
                                                            value={props.values.bottomColor_pt}
                                                            onChange={color => props.setFieldValue('bottomColor_pt', color)}
                                                            onBlur={props.handleBlur}
                                                            fullWidth
                                                            disabled
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FileDropzone disabled={disabled} title={t('upload image')} onAcceptFiles={onUploadImages} data={blog.image_pt} pdf={false} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RichEditorText
                                                name="text_pt"
                                                label={t('text')}
                                                value={props.values.text_pt}
                                                onChange={data => props.setFieldValue('text_pt', data)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box className={clsx({ [classes.noDisplay]: !directLink })}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                    variant='outlined'
                                                    id="title_pt"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('title')}`}
                                                    value={props.values.title_pt}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.title_pt && props.touched.title_pt}
                                                    helperText={(props.errors.title_pt && props.touched.title_pt) && props.errors.title_pt}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                    variant='outlined'
                                                    id="link_pt"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('link')}`}
                                                    value={props.values.link_pt}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.link_pt && props.touched.link_pt}
                                                    helperText={(props.errors.link_pt && props.touched.link_pt) && props.errors.link_pt}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FileDropzone disabled={disabled} title={t('upload image')} onAcceptFiles={onUploadImages} data={blog.image_pt} pdf={false} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>

                            <Box id="box-en" className={clsx({ [classes.noDisplay]: lng !== 'en' })}>
                                 <Box className={clsx({ [classes.noDisplay]: directLink })}>
                                    <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                 variant='outlined'
                                                id="title_en"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_en}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_en && props.touched.title_en}
                                                helperText={(props.errors.title_en && props.touched.title_en) && props.errors.title_en}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                    variant='outlined'
                                                    id="subtitle_en"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('subtitle')}`}
                                                    value={props.values.subtitle_en}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.subtitle_en && props.touched.subtitle_en}
                                                    helperText={(props.errors.subtitle_en && props.touched.subtitle_en) && props.errors.subtitle_en}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                        <TextField disabled={disabled}
                                                            variant='outlined'
                                                            id="bottom_en"
                                                            placeholder=""
                                                            autoComplete="off"
                                                            margin="dense"
                                                            label={`${t('bottom')}`}
                                                            value={props.values.bottom_en}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            error={props.errors.bottom_en && props.touched.bottom_en}
                                                            helperText={(props.errors.bottom_en && props.touched.bottom_en) && props.errors.bottom_en}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3} >
                                                <Box style={{ display: 'flex' }}>
                                                        <ColorPicker
                                                            variant='outlined'
                                                            margin="dense"
                                                            name="bottomColor_en"
                                                            label={props.values.bottomColor_en || "Cor"}
                                                            value={props.values.bottomColor_en}
                                                            onChange={color => props.setFieldValue('bottomColor_en', color)}
                                                            onBlur={props.handleBlur}
                                                            fullWidth
                                                            disabled
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                            <FileDropzone disabled={disabled} title={t('upload image')} onAcceptFiles={onUploadImages} data={blog.image_en} pdf={false} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RichEditorText
                                            name="text_en"
                                            label={t('text')}
                                            value={props.values.text_en}
                                            onChange={data => props.setFieldValue('text_en', data)}
                                        />
                                    </Grid>
                                </Grid>
                                </Box>   
                                 <Box className={clsx({ [classes.noDisplay]: !directLink })}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                    variant='outlined'
                                                    id="title_en"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('title')}`}
                                                    value={props.values.title_en}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.title_en && props.touched.title_en}
                                                    helperText={(props.errors.title_en && props.touched.title_en) && props.errors.title_en}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                    id="link_en"
                                                    variant='outlined'
                                                    placeholder=""
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('link')}`}
                                                    value={props.values.link_en}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.link_en && props.touched.link_en}
                                                    helperText={(props.errors.link_en && props.touched.link_en) && props.errors.link_en}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FileDropzone disabled={disabled} title={t('upload image')} onAcceptFiles={onUploadImages} data={blog.image_en} pdf={false} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>

                            <Box id="box-es" className={clsx({ [classes.noDisplay]: lng !== 'es' })}>
                                 <Box className={clsx({ [classes.noDisplay]: directLink })}>
                                    <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                    id="title_es"
                                                    variant='outlined'
                                                    placeholder=""
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('title')}`}
                                                    value={props.values.title_es}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.title_es && props.touched.title_es}
                                                    helperText={(props.errors.title_es && props.touched.title_es) && props.errors.title_es}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                    id="subtitle_es"
                                                    variant='outlined'
                                                    placeholder=""
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('subtitle')}`}
                                                    value={props.values.subtitle_es}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.subtitle_es && props.touched.subtitle_es}
                                                    helperText={(props.errors.subtitle_es && props.touched.subtitle_es) && props.errors.subtitle_es}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <TextField disabled={disabled}
                                                        id="bottom_es"
                                                        variant='outlined'
                                                        placeholder=""
                                                        autoComplete="off"
                                                        margin="dense"
                                                        label={`${t('bottom')}`}
                                                        value={props.values.bottom_es}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        error={props.errors.bottom_es && props.touched.bottom_es}
                                                        helperText={(props.errors.bottom_es && props.touched.bottom_es) && props.errors.bottom_es}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3} >
                                                <Box style={{ display: 'flex' }}>
                                                        <ColorPicker
                                                            variant='outlined'
                                                            margin="dense"
                                                            name="bottomColor_es"
                                                            label={props.values.bottomColor_es || "Cor"}
                                                            value={props.values.bottomColor_es}
                                                            onChange={color => props.setFieldValue('bottomColor_es', color)}
                                                            onBlur={props.handleBlur}
                                                            fullWidth
                                                            disabled
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                            <FileDropzone disabled={disabled} title={t('upload image')} onAcceptFiles={onUploadImages} data={blog.image_es} pdf={false} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RichEditorText
                                            name="text_es"
                                            label={t('text')}
                                            value={props.values.text_es}
                                            onChange={data => props.setFieldValue('text_es', data)}
                                        />
                                    </Grid>
                                </Grid>
                                </Box>  
                                <Box className={clsx({ [classes.noDisplay]: !directLink })}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                    id="title_es"
                                                    variant='outlined'
                                                    placeholder=""
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('title')}`}
                                                    value={props.values.title_es}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.title_es && props.touched.title_es}
                                                    helperText={(props.errors.title_es && props.touched.title_es) && props.errors.title_es}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                    id="link_es"
                                                    variant='outlined'
                                                    placeholder=""
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('link')}`}
                                                    value={props.values.link_es}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.link_es && props.touched.link_es}
                                                    helperText={(props.errors.link_es && props.touched.link_es) && props.errors.link_es}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FileDropzone disabled={disabled} title={t('upload image')} onAcceptFiles={onUploadImages} data={blog.image_es} pdf={false} />
                                        </Grid>
                                    </Grid>
                                </Box>
                             </Box>

                            <Box id="box-pl" className={clsx({ [classes.noDisplay]: lng !== 'pl' })}>
                                <Box className={clsx({ [classes.noDisplay]: directLink })}>
                                    <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                id="title_pl"
                                                variant='outlined'    
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_pl}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_pl && props.touched.title_pl}
                                                helperText={(props.errors.title_pl && props.touched.title_pl) && props.errors.title_pl}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                id="subtitle_es"
                                                variant='outlined'    
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('subtitle')}`}
                                                value={props.values.subtitle_pl}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.subtitle_pl && props.touched.subtitle_pl}
                                                helperText={(props.errors.subtitle_pl && props.touched.subtitle_pl) && props.errors.subtitle_pl}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <TextField disabled={disabled}
                                                        id="bottom_pl"
                                                        variant='outlined'    
                                                        placeholder=""
                                                        autoComplete="off"
                                                        margin="dense"
                                                        label={`${t('bottom')}`}
                                                        value={props.values.bottom_pl}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        error={props.errors.bottom_pl && props.touched.bottom_pl}
                                                        helperText={(props.errors.bottom_pl && props.touched.bottom_pl) && props.errors.bottom_pl}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3} >
                                                <Box style={{ display: 'flex' }}>
                                                    <ColorPicker
                                                        variant='outlined'
                                                        margin="dense"
                                                        name="bottomColor_pl"
                                                        label={props.values.bottomColor_pl || "Cor"}
                                                        value={props.values.bottomColor_pl}
                                                        onChange={color => props.setFieldValue('bottomColor_pl', color)}
                                                        onBlur={props.handleBlur}
                                                        fullWidth
                                                        disabled
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                            <FileDropzone disabled={disabled} title={t('upload image')} onAcceptFiles={onUploadImages} data={blog.image_pl} pdf={false} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RichEditorText
                                            name="text_pl"
                                            label={t('text')}
                                            value={props.values.text_pl}
                                            onChange={data => props.setFieldValue('text_pl', data)}
                                        />
                                    </Grid>
                                </Grid>
                                </Box>
                                <Box className={clsx({ [classes.noDisplay]: !directLink })}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                    id="title_pl"
                                                    variant='outlined'
                                                    placeholder=""
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('title')}`}
                                                    value={props.values.title_pl}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.title_pl && props.touched.title_pl}
                                                    helperText={(props.errors.title_pl && props.touched.title_pl) && props.errors.title_pl}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                    id="link_pl"
                                                    variant='outlined'
                                                    placeholder=""
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('link')}`}
                                                    value={props.values.link_pl}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.link_pl && props.touched.link_pl}
                                                    helperText={(props.errors.link_pl && props.touched.link_pl) && props.errors.link_pl}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FileDropzone disabled={disabled} title={t('upload image')} onAcceptFiles={onUploadImages} data={blog.image_pl} pdf={false} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', margin: '30px 0' }}>
                                <SubmitButton
                                    isSubmitting={props.isSubmitting}>
                                    {t('save')}
                                </SubmitButton>
                            </Grid>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

Form.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Form)


const getDto = (values) => {
    const data = {
        title_pt: values.title_pt,
        subtitle_pt: values.subtitle_pt,
        text_pt: values.text_pt,
        bottom_pt: values.bottom_pt,
        link_pt: values.link_pt,
        title_en: values.title_en,
        subtitle_en: values.subtitle_en,
        text_en: values.text_en,
        bottom_en: values.bottom_en,
        link_en: values.link_en,
        title_es: values.title_es,
        subtitle_es: values.subtitle_es,
        text_es: values.text_es,
        bottom_es: values.bottom_es,
        link_es: values.link_es,
        title_pl: values.title_pl,
        subtitle_pl: values.subtitle_pl,
        text_pl: values.text_pl,
        bottom_pl: values.bottom_pl,
        link_pl: values.link_pl,
        published: values.published,
        featured: values.featured,
        section: 'blog'
    }
    return data;
}
