import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { useFormik } from 'formik';
import clsx from 'clsx';
import moment from 'moment';
import {
    withStyles,
    Grid,
    FormControl,
    TextField,
    Fab
} from '@material-ui/core';
import LoadingScreen from './../../../components/LoadingScreen';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FaceIcon from '@material-ui/icons/Face';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import SpaIcon from '@material-ui/icons/Spa';
import * as apiService from './../../../utils/api';



const defaultInitialValues = {
    birthDate: '',
    gender: '',
    height: '',
    weight: '',
    exercise: '',
    exerciseFrequency: '',
    doesExercise: false,
    goal: '',
    allergies: '',
    healthProblems: '',
    medication: '',
    medicationEffect: '',
    surgery: '',
    surgeryDone: '',
    surgeryTime: '',
    surgeryType: '',
    foodRestriction: ''
}



const Info = ({
    classes,
    match
}) => {

    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)
    const [userInfo, setUserInfo] = useState(null)


    useEffect(() => {
        const fetchUserInfo = async () => {
            const id = match.params.id
            return apiService.getUserInfo(id);
        }
        const fetchUser = async () => {
            const id = match.params.id
            return apiService.getUser(id)
        }

        const fetchData = async () => {
            try {
                setLoading(true)
                const [res1, res2] = await Promise.all([fetchUserInfo(), fetchUser()]);
                const usrInfo = res1.data.content
                const usr = res2.data.content
                setUserInfo(usrInfo)
                setUser(usr)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchData()
        return () => {
            //
        }
    }, [match.params.id])



    const formik = useFormik({
        enableReinitialize: true,
        validateOnMount: false,
        initialValues: !!userInfo ? { ...userInfo } : defaultInitialValues
    })



    if (loading) {
        return <LoadingScreen />
    }


    return (
        <div className={classes.root}>
            <div className={classes.headerBox}>
                <Fab onClick={() => history.push('/client')}>
                    <ArrowBackIcon />
                </Fab>
            </div>
            <div className={classes.contentBox}>
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{ display: 'flex' }}>
                            <div className={classes.card}>
                                <div className={classes.cardHeaderBox}>
                                    <h3>{t('personal data')}</h3>
                                </div>
                                <div className={clsx({ [classes.iconBoxCard]: true }, { [classes.iconBoxCardPersonalData]: true})}>
                                    <FaceIcon />
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="date"
                                                label={t('birth date')}
                                                defaultValue={t(' ')}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                value={moment(formik.values.birthDate).format('lll')}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="gender"
                                                label={t('gender')}
                                                defaultValue={t(' ')}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                value={formik.values.gender ? t(`gender-${formik.values.gender}`) : ''}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="height"
                                                label={t('height')}
                                                defaultValue={t(' ')}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                value={formik.values.height}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="weight"
                                                label={t('weight')}
                                                defaultValue={t(' ')}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                value={formik.values.weight}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{ display: 'flex' }}>
                            <div className={classes.card}>
                                <div className={classes.cardHeaderBox}>
                                    <h3>{t('physical exercise')}</h3>
                                </div>
                                <div className={clsx({ [classes.iconBoxCard]: true }, { [classes.iconBoxCardPhysicalExercise]: true })}>
                                    <FitnessCenterIcon />
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="doesExercise"
                                                label={t('doesExercise')}
                                                defaultValue={t(' ')}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                value={formik.values.doesExercise ? t('yes') : t('no')}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="exercise"
                                                label={t('exercise')}
                                                defaultValue={t(' ')}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                                value={formik.values.exercise}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="exerciseFrequency"
                                                label={t('exerciseFrequency')}
                                                defaultValue={t(' ')}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                                value={formik.values.exerciseFrequency}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="goal"
                                                label={t('goal')}
                                                defaultValue={t(' ')}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                                value={formik.values.goal}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{ display: 'flex' }}>
                            <div className={classes.card}>
                                <div className={classes.cardHeaderBox}>
                                    <h3>{t('surgeries')}</h3>
                                </div>
                                <div className={clsx({ [classes.iconBoxCard]: true }, { [classes.iconBoxCardSugeries]: true })}>
                                    <LocalHospitalIcon />
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="surgeryDone"
                                                label={t('surgeryDone')}
                                                defaultValue={t(' ')}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                value={formik.values.surgeryDone ? t('yes') : t('no')}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="surgery"
                                                label={t('surgery')}
                                                defaultValue={t(' ')}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                                value={formik.values.surgery}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="surgeryTime"
                                                label={t('surgeryTime')}
                                                defaultValue={t(' ')}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                value={formik.values.surgeryTime}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="surgeryType"
                                                label={t('surgeryType')}
                                                defaultValue={t(' ')}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                value={formik.values.surgeryType}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{ display: 'flex' }}>
                            <div className={classes.card}>
                                <div className={classes.cardHeaderBox}>
                                    <h3>{t('medical history')}</h3>
                                </div>
                                <div className={clsx({ [classes.iconBoxCard]: true }, { [classes.iconBoxCardMedicalHistory]: true })}>
                                    <SpaIcon />
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="allergies"
                                                label={t('allergies')}
                                                defaultValue={t(' ')}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                                value={formik.values.allergies}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="healthProblems"
                                                label={t('healthProblems')}
                                                defaultValue={t(' ')}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                                value={formik.values.healthProblems}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="medication"
                                                label={t('medication')}
                                                defaultValue={t(' ')}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                                value={formik.values.medication}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="medicationEffect"
                                                label={t('medicationEffect')}
                                                defaultValue={t(' ')}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                                value={formik.values.medicationEffect}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="foodRestriction"
                                                label={t('foodRestriction')}
                                                defaultValue={t(' ')}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                                value={formik.values.foodRestriction}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                </Grid>
                </form>
            </div>
        </div>
    )
}

Info.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Info)

{
    /* 
    <form >
                    <Grid container spacing={6}>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <TextField
                                    id="date"
                                    label={t('birth date')}
                                    defaultValue={t(' ')}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    value={moment(formik.values.dat).format('lll')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <TextField
                                    id="height"
                                    label={t('height')}
                                    defaultValue={t(' ')}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    value={formik.values.height}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <TextField
                                    id="weight"
                                    label={t('weight')}
                                    defaultValue={t(' ')}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    value={formik.values.weight}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="allergies"
                                    label={t('allergies')}
                                    defaultValue={t(' ')}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                    value={formik.values.allergies}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="exercise"
                                    label={t('exercise')}
                                    defaultValue={t(' ')}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                    value={formik.values.exercise}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="exerciseFrequency"
                                    label={t('exerciseFrequency')}
                                    defaultValue={t(' ')}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                    value={formik.values.exerciseFrequency}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="medication"
                                    label={t('medication')}
                                    defaultValue={t(' ')}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                    value={formik.values.medication}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="medicationEffect"
                                    label={t('medicationEffect')}
                                    defaultValue={t(' ')}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                    value={formik.values.medicationEffect}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="surgery"
                                    label={t('surgery')}
                                    defaultValue={t(' ')}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                    value={formik.values.surgery}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="surgeryTime"
                                    label={t('surgeryTime')}
                                    defaultValue={t(' ')}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    value={formik.values.surgeryTime}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="surgeryType"
                                    label={t('surgeryType')}
                                    defaultValue={t(' ')}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    value={formik.values.surgeryType}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
              </form>
    
    */
}