import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import moment from 'moment'
import styles from './styles';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {
    withStyles,
    Container,
    Box
} from '@material-ui/core';
import Modal from './../../../../../../components/Modal';
import LoadingScreen from './../../../../../../components/LoadingScreen';
import MeasurementInfo from '../Weighings/components/MeasurementInfo';

import * as apiService from './../../../../../../utils/api';

const localizer = momentLocalizer(moment)


const Template = ({
    classes,
    userId
    
}) => {


    const { t } = useTranslation();
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)
    const [events, setEvents] = useState([])
    const [measurements, setMeasurements] = useState([])
    const [selectedMeasurement, setSelectedMeasurement] = useState(null)

    const [showInfoMeasurement, setShowInfoMeasurement] = useState(false)


    const getEventStyle = () => {
        return {
            className: classes.event
        }
    }

    const selectDiary = (data) => {
        setShowInfoMeasurement(true)
        setSelectedMeasurement(data)
    }


    const handleCloseInfoMeasurement = () => {
        setShowInfoMeasurement(false)
    }




    useEffect(() => {
        const fetchMeasurements = async () => {
            const id = userId
            return apiService.getUserMeasurements(id);
        }
        const fetchUser = async () => {
            const id = userId
            return apiService.getUser(id)
        }

        const fetchData = async () => {
            try {
                setLoading(true)
                const [res1, res2] = await Promise.all([fetchMeasurements(), fetchUser()]);
                const meas = res1.data.content
                const usr = res2.data.content
                const evnts = meas.map((it, index) => {
                    return {
                        title: t('measurement'),
                        start: new Date(it.createdAt),
                        end: new Date(it.createdAt), 
                        allDay: true,
                        resource: it
                    }
                })
                setEvents(evnts)
                setMeasurements(meas)
                setUser(usr)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchData()
        return () => {
            //
        }
    }, [userId, t])


    if (loading) {
        return <LoadingScreen />
    }

    return (
        <div className={classes.root}>
            <div className={classes.contentBox}>
                <Container maxWidth="xl">
                    <Box className={classes.calendarBox}>
                        <Calendar
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 600, width: '100%' }}
                            views={['day', 'month']}
                            onSelectEvent={(event) => {
                                selectDiary(event.resource)
                            }}
                            eventPropGetter={getEventStyle}
                        />
                    </Box>
                </Container>
            </div>
            {showInfoMeasurement ? (
                <Modal open={showInfoMeasurement} title={t('measurement')} onClose={handleCloseInfoMeasurement}>
                    <MeasurementInfo measurementInfo={selectedMeasurement} />
                </Modal>
            ) : null}
        </div>
    )
}

Template.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Template)
