import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Context } from '../../../context';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import * as Yup from 'yup';
import {
    withStyles,
    FormControl,
    Grid,
    TextField,
    Box 
} from '@material-ui/core';
import { Formik } from 'formik';
import FileDropzone from './../../../components/FileDropzone';
import SubmitButton from '../../../components/SubmitButton';
import { toBase64, getFileDto, getImages, getfiles } from './../../../utils/util'
import * as apiService from './../../../utils/api';
import * as actionTypes from '../../../actions';


const defaultInitialValues = {
    title_pt: '',
    title_en: '',
    title_es: '',
    title_pl: '',
    published: false
}

const Form = ({
    classes,
    lng,
    download,
    edit
}) => {


    const { t } = useTranslation();
    const { state, dispatch } = useContext(Context);
    const [disabled, setDisabled] = useState(false)
    const [images, setImages] = useState({
        image_pt: null,
        image_en: null,
        image_pl: null,
        image_es: null
    })


    const [files, setFiles] = useState({
        file_pt: null,
        file_en: null,
        file_pl: null,
        file_es: null
    })


    const onUploadImages = async (fls) => {
        const fl = fls[0]
        let preview = null;
        let resp = null;
        let file = null;
        if (fl) {
            preview = URL.createObjectURL(fl)
            resp = await toBase64(fl)
            file = {
                file: resp,
                type: fl.type,
                filename: `${new Date().getTime()}_${fl.name}`,
                preview: preview
            }
        }
        let filesCopy = { ...images }
        filesCopy[`image_${lng}`] = file
        setImages(filesCopy)
    }


    const onUploadPdf = async (fls) => {
        const fl = fls[0]
        let resp = null;
        let file = null;
        if (fl) {
            resp = await toBase64(fl)
            file = {
                file: resp,
                type: fl.type,
                filename: `${new Date().getTime()}_${fl.name}`,
                preview: null
            }
        }
        let filesCopy = { ...files }
        filesCopy[`file_${lng}`] = file
        setFiles(filesCopy)
    }


    useEffect(() => {
        const setupPermissionEdit = () => {
            if (state?.role === 'admin') {
                const disabled = (state?.user.language !== lng)
                setDisabled(disabled)
            }
        }
        if (download) {
            const imgs = {
                image_pt: download.image_pt,
                image_en: download.image_en,
                image_pl: download.image_pl,
                image_es: download.image_es
            }
            const fls = {
                file_pt: download.file_pt,
                file_en: download.file_en,
                file_pl: download.file_pl,
                file_es: download.file_es
            }
            setImages(imgs)
            setFiles(fls)
        }
        setupPermissionEdit()

        return () => {
            //
        }
    }, [download, lng, state?.role, state?.user.language])


    return (
        <Formik
            initialValues={download ? {...download} : defaultInitialValues}
            validationSchema={
                Yup.object().shape({})
            }
            onSubmit={async (values, { resetForm }) => {
                try {
                    const imgs = {
                        image_pt: await getFileDto(images.image_pt),
                        image_en: await getFileDto(images.image_en),
                        image_pl: await getFileDto(images.image_pl),
                        image_es: await getFileDto(images.image_es)
                    }

                      const _files = {
                        file_pt: await getFileDto(files.file_pt),
                        file_en: await getFileDto(files.file_en),
                        file_pl: await getFileDto(files.file_pl),
                        file_es: await getFileDto(files.file_es)
                    }

                    const data = {
                        ...getDto(values),
                        ...getImages(imgs),
                        ...getfiles(_files)
                    }
                    if (edit) {
                        await apiService.updateDownload(download._id, data)
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })

                    } else {
                        await apiService.createDownload(data)
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })
                    }
                } catch (error) {
                    if (edit) {
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('updated error') } })
                    } else {
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('updated error') } })
                    }
                }
            }}
        >   
            {props => {
                return (
                    <form onSubmit={props.handleSubmit}>
                        <div className={classes.root}>
                            <Box id="box-pt" className={clsx({ [classes.noDisplay]: lng !== 'pt' })}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                variant="outlined"
                                                id="title_pt"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_pt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_pt && props.touched.title_pt}
                                                helperText={(props.errors.title_pt && props.touched.title_pt) && props.errors.title_pt}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} pdf={false} onAcceptFiles={onUploadImages} data={download.image_pt}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload pdf')} pdf={true} onAcceptFiles={onUploadPdf} data={download.file_pt}/>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box id="box-en" className={clsx({ [classes.noDisplay]: lng !== 'en' })}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                variant="outlined"
                                                id="title_en"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_en}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_en && props.touched.title_en}
                                                helperText={(props.errors.title_en && props.touched.title_en) && props.errors.title_en}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} pdf={false} onAcceptFiles={onUploadImages} data={download.image_en}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload pdf')} pdf={true} onAcceptFiles={onUploadPdf} data={download.file_en}  />
                                    </Grid>
                                </Grid>
                            </Box>
                           
                            <Box id="box-es" className={clsx({ [classes.noDisplay]: lng !== 'es' })}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                variant="outlined"
                                                id="title_es"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_es}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_es && props.touched.title_es}
                                                helperText={(props.errors.title_es && props.touched.title_es) && props.errors.title_es}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} pdf={false} onAcceptFiles={onUploadImages} data={download.image_es}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload pdf')} pdf={true} onAcceptFiles={onUploadPdf} data={download.file_es} />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box id="box-pl" className={clsx({ [classes.noDisplay]: lng !== 'pl' })}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                variant="outlined"
                                                id="title_pl"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_pl}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_pl && props.touched.title_pl}
                                                helperText={(props.errors.title_pl && props.touched.title_pl) && props.errors.title_pl}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} pdf={false} onAcceptFiles={onUploadImages} data={download.image_pl}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload pdf')} pdf={true} onAcceptFiles={onUploadPdf} data={download.file_pl} />
                                    </Grid>
                                </Grid>
                            </Box>
                           
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', margin: '30px 0' }}>
                                <SubmitButton
                                    isSubmitting={props.isSubmitting}>
                                    {t('save')}
                                </SubmitButton>
                            </Grid>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

Form.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Form)

const getDto = (values) => {
    const data = {
        title_pt: values.title_pt,
        title_en: values.title_en,
        title_es: values.title_es,
        title_pl: values.title_pl,
        published: values.published
    }
    return data;
}


