import React, { useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import { Context } from '../../context';
import { compose } from 'recompose';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import {
    withStyles,
    Grid,
    FormControl,
    TextField,
    Box
} from '@material-ui/core';
import FileDropzone from './../../components/FileDropzone';
import SubmitButton from '../../components/SubmitButton';
import RichEditorText from './../../components/RichEditorText';

import { toBase64, getFileDto, getImages} from './../../utils/util'


const INITIAL_VALUES = {
    title_pt: '',
    text_pt: '',
    title_en: '',
    text_en: '',
    title_pl: '',
    text_pl: '',
    title_es: '',
    text_es: '',
};


const FormPageContent = ({
    classes,
    lng,
    data,
    onSave,
}) => {

    const { state, dispatch } = useContext(Context);
    const { t } = useTranslation();
    const [disabled, setDisabled] = useState(false)
    const [images, setImages] = useState({
        image_pt: null,
        image_en: null,
        image_pl: null,
        image_es: null
    })
    

    const formik = useFormik({
        enableReinitialize: true,
        validateOnMount: false,
        initialValues: data === null ? { ...INITIAL_VALUES } : { ...data },
        validationSchema: Yup.object().shape({
            title: Yup.string()
        }),
        onSubmit: async (values) => {
            const data = {
                title_pt: values.title_pt,
                text_pt: values.text_pt,
                title_en: values.title_en,
                text_en: values.text_en,
                title_pl: values.title_pl,
                text_pl: values.text_pl,
                title_es: values.title_es,
                text_es: values.text_es
            } 
            await onSave(data)
        }
    })






    useEffect(() => {
        const setupPermissionEdit = () => {
            if (state?.role === 'admin') {
                const disabled = (state?.user.language !== lng)
                setDisabled(disabled)
            }
        }
        if (data) {
            setupPermissionEdit()
        }
        return () => {
            //
        }
    }, [data, lng, state?.role, state?.user.language])

    
    return (
        <div className={classes.root}>
            <form onSubmit={formik.handleSubmit}>
                <Box id="box-pt" className={clsx({ [classes.noDisplay]: lng !== 'pt' })}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField disabled={disabled}
                                    id="title_pt"
                                    placeholder=""
                                    autoComplete="off"
                                    variant='outlined'
                                    margin="dense"
                                    label={t('title')}
                                    value={formik.values.title_pt}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.title_pt && formik.touched.title_pt}
                                    helperText={(formik.errors.title_pt && formik.touched.title) && formik.errors.title}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <RichEditorText
                                name="text_pt"
                                label={t('description')}
                                value={formik.values.text_pt}
                                onChange={data => formik.setFieldValue('text_pt', data)}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box id="box-en" className={clsx({ [classes.noDisplay]: lng !== 'en' })}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField disabled={disabled}
                                    id="title_en"
                                    placeholder=""
                                    variant='outlined'
                                    autoComplete="off"
                                    margin="dense"
                                    label={t('title')}
                                    value={formik.values.title_en}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.title_en && formik.touched.title_en}
                                    helperText={(formik.errors.title_en && formik.touched.title_en) && formik.errors.title_en}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <RichEditorText
                                name="text_en"
                                label={t('description')}
                                value={formik.values.text_en}
                                onChange={data => formik.setFieldValue('text_en', data)}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box id="box-pl" className={clsx({ [classes.noDisplay]: lng !== 'pl' })}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField disabled={disabled}
                                    id="title_pl"
                                    placeholder=""
                                    autoComplete="off"
                                    variant='outlined'
                                    margin="dense"
                                    label={t('title')}
                                    value={formik.values.title_pl}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.title_pl && formik.touched.title_pl}
                                    helperText={(formik.errors.title_pl && formik.touched.title_pl) && formik.errors.title_pl}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                              <RichEditorText
                                name="text_pl"
                                label={t('description')}
                                value={formik.values.text_pl}
                                onChange={data => formik.setFieldValue('text_pl', data)}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box id="box-es" className={clsx({ [classes.noDisplay]: lng !== 'es' })}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField disabled={disabled}
                                    id="title_es"
                                    placeholder=""
                                    autoComplete="off"
                                    margin="dense"
                                    variant='outlined'
                                    label={t('title')}
                                    value={formik.values.title_es}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.title_es && formik.touched.title_es}
                                    helperText={(formik.errors.title_es && formik.touched.title_es) && formik.errors.title_es}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <RichEditorText
                                name="text_es"
                                label={t('description')}
                                value={formik.values.text_es}
                                onChange={data => formik.setFieldValue('text_es', data)}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', margin: '30px 0' }}>
                    <SubmitButton
                        isSubmitting={formik.isSubmitting}>
                        {t('save')}
                    </SubmitButton>
                </Grid>
                
            </form>
       </div>
    )
}

FormPageContent.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(FormPageContent)



