
const  theme  = () => ({
    root: {
        padding: '20px 20px',
        position: 'relative',
        backgroundColor: 'white',
        margin: '70px 50px 20px',
        border: '1px solid #eee',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0, 0, 0, .12)',
        borderRadius: '6px',
    },
    contentBox: {
        marginTop: '20px'
    },
    dataRangerPicker: {
        '& .rdrDefinedRangesWrapper': {
            display: 'none'
        },
        '& .rdrDayHovered': {
            border: '0px solid transparent'
        }
    },
    calendarBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    event: {
        backgroundColor: '#cc3024 !important',
        padding: '6px 5px !important'
    }
})

export default theme;