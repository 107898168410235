const classes =  theme => ({
    root: {},
    colorPickerBox: {
        marginLeft: '10px',
        width: '40px',
        height: '40px',
        borderRadius: '50%'
    },
    iframe: {
        width: '100%',
        height: '100%'
    },
    noDisplay: {
        display: 'none'
    }
})
export default classes;