import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'
import styles from './styles';
import {
    withStyles,
    Container,
    Box,
    Fab
} from '@material-ui/core';
import Modal from './../../../../../../components/Modal';
import LoadingScreen from './../../../../../../components/LoadingScreen';
import DiaryInfo from '../../../components/RecMessages/Diary/components/DiaryInfo';
import * as apiService from './../../../../../../utils/api';

const localizer = momentLocalizer(moment)


const Diary = ({
    classes,
    userId
}) => {

    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState(true)
    const [diaries, setDiaries] = useState([])
    const [selectedDiary, setSelectedDiary] = useState(null)
    const [events, setEvents] = useState([])
    const [user, setUser] = useState(null)
    const [showInfoDiary, setshowInfoDiary] = useState(false)
    
 

    const selectDiary = (data) => {
        setshowInfoDiary(true)
        setSelectedDiary(data)
    }

    const getEventStyle = () => {
        return {
            className: classes.event
        }
    }

    const handleCloseInfoDiary = () => {
        setshowInfoDiary(false)
    }

    

    useEffect(() => {
        const fetchDiary = async () => {
            const id = userId
            return  apiService.getUserDiary(id);
        }
        const fetchUser = async () => {
            const id = userId
            return  apiService.getUser(id)
        }

        const fetchData = async () => {
            try {
                setLoading(true)
                const [res1, res2] = await Promise.all([fetchDiary(), fetchUser()]);
                const diars = res1.data.content
                const usr = res2.data.content
                const evnts = diars.map((it, index) => {
                    return {
                        title: it.name,
                        start: new Date(it.date),
                        end: new Date(it.date),
                        allDay: true,
                        resource: it
                    }
                })
                setEvents(evnts)
                setDiaries(diars)
                setUser(usr)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchData()
        return () => {
            //
        }
    }, [userId, t])


    if (loading) {
        return <LoadingScreen />
    }


    return (
        <div className={classes.root}>
            <div className={classes.contentBox}>
                <Container maxWidth="xl">
                    <Box className={classes.calendarBox}>
                        <Calendar
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            style={{width: '100%' , flex: '1 1', minHeight: '580px'}}
                            views={['day', 'month']}
                            onSelectEvent={(event) => {
                                selectDiary(event.resource)
                            }}
                            eventPropGetter={getEventStyle}
                        />
                    </Box>  
                </Container>
            </div>
            {showInfoDiary ? (
                <Modal open={showInfoDiary} title={t('Diary')} onClose={handleCloseInfoDiary}>
                    <DiaryInfo diaryInfo={selectedDiary}/>
                </Modal>
            ) : null }
        </div>
    )
}

Diary.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Diary)
