const classes =  theme => ({
    root: {},
    paperSimpleMessage: {
        margin: '5px 5px',
        padding: '30px'
    },
    noDisplay: {
        display: 'none'
    },
    paper: {
        padding: '30px 10px',
        '& legend': {
            fontWeight: 700
        }
    },
    paperHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '20px'
    },
    boxForm: {
        display: 'flex',
        alignItems: 'center',
        padding: '20px',
        border: '1px solid #ebebeb',
        marginBottom: '20px'
    }
})
export default classes;