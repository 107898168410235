export default {
    label: {
        textTransform: 'none',
    },  
    containedPrimary: {
        backgroundColor: '#E35205',
        '&:hover': {
            backgroundColor: '#E35205'
        }
    },
    containedSecondary: {
        backgroundColor: 'green',
    },
    contained: {
        '&.button-add': {
            color: 'white',
            backgroundColor: '#4caf50',
            borderColor: '#4caf50',
            boxShadow: '0 2px 2px 0 rgba(76, 175, 80, .14), 0 3px 1px -2px rgba(76, 175, 80, .2), 0 1px 5px 0 rgba(76, 175, 80, .12)',
            '&:hover': {
                color: 'white',
                backgroundColor: '#4caf50',
                boxShadow: '0 14px 26px -12px rgba(153, 153, 153, 0.43), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px - 5px hsla(0,0%, 60%, .2)'
            }
        }
    }

};
