import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import {
    withStyles,
    Grid,
    LinearProgress 
} from '@material-ui/core';


const LoadingScreen = ({
    classes
}) => {


    const { t } = useTranslation();

    return (
        <Grid
            className={classes.root}
            container
            justify="center"
            alignItems="center"
            style={{ textAlign: 'center', flexGrow: 1, height: '100%'}}>
            <Grid item>
                <LinearProgress className={classes.linearProgress} />
                <h4>{t('loading')}...</h4>
            </Grid>
        </Grid>
    )
}

LoadingScreen.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(LoadingScreen)
