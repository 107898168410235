
const classes = theme => ({
    dialogTitleRoot: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    closeButtonDialogTitle: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'white',
    }
})
export default classes;