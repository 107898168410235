import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import Main from './layouts/Main';
import Routes from './routes';
import theme from './theme';
import ScrollReset from './components/ScrollReset';
import './assets/css/index.css';
import './i18n';
import ContextProvider from './context';
import CheckAuth from './components/CheckAuth';
import DialogInfo from './components/DialogInfo'
import { ConfirmProvider } from 'material-ui-confirm';

function App() {
  return (
    <ConfirmProvider>
      <ContextProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <Main>
                <Routes />
              </Main>
             <DialogInfo />
            </ThemeProvider>
            <ScrollReset />
            <CheckAuth />
          </BrowserRouter>
        </Suspense>
      </ContextProvider>
    </ConfirmProvider>
  );
}

export default App;
