
export default theme => ({
    typographyStyles: {
        flex: 1,
    },
    content: {
        flex: 1,
        '& .MuiButtonBase-root': {
            color: theme.palette.white
        }
    },
    logout: {
        color: theme.palette.white
    },
    logo: {
        padding: '10px 0',
        height: '58px',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        }
    }
})