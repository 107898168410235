
const classes =  theme => ({
    root: {},
    colorPickerBox: {
        marginLeft: '10px',
        width: '40px',
        height: '40px',
        borderRadius: '50%'
    },
    iframe: {
        width: '100%',
        height: '100%'
    },
    noDisplay: {
        display: 'none'
    },
    paper: {
        padding: '30px 10px',
        '& legend': {
            fontWeight: 700
        }
    },
    paperHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '20px'
    },
    boxForm: {
        display: 'flex',
        alignItems: 'center',
        padding: '20px',
        border: '1px solid #ebebeb',
        marginBottom: '20px'
    }
})
export default classes;