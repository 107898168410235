import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { 
    withStyles,
    Grid,
    Box,
} from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import BookIcon from '@material-ui/icons/Book';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import Table from './../../../components/Table'
import LoadingScreen from './../../../components/LoadingScreen'
import * as apiService from './../../../utils/api'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const List = ({
    classes
}) => {

    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [data, setData] = useState([])
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true)


    const actions = [
        {
            icon: () => <ImportContactsIcon />,
            tooltip: t('user info'),
            onClick: (event, rowData) => {
                history.push(`/client/${rowData.id}/info`)
            }
        },
         {
            icon: () => <AccessibilityIcon />,
            tooltip: t('weighings'),
            onClick: (event, rowData) => {
                history.push(`/client/${rowData.id}/weighings`)
            }
        },
        {
            icon: () => <BookIcon />,
            tooltip: t('diary'),
            onClick: (event, rowData) => {
                history.push(`/client/${rowData.id}/diary`)
            }
        },
        {
            icon: () => <MailIcon />,
            tooltip: t('message'),
            onClick: (event, rowData) => {
                history.push(`/client/${rowData.id}/message`)
            }
        }
    ];

    let columns = [
        { title: t('id'), field: 'id', hidden: true },
        { title: t('name'), field: 'name' },
        { title: t('steps bought'), field: 'step' },
        { title: t('new messages'), field: 'newMessages' }
    ]


    useEffect(() => {


        const fetchClients = async () => {
            try {
                setLoading(true)
                let resp = await apiService.getUsers();
                const usersSorted = resp.data.content.sort((a, b) => {
                    return (a.newMessages === b.newMessages) ? 0 : a.newMessages ? -1 : 1;
                })
                let urs = [];
                await Promise.all(usersSorted.map(async (item, index) => {
                    const resp = await apiService.getUserStep(item._id)
                    const stepBought = resp.data.content.length
                    urs[index] = {
                        ...item,
                        stepBought: stepBought
                    }
                }))

                let usersMsgCount = []
                await Promise.all(urs.map(async (item, index) => {
                    const resp = await apiService.getUserMsgUnread(item._id)
                    const msgUnRead = resp.data.content.length
                    usersMsgCount[index] = {
                        ...item,
                        msgUnRead: msgUnRead
                    }
                }))
                 const aux = usersMsgCount.sort((a, b) => {
                    return (a.msgUnRead === b.msgUnRead) ? 0 : a.msgUnRead ? -1 : 1;
                 })
                setUsers(aux)
                setLoading(false)

            } catch (error) {
                setLoading(false)            
            }
        }

        fetchClients()
        return () => {
            //cleanup
        }
    }, [])

    useEffect(() => {
        const setupData = () => {
            const setDataTable = () => {
                return users.map(user => {
                    return {
                        id: user._id,
                        name: `${user.firstName} ${user.lastName}`,
                        step: user.stepBought,
                        newMessages: (user.msgUnRead > 0) ?
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <PriorityHighIcon size={22} className={classes.newMsg} />
                                <p style={{ fontWeight: 'bold', color: '#e91e63' }}>{user.msgUnRead} {t('new messages')}</p>
                            </div> : null
                    }
                })
            }
            setData(setDataTable())
        }
        setupData()
        return () => {
            //
        }
    }, [ i18n.language, users])

  
    if (loading) {
        return <LoadingScreen /> 
    }
   
    return (
        <>
            <div className={classes.root}>
                <div className={classes.iconBox}>
                    <EmojiPeopleIcon />
                </div>
                <Grid container justify="space-between" spacing={3}>
                    <Grid item className={classes.gridHeader}>
                        <h3>{t('clients')}</h3>
                    </Grid>
                </Grid>
                <Box className={classes.boxRoot}>
                    <Table
                        title={null}
                        columns={columns}
                        actions={actions}
                        data={data}
                    />
                </Box>
            </div>
        </>
        
    )
}

List.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(List)



