import { axiosInstance } from '../axios';


const LOGIN = process.env.REACT_APP_LOGIN;
const REQ_RESET_PWD = process.env.REACT_APP_REQ_RESET_PWD;
const ABOUT = process.env.REACT_APP_ABOUT;
const HELP = process.env.REACT_APP_HELP;
const STEPS = process.env.REACT_APP_STEPS
const USER = process.env.REACT_APP_USER 
const TRAINING = process.env.REACT_APP_TRAINING
const TERMS = process.env.REACT_APP_TERMS
const STEP_CODES = process.env.REACT_APP_STEP_CODES
const BLOG = process.env.REACT_APP_BLOG
const MESSAGES = process.env.REACT_APP_MESSAGES
const DOWNLOADS = process.env.REACT_APP_DOWNLOADS
const PRODUCTS = process.env.REACT_APP_PRODUCTS
const DASHBOARD = process.env.REACT_APP_DASHBOARD
const NOTIFICATIONS = process.env.REACT_APP_NOTIFICATIONS
const RECIPES = process.env.REACT_APP_RECIPES




/************************************************ AUTH ***********************************************/
export const login = async (data) =>
    await axiosInstance.post(`${LOGIN}`, data)

export const requestPasswordReset = async (data) =>
    await axiosInstance.post(`${REQ_RESET_PWD}`, data)


/************************************************ ABOUT ***********************************************/
export const getAbout = async () =>
    await axiosInstance.get(`${ABOUT}`)

export const createAbout = async (data) =>
    await axiosInstance.post(`${ABOUT}`, data)

export const updateAbout = async (id, data) =>
    await axiosInstance.put(`${ABOUT}/${id}`, data)



/************************************************ HELP ***********************************************/
export const getHelp = async () =>
    await axiosInstance.get(`${HELP}`)

export const createHelp = async (data) =>
    await axiosInstance.post(`${HELP}`, data)

export const updateHelp = async (id, data) =>
    await axiosInstance.put(`${HELP}/${id}`, data)



/************************************************ STEPS ***********************************************/
export const getSteps = async () =>
    await axiosInstance.get(`${STEPS}`)

export const getStep = async (id) =>
    await axiosInstance.get(`${STEPS}/${id}`)

export const createStep = async (data) =>
    await axiosInstance.post(`${STEPS}`, data)

export const deleteStep = async (id) =>
    await axiosInstance.delete(`${STEPS}/${id}`)


export const updateStep = async (id, data) =>
    await axiosInstance.put(`${STEPS}/${id}`, data)

export const publishStep = async (id) =>
    await axiosInstance.get(`${STEPS}/${id}/publish`)

export const generateStepCodes = async (id) =>
    await axiosInstance.get(`${STEPS}/${id}/generate/50`)


/************************************************ STEP CODES ***********************************************/
export const getStepCodes = async () =>
    await axiosInstance.get(`${STEP_CODES}`)



/************************************************ TRAINING ***********************************************/
export const getTrainings = async (section) =>
    await axiosInstance.get(`${TRAINING}`, { params: { section: section } })

export const getTraining = async (id, section) =>
    await axiosInstance.get(`${TRAINING}/${id}`)

export const createTraining = async (data) =>
    await axiosInstance.post(`${TRAINING}`, data)


export const deleteTraining = async (id) =>
    await axiosInstance.delete(`${TRAINING}/${id}`)

export const updateTraining = async (id, data) =>
    await axiosInstance.put(`${TRAINING}/${id}`, data)

export const publishTraining = async (id, postData) =>
    await axiosInstance.put(`${TRAINING}/${id}/publish`, postData)

export const featureTraining = async (id) =>
    await axiosInstance.get(`${TRAINING}/${id}/feature`)
        
/************************************************ USER ***********************************************/

export const getUsers = async (id) =>
    await axiosInstance.get(`${USER}`)
export const getUser = async (id) =>
    await axiosInstance.get(`${USER}/${id}`)

export const updateUser = async (id, data) =>
    await axiosInstance.put(`${USER}/${id}`, data)

export const getUserMeasurements = async (id) =>
    await axiosInstance.get(`${USER}/${id}/measurements`)

export const getUserDiary = async (id) =>
    await axiosInstance.get(`${USER}/${id}/diary`)

export const getUserInfo = async (id) =>
    await axiosInstance.get(`${USER}/${id}/user-info`)


export const getUserStep = async (id) =>
    await axiosInstance.get(`${USER}/${id}/user-steps`)

export const getUserMsgUnread = async (id) =>
    await axiosInstance.get(`${MESSAGES}`, { params: { read: false, sender: id  } })


/************************************************ TERMS ***********************************************/

export const getTerms = async (id) =>
    await axiosInstance.get(`${TERMS}`)

export const createTerms = async (data) =>
    await axiosInstance.post(`${TERMS}`, data)


/************************************************ BLOG ***********************************************/
export const getBlogs = async (section) =>
    await axiosInstance.get(`${BLOG}`, { params: { section: section } })

export const getBlog = async (id) =>
    await axiosInstance.get(`${BLOG}/${id}`)

export const createBlog = async (data) =>
    await axiosInstance.post(`${BLOG}`, data)

export const deleteBlog = async (id) =>
    await axiosInstance.delete(`${BLOG}/${id}`)


export const updateBlog = async (id, data) =>
    await axiosInstance.put(`${BLOG}/${id}`, data)


export const publishBlog = async (id, postData) =>
    await axiosInstance.put(`${BLOG}/${id}/publish`, postData)

export const featureBlog = async (id) =>
    await axiosInstance.get(`${BLOG}/${id}/feature`)


/************************************************ MESSAGES ***********************************************/
export const getUserMessages = async (userId) =>
    await axiosInstance.get(`${MESSAGES}/${userId}`)

export const sendMessage = async (postData) =>
    await axiosInstance.post(`${MESSAGES}`, postData)

export const markAsRead = async (id) =>
    await axiosInstance.get(`${USER}/${id}/read-message`)

export const markMsgAsRead = async (id) =>
    await axiosInstance.get(`${MESSAGES}/${id}/read`)

/************************************************ DOWNLOAD ***********************************************/
export const getDownloads = async () =>
    await axiosInstance.get(`${DOWNLOADS}`)

export const getDownload = async (id) =>
    await axiosInstance.get(`${DOWNLOADS}/${id}`)

export const createDownload = async (data) =>
    await axiosInstance.post(`${DOWNLOADS}`, data)

export const deleteDownload= async (id) =>
    await axiosInstance.delete(`${DOWNLOADS}/${id}`)


export const updateDownload = async (id, data) =>
    await axiosInstance.put(`${DOWNLOADS}/${id}`, data)

export const publishDownload = async (id) =>
    await axiosInstance.get(`${DOWNLOADS}/${id}/publish`)



/************************************************ PRODUCT ***********************************************/
export const getProducts = async () =>
    await axiosInstance.get(`${PRODUCTS}`)

export const getProduct = async (id) =>
    await axiosInstance.get(`${PRODUCTS}/${id}`)

export const createProduct = async (data) =>
    await axiosInstance.post(`${PRODUCTS}`, data)

export const deleteProduct = async (id) =>
    await axiosInstance.delete(`${PRODUCTS}/${id}`)


export const updateProduct = async (id, data) =>
    await axiosInstance.put(`${PRODUCTS}/${id}`, data)

export const publishProduct = async (id) =>
    await axiosInstance.get(`${PRODUCTS}/${id}/publish`)



/************************************************ DASHBOARD ***********************************************/
export const getDashboard = async () =>
    await axiosInstance.get(`${DASHBOARD}`)



/************************************************ NOTIFICATIONS ***********************************************/
export const getNotifications = async (params) =>
    await axiosInstance.get(`${NOTIFICATIONS}`, { params: params })

export const createNotifications = async (data) =>
    await axiosInstance.post(`${NOTIFICATIONS}`, data)

export const deleteNotification = async (id) =>
    await axiosInstance.delete(`${NOTIFICATIONS}/${id}`)

export const getNotification = async (id) =>
    await axiosInstance.get(`${NOTIFICATIONS}/${id}`)

export const updateNotification = async (data) =>
    await axiosInstance.put(`${NOTIFICATIONS}`, data)


/************************************************ RECIPE ***********************************************/
export const getRecipes = async () =>
    await axiosInstance.get(`${RECIPES}`)

export const getRecipe = async (id) =>
    await axiosInstance.get(`${RECIPES}/${id}`)

export const createRecipe = async (data) =>
    await axiosInstance.post(`${RECIPES}`, data)

export const deleteRecipe = async (id) =>
    await axiosInstance.delete(`${RECIPES}/${id}`)


export const updateRecipe = async (id, data) =>
    await axiosInstance.put(`${RECIPES}/${id}`, data)

export const publishRecipe = async (id) =>
    await axiosInstance.get(`${RECIPES}/${id}/publish`)




