
const classes =  theme => ({
    iconBox: {
        background: 'linear-gradient(60deg,#ffa726,#fb8c00)',
        boxShadow: '0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(255,152,0,.4)',
        borderRadius: '5px',
        marginTop: '-65px',
        padding: '15px',
        display: 'flex',
        zIndex: 1,
        position: 'absolute',
        fontSize: '3.5rem',
        minWidth: '80px',
        minHeight: '80px',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        '& svg': {
            fontSize: '3.5rem'
        }
    },
    headerBox: {
        paddingLeft: '130px !important',
        paddingBottom: '30px',
        display: 'flex',
        justifyContent: 'flex-end',
        '& h1': {
            fontWeight: 300,
            lineHeight: '1.4em',
            marginTop: '-10px'
        }
    },
    contentBox: {
        marginTop: '10px',
        padding: '30px'
    },
    card: {
        margin: '20px',
        padding: '20px 20px',
        position: 'relative',
        backgroundColor: 'white',
        border: '1px solid #eee',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0, 0, 0, .12)',
        borderRadius: '6px',
        width: '100%'
    },
    iconBoxCard: {
        background: 'linear-gradient(60deg,#ffa726,#fb8c00)',
        boxShadow: '0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(255,152,0,.4)',
        borderRadius: '5px',
        marginTop: '-100px',
        padding: '15px',
        display: 'flex',
        zIndex: 1,
        position: 'absolute',
        fontSize: '1.5rem',
        minWidth: '40px',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        '& svg': {
            fontSize: '1.5rem'
        }
    },
    cardHeaderBox: {
        paddingLeft: '80px !important',
        paddingBottom: '30px',
        display: 'flex',
        justifyContent: 'flex-end',
        '& h3': {
            fontWeight: '600 !important',
            lineHeight: '1.4em !important',
            marginTop: '-10px'
        }
    },
    iconBoxCardPersonalData: {
        background: 'linear-gradient(60deg,#ffa726,#fb8c00)',
        boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(255 152 0/40%)'
    },
    iconBoxCardPhysicalExercise: {
        background: 'linear-gradient(60deg,#dc1e1e,#d81b60)',
        boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(233 30 99 / 40%)'
    },
    iconBoxCardSugeries: {
        background: 'linear-gradient(60deg,#c709ca,#7d03a5)',
        boxShadow: '0 4px 20px 0 rgb(0 0 0 /14 %), 0 7px 10px -5px rgb(118 3 183 /40%)'
    },
    iconBoxCardMedicalHistory: {
        background: 'linear-gradient(60deg,#26c6da,#00acc1)',
        boxShadow: '0 4px 20px 0 rgb(0 0 0 /14%), 0 7px 10px -5px rgb(118 3 183 / 40%)',
    }
})

export default classes;