import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/pl';
import 'moment/locale/es';
import { 
    withStyles,
    Grid,
    Box,
    Fab
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import Table from '../../../../components/Table';
import EditIcon from '@material-ui/icons/Edit';
import LoadingScreen from '../../../../components/LoadingScreen'
import * as apiService from '../../../../utils/api';

const List = ({
    classes
}) => {

    const { t, i18n } = useTranslation();
    moment.locale(i18n.language);
    const history = useHistory();
    const confirm = useConfirm();
    const [data, setData] = useState([])
    const [notifications, setNotifications] = useState([])
    const [loading, setLoading] = useState(true)


    const actions = [
        {
            icon: 'delete',
            tooltip: t('delete'),
            onClick: (event, rowData) => { handleDeleteClick(rowData) }
        },
        {
            icon: () => <EditIcon />,
            tooltip: t('detail'),
            onClick: (event, rowData) => {
                history.push(`/notifications/manual/edit/${rowData.id}`)
            }
        }
    ];


    let columns = [
        { title: t('id'), field: 'id', hidden: true },
        { title: t('title'), field: 'title' },
        { title: t('date'), field: 'date' },
        { title: t('type'), field: 'type' }
    ]


    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const params = {type: 'manual'}
                setLoading(true)
                const resp = await apiService.getNotifications(params);
                setNotifications(resp.data.content)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
      
        fetchNotifications()
        return () => {
            //cleanup
        }
    }, [])

    useEffect(() => {
        const setupData = () => {
            const setDataTable = () => {
                return notifications.map(notification => {
                    return {
                        id: notification._id,
                        title: notification[`title_${i18n.language}`],
                        date: notification.hasOwnProperty('runAt') ? `${moment(notification.runAt).format('lll')}` : null,
                        type: t(notification.type)
                    }
                })
            }
            setData(setDataTable())
        }
        setupData()
        return () => {
            //
        }
    }, [i18n.language, notifications])

  

    const handleDeleteClick = (data) => {
        confirm({ title: t( 'are you sure' ), description: t( 'are you sure to delete this item') })
            .then(async () => {
                try {
                    setLoading(true)
                    await apiService.deleteNotification(data.id);
                    const params = { type: 'manual' }
                    const resp = await apiService.getNotifications(params);
                    setNotifications(resp.data.content)
                    setLoading(false)
                } catch (error) {

                }
            })
            .catch(() => { });
    }

 

    return (
        <>
            <div className={classes.root}>
                <div className={classes.iconBox}>
                    <InsertLinkIcon />
                </div>
                <Grid container justify="space-between" spacing={3} >
                    <Grid item className={classes.gridHeader}>
                        <h3>{t('notifications')}</h3>
                    </Grid>
                    <Grid item >
                        <Fab onClick={() => history.push('/notifications/manual/create')} >
                            <AddIcon />
                        </Fab>
                    </Grid>
                </Grid>
                <Box className={classes.boxRoot}>
                    <Table
                        title={null}
                        columns={columns}
                        actions={actions}
                        data={data}
                    />
                </Box>
                {loading ? <LoadingScreen /> : null}
            </div>
        </>
        
    )
}

List.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(List)



