
export default theme => ({
    root: {
        padding: '15px 30px 10px',
        width: '100%',
        height: '100%'
    },
    buttonDownloadPdf: {
        margin:'0 10px'
    }
})