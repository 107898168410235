import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import CountUp from 'react-countup';
import styles from './styles';
import clsx from 'clsx';
import {
    withStyles
} from '@material-ui/core';


const MiniCard = ({
    classes,
    id,
    icon,
    title,
    data
}) => {

    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <div className={clsx(
                { [classes.iconBox]: true },
                { [classes.iconBoxUser]: id === 'user' },
                { [classes.iconBoxBLog]: id === 'bLog' },
                { [classes.iconBoxNews]: id === 'news' },
                { [classes.iconBoxStepPurchased]: id === 'step purchased' },
                { [classes.iconBoxPurchasedStep]: id === 'purcashed client' },
                { [classes.iconNotificationActive]: id === 'notifications active' },
                { [classes.iconBoxTotalMessageRevieved]: id === 'message received' },
                { [classes.iconBoxChallenges]: id === 'challenges' },
                { [classes.iconBoxTraining]: id === 'training' },
                { [classes.iconBoxDownload]: id === 'download' },
                { [classes.iconBoxProduct]: id === 'product' }
            )}
            >
               {icon}
            </div>
            <div className={classes.headerBox}>
                <p>{t(title)}</p>
                <span>
                    <CountUp delay={0.03} end={data} />
                </span>
            </div>
            <div className={classes.infoBox}>
               {t('updated now')}
            </div>
        </div>
    )
}

MiniCard.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(MiniCard)



