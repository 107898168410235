import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Context } from './../../../context';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    withStyles,
    FormControl,
    Grid,
    TextField,
    Box,
    Paper,
    FormLabel,
    IconButton,
    InputLabel,
    Select,
    Input,
    Chip,
    MenuItem
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { Formik, FieldArray } from 'formik';
import FileDropzone from './../../../components/FileDropzone';
import SubmitButton from '../../../components/SubmitButton';
import RichEditorText from './../../../components/RichEditorText';


import { toBase64, getFileDto, getImages, getfiles} from './../../../utils/util'
import * as apiService from './../../../utils/api';
import * as actionTypes from './../../../actions';




const defaultInitialValues = {
    recipes: [],
    title_pt: '',
    text_pt: '',
    number_pt: '',
    videos_pt: [],
    title_en: '',
    text_en: '',
    number_en: '',
    videos_en: [],
    title_es: '',
    text_es: '',
    number_es: '',
    videos_es: [],
    title_pl: '',
    text_pl: '',
    number_pl: '',
    videos_pl: [],
    published: false
}

const videos_TYPE = [
    { id: '0', name: 'initiated level' },
    { id: '1', name: 'intermediate level' },
    { id: '2', name: 'expert level' }
]






const Form = ({
    classes,
    lng,
    step,
    edit
}) => {




    const { state, dispatch } = useContext(Context);
    const { t, i18n} = useTranslation();
    const [recipes, setRecipes] = useState([])
    const [disabled, setDisabled] = useState(false)




    
    const [images, setImages] = useState({
        image_pt: null,
        image_en: null,
        image_pl: null,
        image_es: null
    })


    const [files, setFiles] = useState({
        file_pt: null,
        file_en: null,
        file_pl: null,
        file_es: null
    })


    const onUploadImages = async (fls) => {
        const fl = fls[0]
        let preview = null;
        let resp = null;
        let file = null;
        if (fl) {
            preview = URL.createObjectURL(fl) 
            resp = await toBase64(fl)
            file = {
                file: resp,
                type: fl.type,
                filename: `${new Date().getTime()}_${fl.name}`,
                preview: preview
            }
        }
        let filesCopy = { ...images }
        filesCopy[`image_${lng}`] = file
        setImages(filesCopy)
    }

    const onUploadPdf = async (fls) => {
        const fl = fls[0]
        let resp = null;
        let file = null;
        if (fl) {
            resp = await toBase64(fl)
            file = {
                file: resp,
                type: fl.type,
                filename: `${new Date().getTime()}_${fl.name}`,
                preview: null
            }
        }
        let filesCopy = { ...files }
        filesCopy[`file_${lng}`] = file
        setFiles(filesCopy)
    }


    useEffect(() => {
        const setupPermissionEdit = () => {
            if (state?.role === 'admin') {
                const disabled = (state?.user.language !== lng)
                setDisabled(disabled)
            }
        }

        const fetchRecipes = async () => {
            try {
                const resp = await apiService.getRecipes();
                setRecipes(resp.data.content)
            } catch (error) {
            }
        }
        
        if (step) {
            const imgs = {
                image_pt: step.image_pt,
                image_en: step.image_en,
                image_pl: step.image_pl,
                image_es: step.image_es
            }
            const fls = {
                file_pt: step.file_pt,
                file_en: step.file_en,
                file_pl: step.file_pl,
                file_es: step.file_es
            }
            setImages(imgs)
            setFiles(fls)
        }
        fetchRecipes()
        setupPermissionEdit()
        return () => {}
    }, [lng, state?.role, state?.user.language, step])

  
  
    return (
        <Formik
            enableReinitialize={true}
            initialValues={step && Object.keys(step).length !== 0 ? getDataForm(step) : defaultInitialValues}
            onSubmit={async (values, { resetForm }) => {
                try {
                    const imgs = {
                        image_pt: await getFileDto(images.image_pt),
                        image_en: await getFileDto(images.image_en),
                        image_pl: await getFileDto(images.image_pl),
                        image_es: await getFileDto(images.image_es)
                    }
                    const _files = {
                        file_pt: await getFileDto(files.file_pt),
                        file_en: await getFileDto(files.file_en),
                        file_pl: await getFileDto(files.file_pl),
                        file_es: await getFileDto(files.file_es)
                    }
                    const data = {
                        ...getDto(values),
                        ...getImages(imgs),
                        ...getfiles(_files)
                    }
                    if (edit) {
                        await apiService.updateStep(step._id, data)
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })

                    } else {
                        await apiService.createStep(data)
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })
                    }
                } catch (error) {
                    if (edit) {
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('updated error') } })
                    } else {
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('updated error') } })
                    }
                }
            }}
        >   
            {props => {
                return (
                    <form onSubmit={props.handleSubmit}>
                        <div className={classes.root}>
                            <Box id="box-pt" className={clsx({ [classes.noDisplay]: lng !== 'pt' })}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                id="title_pt"
                                                placeholder=""
                                                autoComplete="off"
                                                variant='outlined'
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_pt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_pt && props.touched.title_pt}
                                                helperText={(props.errors.title_pt && props.touched.title_pt) && props.errors.title_pt}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                id="number_pt"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                variant='outlined'
                                                label={`${t('number')}`}
                                                value={props.values.number_pt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.number_pt && props.touched.number_pt}
                                                helperText={(props.errors.number_pt && props.touched.number_pt) && props.errors.number_pt}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {lng !== 'pt' ? null : (
                                        <Grid item xs={12}>
                                            <RichEditorText
                                                name="text_pt"
                                                label={t('diet')}
                                                value={props.values.text_pt}
                                                onChange={data => props.setFieldValue('text_pt', data)}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <FieldArray name="videos_pt">
                                            {({ push, remove }) => (
                                                <Paper elevation={3} className={classes.paper}>
                                                    <div className={classes.paperHeader}>
                                                        <FormLabel component="legend">{t('trainingsSimple')}</FormLabel>
                                                        <IconButton
                                                            aria-label="add"
                                                            onClick={() =>
                                                                push({ title: '', url: '', type: ''})
                                                            }>
                                                            <AddIcon fontSize="large" />
                                                        </IconButton>
                                                    </div>
                                                    {props.values.videos_pt.map((video, index) => {
                                                        const videoTitle = `videos_pt[${index}].title`;
                                                        const videoUrl = `videos_pt[${index}].url`;
                                                        const videoType = `videos_pt[${index}].type`;
                                                        return (
                                                            <div className={classes.boxForm}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={9}>
                                                                        <FormControl fullWidth>
                                                                            <TextField disabled={disabled}
                                                                                variant='outlined'
                                                                                id={videoTitle}
                                                                                name={videoTitle}
                                                                                placeholder=""
                                                                                autoComplete="off"
                                                                                margin="dense"
                                                                                label={`${t('video title')}`}
                                                                                value={video.title}
                                                                                onChange={event => { props.setFieldValue(videoTitle, event.target.value)} }
                                                                                onBlur={props.handleBlur}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={3} >
                                                                        <FormControl fullWidth>
                                                                            <TextField disabled={disabled}
                                                                                id={videoType}
                                                                                name={videoType}
                                                                                select
                                                                                margin="dense"
                                                                                SelectProps={{
                                                                                    native: true,
                                                                                }}
                                                                                variant="outlined"
                                                                                value={video.type}
                                                                                onChange={event => { props.setFieldValue(videoType, event.target.value) } }
                                                                            >
                                                                                {videos_TYPE.map((videoType) => (
                                                                                    <option key={videoType.id} value={videoType.id}>
                                                                                        {t(videoType.name)}
                                                                                    </option>
                                                                                ))}
                                                                            </TextField>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormControl fullWidth>
                                                                            <TextField disabled={disabled}
                                                                                variant='outlined'
                                                                                id={videoUrl}
                                                                                name={videoUrl}
                                                                                placeholder=""
                                                                                autoComplete="off"
                                                                                margin="dense"
                                                                                label={`${t('video url')}`}
                                                                                value={video.url}
                                                                                onChange={event => { props.setFieldValue(videoUrl, event.target.value) }}
                                                                                onBlur={props.handleBlur}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                                <div style={{ marginLeft: '30px' }}>
                                                                    <IconButton
                                                                        aria-label="add"
                                                                          onClick={() => {
                                                                            if(disabled) { return null}
                                                                            remove(index)
                                                                        }}
                                                                    >
                                                                        <DeleteIcon fontSize="small" />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </Paper>
                                                )}
                                        </FieldArray>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper elevation={3} className={classes.paper}>
                                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                                <InputLabel>{t('recipes')}</InputLabel>
                                                <Select disabled={disabled}
                                                    id="recipes"
                                                    multiple
                                                    value={props.values.recipes ? props.values.recipes : []}
                                                    onChange={event => props.setFieldValue('recipes', event.target.value)}
                                                    input={<Input />}
                                                    renderValue={(selected) => {
                                                        return (
                                                            <div className={classes.chips}>
                                                                {selected.map((value) => (
                                                                    <Chip
                                                                        style={{ margin: '15px 10px' }}
                                                                        key={value._id}
                                                                        label={value.title_pt}
                                                                        className={classes.chip}
                                                                        onMouseDown={(event) => {
                                                                            event.stopPropagation();
                                                                        }}
                                                                        onDelete={() => { if(disabled) { return null }
                                                                            const rps = props.values.recipes.filter((rec) => rec._id !== value._id)
                                                                            props.setFieldValue('recipes', rps)
                                                                        }} />
                                                                ))}
                                                            </div>
                                                        )
                                                    }}

                                                >
                                                    {recipes.map((recipe) => (
                                                        <MenuItem key={recipe._id} value={recipe}>
                                                            {recipe[`title_${i18n.language}`]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                          </Paper>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} pdf={false} onAcceptFiles={onUploadImages} data={step.image_pt}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FileDropzone disabled={disabled} title={t('upload pdf')} pdf={true} onAcceptFiles={onUploadPdf} data={step.file_pt} />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box id="box-en" className={clsx({ [classes.noDisplay]: lng !== 'en' })}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                variant='outlined'
                                                id="title_en"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_en}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_en && props.touched.title_en}
                                                helperText={(props.errors.title_en && props.touched.title_en) && props.errors.title_en}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                variant='outlined'
                                                id="number_en"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('number')}`}
                                                value={props.values.number_en}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.number_en && props.touched.number_en}
                                                helperText={(props.errors.number_en && props.touched.number_en) && props.errors.number_en}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {lng !== 'en' ? null : (
                                        <Grid item xs={12}>
                                            <RichEditorText
                                                name="text_en"
                                                label={t('diet')}
                                                value={props.values.text_en}
                                                onChange={data => props.setFieldValue('text_en', data)}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <FieldArray name="videos_en" >
                                            {({ push, remove }) => (
                                                <Paper elevation={3} className={classes.paper}>
                                                    <div className={classes.paperHeader}>
                                                        <FormLabel component="legend">{t('trainingsSimple')}</FormLabel>
                                                        <IconButton
                                                            aria-label="add"
                                                            onClick={() =>
                                                                push({ title: '', url: '', type: '' })
                                                            }>
                                                            <AddIcon fontSize="large" />
                                                        </IconButton>
                                                    </div>
                                                    {props.values.videos_en.map((video, index) => {
                                                        const videoTitle = `videos_en[${index}].title`;
                                                        const videoUrl = `videos_en[${index}].url`;
                                                        const videoType = `videos_en[${index}].type`;
                                                        return (
                                                            <div className={classes.boxForm}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={9}>
                                                                        <FormControl fullWidth>
                                                                            <TextField disabled={disabled}
                                                                                variant='outlined'
                                                                                id={videoTitle}
                                                                                name={videoTitle}
                                                                                placeholder=""
                                                                                autoComplete="off"
                                                                                margin="dense"
                                                                                label={`${t('video title')}`}
                                                                                value={video.title}
                                                                                onChange={event => { props.setFieldValue(videoTitle, event.target.value) }}
                                                                                onBlur={props.handleBlur}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={3} >
                                                                        <FormControl fullWidth>
                                                                            <TextField disabled={disabled}
                                                                                id={videoType}
                                                                                name={videoType}
                                                                                select
                                                                                margin="dense"
                                                                                SelectProps={{
                                                                                    native: true,
                                                                                }}
                                                                                variant="outlined"
                                                                                value={video.type}
                                                                                onChange={event => { props.setFieldValue(videoType, event.target.value) }}
                                                                            >
                                                                                {videos_TYPE.map((videoType) => (
                                                                                    <option key={videoType.id} value={videoType.id}>
                                                                                        {t(videoType.name)}
                                                                                    </option>
                                                                                ))}
                                                                            </TextField>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormControl fullWidth>
                                                                            <TextField disabled={disabled}
                                                                                variant='outlined'
                                                                                id={videoUrl}
                                                                                name={videoUrl}
                                                                                placeholder=""
                                                                                autoComplete="off"
                                                                                margin="dense"
                                                                                label={`${t('video url')}`}
                                                                                value={video.url}
                                                                                onChange={event => { props.setFieldValue(videoUrl, event.target.value) }}
                                                                                onBlur={props.handleBlur}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                                <div style={{ marginLeft: '30px' }}>
                                                                    <IconButton
                                                                        aria-label="add"
                                                                          onClick={() => {
                                                                            if(disabled) { return null}
                                                                            remove(index)
                                                                        }}
                                                                    >
                                                                        <DeleteIcon fontSize="small" />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </Paper>
                                            )}
                                        </FieldArray>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper elevation={3} className={classes.paper}>
                                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                                <InputLabel>{t('recipes')}</InputLabel>
                                                <Select disabled={disabled}
                                                    id="recipes"
                                                    multiple
                                                    value={props.values.recipes ? props.values.recipes : []}
                                                    onChange={event => props.setFieldValue('recipes', event.target.value)}
                                                    input={<Input />}
                                                    renderValue={(selected) => {
                                                        return (
                                                            <div className={classes.chips}>
                                                                {selected.map((value) => (
                                                                    <Chip
                                                                        style={{ margin: '15px 10px' }}
                                                                        key={value._id}
                                                                        label={value.title_pt}
                                                                        className={classes.chip}
                                                                        onMouseDown={(event) => {
                                                                            event.stopPropagation();
                                                                        }}
                                                                        onDelete={() => { if(disabled) { return null }
                                                                            const rps = props.values.recipes.filter((rec) => rec._id !== value._id)
                                                                            props.setFieldValue('recipes', rps)
                                                                        }} />
                                                                ))}
                                                            </div>
                                                        )
                                                    }}

                                                >
                                                    {recipes.map((recipe) => (
                                                        <MenuItem key={recipe._id} value={recipe}>
                                                            {recipe[`title_${i18n.language}`]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} pdf={false} onAcceptFiles={onUploadImages} data={step.image_en}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FileDropzone disabled={disabled} title={t('upload pdf')} pdf={true} onAcceptFiles={onUploadPdf} data={step.file_en} />
                                    </Grid>
                                </Grid>
                            </Box>
                           
                            <Box id="box-es" className={clsx({ [classes.noDisplay]: lng !== 'es' })}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                variant='outlined'
                                                id="title_es"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_es}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_es && props.touched.title_es}
                                                helperText={(props.errors.title_es && props.touched.title_es) && props.errors.title_es}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                variant='outlined'
                                                id="number_es"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('number')}`}
                                                value={props.values.number_es}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.number_es && props.touched.number_es}
                                                helperText={(props.errors.number_es && props.touched.number_es) && props.errors.number_es}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {lng !== 'es' ? null : (
                                        <Grid item xs={12}>
                                            <RichEditorText
                                                name="text_es"
                                                label={t('diet')}
                                                value={props.values.text_es}
                                                onChange={data => props.setFieldValue('text_es', data)}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <FieldArray name="videos_es">
                                            {({ push, remove }) => (
                                                <Paper elevation={3} className={classes.paper}>
                                                    <div className={classes.paperHeader}>
                                                        <FormLabel component="legend">{t('trainingsSimple')}</FormLabel>
                                                        <IconButton
                                                            aria-label="add"
                                                            onClick={() =>
                                                                push({ title: '', url: '', type: '' })
                                                            }>
                                                            <AddIcon fontSize="large" />
                                                        </IconButton>
                                                    </div>
                                                    {props.values.videos_es.map((video, index) => {
                                                        const videoTitle = `videos_es[${index}].title`;
                                                        const videoUrl = `videos_es[${index}].url`;
                                                        const videoType = `videos_es[${index}].type`;
                                                        return (
                                                            <div className={classes.boxForm}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={9}>
                                                                        <FormControl fullWidth>
                                                                            <TextField disabled={disabled}
                                                                                variant='outlined'
                                                                                id={videoTitle}
                                                                                name={videoTitle}
                                                                                placeholder=""
                                                                                autoComplete="off"
                                                                                margin="dense"
                                                                                label={`${t('video title')}`}
                                                                                value={video.title}
                                                                                onChange={event => { props.setFieldValue(videoTitle, event.target.value) }}
                                                                                onBlur={props.handleBlur}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={3} >
                                                                        <FormControl fullWidth>
                                                                            <TextField disabled={disabled}
                                                                                id={videoType}
                                                                                name={videoType}
                                                                                select
                                                                                margin="dense"
                                                                                SelectProps={{
                                                                                    native: true,
                                                                                }}
                                                                                variant="outlined"
                                                                                value={video.type}
                                                                                onChange={event => { props.setFieldValue(videoType, event.target.value) }}
                                                                            >
                                                                                {videos_TYPE.map((videoType) => (
                                                                                    <option key={videoType.id} value={videoType.id}>
                                                                                        {t(videoType.name)}
                                                                                    </option>
                                                                                ))}
                                                                            </TextField>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormControl fullWidth>
                                                                            <TextField disabled={disabled}
                                                                                variant='outlined'
                                                                                id={videoUrl}
                                                                                name={videoUrl}
                                                                                placeholder=""
                                                                                autoComplete="off"
                                                                                margin="dense"
                                                                                label={`${t('video url')}`}
                                                                                value={video.url}
                                                                                onChange={event => { props.setFieldValue(videoUrl, event.target.value) }}
                                                                                onBlur={props.handleBlur}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                                <div style={{ marginLeft: '30px' }}>
                                                                    <IconButton
                                                                        aria-label="add"
                                                                          onClick={() => {
                                                                            if(disabled) { return null}
                                                                            remove(index)
                                                                        }}
                                                                    >
                                                                        <DeleteIcon fontSize="small" />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </Paper>
                                            )}
                                        </FieldArray>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper elevation={3} className={classes.paper}>
                                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                                <InputLabel>{t('recipes')}</InputLabel>
                                                <Select disabled={disabled}
                                                    id="recipes"
                                                    multiple
                                                    value={props.values.recipes ? props.values.recipes : []}
                                                    onChange={event => props.setFieldValue('recipes', event.target.value)}
                                                    input={<Input />}
                                                    renderValue={(selected) => {
                                                        return (
                                                            <div className={classes.chips}>
                                                                {selected.map((value) => (
                                                                    <Chip
                                                                        style={{ margin: '15px 10px' }}
                                                                        key={value._id}
                                                                        label={value.title_pt}
                                                                        className={classes.chip}
                                                                        onMouseDown={(event) => {
                                                                            event.stopPropagation();
                                                                        }}
                                                                        onDelete={() => { if(disabled) { return null }
                                                                            const rps = props.values.recipes.filter((rec) => rec._id !== value._id)
                                                                            props.setFieldValue('recipes', rps)
                                                                        }} />
                                                                ))}
                                                            </div>
                                                        )
                                                    }}

                                                >
                                                    {recipes.map((recipe) => (
                                                        <MenuItem key={recipe._id} value={recipe}>
                                                            {recipe[`title_${i18n.language}`]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} pdf={false} onAcceptFiles={onUploadImages} data={step.image_es}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FileDropzone disabled={disabled} title={t('upload pdf')} pdf={true} onAcceptFiles={onUploadImages} data={step.file_es} />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box id="box-pl" className={clsx({ [classes.noDisplay]: lng !== 'pl' })}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                variant='outlined'
                                                id="title_pl"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_pl}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_pl && props.touched.title_pl}
                                                helperText={(props.errors.title_pl && props.touched.title_pl) && props.errors.title_pl}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                variant='outlined'
                                                id="number_pl"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('number')}`}
                                                value={props.values.number_pl}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.number_pl && props.touched.number_pl}
                                                helperText={(props.errors.number_pl && props.touched.number_pl) && props.errors.number_pl}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {lng !== 'pl' ? null : (
                                        <Grid item xs={12}>
                                            <RichEditorText
                                                name="text_pl"
                                                label={t('diet')}
                                                value={props.values.text_pl}
                                                onChange={data => props.setFieldValue('text_pl', data)}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <FieldArray name="videos_pl">
                                            {({ push, remove }) => (
                                                <Paper elevation={3} className={classes.paper}>
                                                    <div className={classes.paperHeader}>
                                                        <FormLabel component="legend">{t('trainingsSimple')}</FormLabel>
                                                        <IconButton
                                                            aria-label="add"
                                                            onClick={() =>
                                                                push({ title: '', url: '', type: '' })
                                                            }>
                                                            <AddIcon fontSize="large" />
                                                        </IconButton>
                                                    </div>
                                                    {props.values.videos_pl.map((video, index) => {
                                                        const videoTitle = `videos_pl[${index}].title`;
                                                        const videoUrl = `videos_pl[${index}].url`;
                                                        const videoType = `videos_pl[${index}].type`;
                                                        return (
                                                            <div className={classes.boxForm}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={9}>
                                                                        <FormControl fullWidth>
                                                                            <TextField disabled={disabled}
                                                                                variant='outlined'
                                                                                id={videoTitle}
                                                                                name={videoTitle}
                                                                                placeholder=""
                                                                                autoComplete="off"
                                                                                margin="dense"
                                                                                label={`${t('video title')}`}
                                                                                value={video.title}
                                                                                onChange={event => { props.setFieldValue(videoTitle, event.target.value) }}
                                                                                onBlur={props.handleBlur}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={3} >
                                                                        <FormControl fullWidth>
                                                                            <TextField disabled={disabled}
                                                                                id={videoType}
                                                                                name={videoType}
                                                                                select
                                                                                margin="dense"
                                                                                SelectProps={{
                                                                                    native: true,
                                                                                }}
                                                                                variant="outlined"
                                                                                value={video.type}
                                                                                onChange={event => { props.setFieldValue(videoType, event.target.value) }}
                                                                            >
                                                                                {videos_TYPE.map((videoType) => (
                                                                                    <option key={videoType.id} value={videoType.id}>
                                                                                        {t(videoType.name)}
                                                                                    </option>
                                                                                ))}
                                                                            </TextField>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormControl fullWidth>
                                                                            <TextField disabled={disabled}
                                                                                variant='outlined'
                                                                                id={videoUrl}
                                                                                name={videoUrl}
                                                                                placeholder=""
                                                                                autoComplete="off"
                                                                                margin="dense"
                                                                                label={`${t('video url')}`}
                                                                                value={video.url}
                                                                                onChange={event => { props.setFieldValue(videoUrl, event.target.value) }}
                                                                                onBlur={props.handleBlur}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                                <div style={{ marginLeft: '30px' }}>
                                                                    <IconButton
                                                                        aria-label="add"
                                                                        onClick={() => {
                                                                            if(disabled) { return null}
                                                                            remove(index)
                                                                        }}
                                                                    >
                                                                        <DeleteIcon fontSize="small" />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </Paper>
                                            )}
                                        </FieldArray>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper elevation={3} className={classes.paper}>
                                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                                <InputLabel>{t('recipes')}</InputLabel>
                                                <Select disabled={disabled}
                                                    id="recipes"
                                                    multiple
                                                    value={props.values.recipes ? props.values.recipes : []}
                                                    onChange={event => props.setFieldValue('recipes', event.target.value)}
                                                    input={<Input />}
                                                    renderValue={(selected) => {
                                                        return (
                                                            <div className={classes.chips}>
                                                                {selected.map((value) => (
                                                                    <Chip
                                                                        style={{ margin: '15px 10px' }}
                                                                        key={value._id}
                                                                        label={value.title_pt}
                                                                        className={classes.chip}
                                                                        onMouseDown={(event) => {
                                                                            event.stopPropagation();
                                                                        }}
                                                                        onDelete={() => { if(disabled) { return null }
                                                                            const rps = props.values.recipes.filter((rec) => rec._id !== value._id)
                                                                            props.setFieldValue('recipes', rps)
                                                                        }} />
                                                                ))}
                                                            </div>
                                                        )
                                                    }}

                                                >
                                                    {recipes.map((recipe) => (
                                                        <MenuItem key={recipe._id} value={recipe}>
                                                            {recipe[`title_${i18n.language}`]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} pdf={false} onAcceptFiles={onUploadImages} data={step.image_pl}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FileDropzone disabled={disabled} title={t('upload pdf')} pdf={true} onAcceptFiles={onUploadImages} data={step.file_pl}/>
                                    </Grid>
                                </Grid>
                            </Box>
                           
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', margin: '30px 0' }}>
                                <SubmitButton
                                    isSubmitting={props.isSubmitting}>
                                    {t('save')}
                                </SubmitButton>
                            </Grid>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

Form.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Form)

const getDataForm = (step) => {
    let data = { ...step } 
    return data
} 



const getDto = (values) => {
    let data = {
        recipes: values.recipes.map(it => it._id),
        title_pt: values.title_pt,
        text_pt: values.text_pt,
        number_pt: values.number_pt,
        videos_pt: values.videos_pt,
        title_en: values.title_en,
        text_en: values.text_en,
        number_en: values.number_en,
        videos_en: values.videos_en,
        title_es: values.title_es,
        text_es: values.text_es,
        number_es: values.number_es,
        videos_es: values.videos_es,
        title_pl: values.title_pl,
        text_pl: values.text_pl,
        number_pl: values.number_pl,
        videos_pl: values.videos_pl,
        published: values.published
    }
    return data;
}




