import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import {
    withStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
} from '@material-ui/core';
import SubmitButton from '../../components/SubmitButton';


const ModalDateTime = ({
    data,
    classes,
    onCancel,
    onSelect
}) => {

    const { t } = useTranslation();

    const handleClose = () => {
        onCancel()
    };

  
    const formik = useFormik({
        validateOnMount: false,
        initialValues: {
            dateTime: data?.publishAt ? moment(data?.publishAt).format("YYYY-MM-DDTkk:mm") : moment(new Date()).format("YYYY-MM-DDTkk:mm")
        },
        validationSchema: Yup.object().shape({
            dateTime: Yup.date()
                .required(t('required field'))
        }),
        onSubmit: async (values) => {
            onSelect(values.dateTime)
        }
    })
 


    return (
        
        <Dialog className={classes.dialog}
                onClose={handleClose} aria-labelledby="simple-dialog-title" open={true} maxWidth="md" disableBackdropClick={true}>
            <DialogTitle id="simple-dialog-title">{t('select date')}</DialogTitle>
            <form className={classes.container} onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <FormControl fullWidth>
                        <TextField
                            id="dateTime"
                            type="datetime-local"
                            className={classes.textField}
                            value={formik.values.dateTime}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.dateTime && formik.touched.dateTime}
                            helperText={(formik.errors.dateTime && formik.touched.dateTime) && formik.errors.dateTime}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <SubmitButton
                        isSubmitting={formik.isSubmitting}>
                        {t('ok')}
                    </SubmitButton>
                </DialogActions>
            </form>
        </Dialog>
       
    )
}

ModalDateTime.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(ModalDateTime)
