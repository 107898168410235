import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Context } from './../context';

import Dashboard from '../screens/Dashboard';
import About from '../screens/About';
import Login from '../screens/Auth/Login';
import RecoverPassword from '../screens/Auth/RecoverPassword';

import ChallengesList from '../screens/Challenges/List';
import ChallengesCreate from '../screens/Challenges/Create';
import ChallengesEdit from '../screens/Challenges/Edit';

import StepsList from '../screens/Steps/List';
import StepsCreate from '../screens/Steps/Create';
import StepsEdit from '../screens/Steps/Edit';

import TermsAndConditions from '../screens/TermsAndConditions';
import Help from '../screens/Help';
import UserSettings from '../screens/UserSettings';
import StepCodeList from '../screens/StepCode/List';

import NewsList from '../screens/News/List';
import NewsCreate from '../screens/News/Create';
import NewsEdit from '../screens/News/Edit';

import BlogList from '../screens/Blogs/List';
import BlogCreate from '../screens/Blogs/Create';
import BlogEdit from '../screens/Blogs/Edit';

import ClientList from '../screens/Client/List';
import ClientWeighings from '../screens/Client/Weighings';
import ClientDiary from '../screens/Client/Diary';

import ClientMessage from '../screens/Client/Message';
import ClientInfo from '../screens/Client/Info';
import ReplyMessage from '../screens/Client/ReplyMessage';

import TrainingList from '../screens/Training/List';
import TrainingCreate from '../screens/Training/Create';
import TrainingEdit from '../screens/Training/Edit';

import DownloadList from '../screens/Download/List';
import DownloadCreate from '../screens/Download/Create';
import DownloadEdit from '../screens/Download/Edit';

import ProductList from '../screens/Product/List';
import ProductCreate from '../screens/Product/Create';
import ProductEdit from '../screens/Product/Edit';

import NotificationsManualList from '../screens/Notifications/Manual/List';
import NotificationsManualCreate from '../screens/Notifications/Manual/Create';
import NotificationsManualEdit from '../screens/Notifications/Manual/Edit';


import NotificationsStepList from '../screens/Notifications/Step/List';
import NotificationsStepCreate from '../screens/Notifications/Step/Create';
import NotificationsStepEdit from '../screens/Notifications/Step/Edit';


import NotificationsProgressList from '../screens/Notifications/Progress/List';
import NotificationsProgressCreate from '../screens/Notifications/Progress/Create';
import NotificationsProgressEdit from '../screens/Notifications/Progress/Edit';

import RecipesList from '../screens/Recipes/List';
import RecipesCreate from '../screens/Recipes/Create';
import RecipesEdit from '../screens/Recipes/Edit';


const Routes = () => {

    const { state, dispatch } = useContext(Context);
    const { user, role } = state





    const routesAdmin = (
        <Switch>
            <Route
                path="/dashboard"
                exact
                component={Dashboard}
            />
            <Route
                path="/fitGym/about-us"
                exact
                component={About}
            />
            <Route
                path="/notifications/manual"
                exact
                component={NotificationsManualList}
            />
            <Route
                path="/notifications/manual/create"
                exact
                component={NotificationsManualCreate}
              />
            <Route
                path="/notifications/manual/edit/:id"
                exact
                component={NotificationsManualEdit}
            />
            <Route
                path="/notifications/steps"
                exact
                component={NotificationsStepList}
            />
            <Route
                path="/notifications/steps/create"
                exact
                component={NotificationsStepCreate}
            />
            <Route
                path="/notifications/steps/edit/:id"
                exact
                component={NotificationsStepEdit}
            />
            <Route
                path="/notifications/progress"
                exact
                component={NotificationsProgressList}
            />
            <Route
                path="/notifications/progress/create"
                exact
                component={NotificationsProgressCreate}
            />
            <Route
                path="/notifications/progress/edit/:id"
                exact
                component={NotificationsProgressEdit}
            />
            <Route
                path="/challenges"
                exact
                component={ChallengesList}
            />
            <Route
                path="/challenges/create"
                exact
                component={ChallengesCreate}
            />
            <Route
                path="/challenges/edit/:id"
                exact
                component={ChallengesEdit}
            />
            <Route
                path="/news"
                exact
                component={NewsList}
            />
            <Route
                path="/news/create"
                exact
                component={NewsCreate}
            />
            <Route
                path="/news/edit/:id"
                exact
                component={NewsEdit}
            />
            <Route
                path="/blog"
                exact
                component={BlogList}
            />
            <Route
                path="/blog/create"
                exact
                component={BlogCreate}
            />
            <Route
                path="/blog/edit/:id"
                exact
                component={BlogEdit}
            />
            <Route
                path="/recipes"
                exact
                component={RecipesList}
            />
            <Route
                path="/recipes/create"
                exact
                component={RecipesCreate}
            />
            <Route
                path="/recipes/edit/:id"
                exact
                component={RecipesEdit}
            />
            <Route
                path="/steps"
                exact
                component={StepsList}
            />
            <Route
                path="/steps/create"
                exact
                component={StepsCreate}
            />
            <Route
                path="/steps/edit/:id"
                exact
                component={StepsEdit}
            />
            <Route
                path="/step-codes"
                exact
                component={StepCodeList}
            />
            <Route
                path="/user/settings"
                exact
                component={UserSettings}
            />
            <Route
                path="/settings/terms-and-conditions"
                exact
                component={TermsAndConditions}
            />
            <Route
                path="/settings/help"
                exact
                component={Help}
            />
            <Route
                path="/auth/login"
                exact
                component={Login}
            />
            <Route
                path="/auth/forgot-password"
                exact
                component={RecoverPassword}
            />
            <Route
                path="/client"
                exact
                component={ClientList}
            />
            <Route
                path="/client/:id/weighings"
                exact
                component={ClientWeighings}
            />
            <Route
                path="/client/:id/info"
                exact
                component={ClientInfo}
            />
            <Route 
                path="/client/:id/diary"
                exact
                component={ClientDiary}
            />
            <Route
                path="/client/:id/message"
                exact
                component={ClientMessage}
            />
            <Route
                path="/client/:id/message/reply"
                exact
                component={ReplyMessage}
            />
            <Route
                path="/training"
                exact
                component={TrainingList}
            />
            <Route
                path="/training/create"
                exact
                component={TrainingCreate}
            />
            <Route
                path="/training/edit/:id"
                exact
                component={TrainingEdit}
            />
            <Route
                path="/download"
                exact
                component={DownloadList}
            />
            <Route
                path="/download/create"
                exact
                component={DownloadCreate}
            />
            <Route
                path="/download/edit/:id"
                exact
                component={DownloadEdit}
            />
            <Route
                path="/products"
                exact
                component={ProductList}
            />
            <Route
                path="/products/create"
                exact
                component={ProductCreate}
            />
            <Route
                path="/products/edit/:id"
                exact
                component={ProductEdit}
            />
            <Redirect 
                from="/" 
                to="/dashboard" 
                exact
            />
            <Route>
                <h3>Not found</h3>
            </Route>
            <Redirect
                to="/"
            />
        </Switch>
    )

    const routespecial_admin = (
        <Switch>
            <Route
                path="/dashboard"
                exact
                component={Dashboard}
            />
            <Route
                path="/auth/login"
                exact
                component={Login}
            />
            <Route
                path="/auth/forgot-password"
                exact
                component={RecoverPassword}
            />
            <Route
                path="/client"
                exact
                component={ClientList}
            />
            <Route
                path="/client/:id/weighings"
                exact
                component={ClientWeighings}
            />
            <Route
                path="/client/:id/info"
                exact
                component={ClientInfo}
            />
            <Route
                path="/client/:id/diary"
                exact
                component={ClientDiary}
            />
            <Route
                path="/client/:id/message"
                exact
                component={ClientMessage}
            />
            <Route
                path="/client/:id/message/reply"
                exact
                component={ReplyMessage}
            />
            <Route
                path="/user/settings"
                exact
                component={UserSettings}
            />
            <Redirect
                from="/"
                to="/dashboard"
                exact
            />
            <Route>
                <h3>Not found</h3>
            </Route>
            <Redirect
                to="/"
            />
        </Switch>
    )

    const routes = (role === 'root' || role === 'admin') ? routesAdmin : routespecial_admin;

    return routes

}
export default Routes;