import React, {  useContext } from 'react';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link  } from 'react-router-dom';
import clsx from 'clsx';
import {
  withStyles,
  Hidden,
  Divider,
  Paper,
  Avatar,
  Drawer,
  ListItem,
  Button
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import styles from './styles';
import { navs_admin, navs_moderator } from './navConfig';
import { Context } from './../../../../context';
import Navigation from './../../../../components/Navigation';
import LanguageMenu from '../../../../components/LanguageMenu';
import SettingsUserMenu from '../../../../components/SettingsUserMenu';
import Logo from './../../../../assets/images/logo-fitgym.png'
import { deleteToken, deleteUser } from './../../../../utils/storage';
import * as actionTypes from './../../../../actions';


const API_URL = process.env.REACT_APP_DOMAIN;


const SideBar = ({
  classes,
  openDrawer,
  closeDrawer
}) => {


  const { t } = useTranslation();
  const history = useHistory();
  const { state, dispatch } = useContext(Context);
  const { user, role } = state
  
  const navConfig = role === 'moderator' ? navs_moderator : navs_admin;


  const onCloseSidebarMobile = () => {
    closeDrawer()
  }

  const onClickLogoutHandle = async () => {
    await deleteToken()
    await deleteUser()
    dispatch({ type: actionTypes.SESSION_LOGOUT })
    history.push('/auth/login')
  }

  const sideBarContent = (
    <div className={classes.sideBarContent}>
        <div className={classes.boxLogo}>
        <img src={Logo} alt="" />
        </div>
        <div className={classes.profileBox}>
          <div className={classes.menuUserBox}>
            <Avatar
              alt="Person"
              className={classes.avatar}
              component={Link}
              src={(user && user.image) ? `${API_URL}/warehouse/${user.image.filename}` : ''}
              to=""
            />
            <SettingsUserMenu />
          </div>
          <div className={classes.boxLanguage}>
            <LanguageMenu />
          </div>
        </div>
        <Divider className={classes.divider} />
        <nav className={classes.navigation}>
        {navConfig.map((list, index) => (
            <Navigation key={`navigation-${index}`} list={list} />
          ))}
        <ListItem
          onClick={onClickLogoutHandle}
          className={classes.item}
          disableGutters >
          <Button >
            <ExitToAppIcon className={classes.icon} />
            {t('logout')}
          </Button>
        </ListItem>
      </nav>
      <div className={classes.overlay}></div>
    </div>
  )

  return (
    <>
      <Hidden mdDown>
        <Paper
          className={clsx(classes.root)}
          elevation={1}
          square
        >
          {sideBarContent}
        </Paper>
      </Hidden>

      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={openDrawer}
          onClose={onCloseSidebarMobile}
          variant="temporary">
          {sideBarContent}
        </Drawer>
      </Hidden>
    </>
  )
}

SideBar.propTypes = {
  classes: PropTypes.object,
  openDrawer: PropTypes.bool
}

export default compose (
  withStyles(styles)
)(SideBar)

