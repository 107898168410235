import axios from 'axios';
import { getToken } from './../storage';

const API_URL = process.env.REACT_APP_DOMAIN;



export const axiosInstance = axios.create({
    baseURL: API_URL
});


/* Define time out response  */
axiosInstance.defaults.timeout = 1000000;

/* Request interceptor */
axiosInstance.interceptors.request.use(async request => {
    const token = await getToken();
    if (token) {
        request.headers['Authorization'] = `Bearer ${token}`
    }
    request.url = `${request.baseURL}/${request.url}`
    return request;
},
    (error) => {
        return Promise.reject(error);
    });


/* Response interceptor */
axiosInstance.interceptors.response.use((response) => {
    return response;
},
    (error) => {
        const originalRequest = error.config;
        return Promise.reject(error);
    });


export default axiosInstance;
