import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/pl';
import 'moment/locale/es';
import { useConfirm } from 'material-ui-confirm';
import { 
    withStyles,
    Grid,
    Fab,
    Box,
    Switch
} from '@material-ui/core';
import BookIcon from '@material-ui/icons/Book';
import AddIcon from '@material-ui/icons/Add';
import Table from './../../../components/Table'
import EditIcon from '@material-ui/icons/Edit';
import ModalDateTime from './../../../components/ModalDateTime';
import LoadingScreen from './../../../components/LoadingScreen'
import * as apiService from './../../../utils/api';

const List = ({
    classes
}) => {

    const { t, i18n } = useTranslation();
    moment.locale(i18n.language);
    const history = useHistory();
    const confirm = useConfirm();
    const [data, setData] = useState([])
    const [news, setNews] = useState([])
    const [openModalDateTime, setOpenModalDateTime] = useState(false)
    const [loading, setLoading] = useState(true)
    const [newsData, setNewsData] = useState(null)



    const actions = [
        {
            icon: 'scheduleIcon',
            tooltip: t('schedule'),
            onClick: (event, rowData) => { handleSchedulePublishClick(rowData) }
        },
        {
            icon: 'delete',
            tooltip: t('delete'),
            onClick: (event, rowData) => { handleDeleteClick(rowData) }
        },
        {
            icon: () => <EditIcon />,
            tooltip: t('edit'),
            onClick: (event, rowData) => {
                history.push(`/news/edit/${rowData.id}`)
            }
        }
    ];

    let columns = [
        { title: t('id'), field: 'id', hidden: true },
        { title: t('title'), field: 'title' },
        { title: t('create date'), field: 'createDate' },
        { title: t('type'), field: 'type' },
        { title: t('publish'), field: 'publish' },
        { title: t('highlight'), field: 'highlight' },
        { title: t('publishDate'), field: 'publishDate' }
    ]

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                setLoading(true)
                const resp = await apiService.getBlogs('news');
                const newsSorted = sortFolderByDate(resp.data.content)
                setNews(newsSorted)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchBlog()
        return () => {
            //cleanup
        }
    }, [])

    const sortFolderByDate = (list) => {
        const listSorted = list.sort((a, b) => {
            return (new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        })
        return listSorted
    }


    useEffect(() => {
        const setupData = () => {
            const setDataTable = () => {
                return news.map(itemNews => {
                        return {
                            id: itemNews._id,
                            title: itemNews[`title_${i18n.language}`],
                            createDate: itemNews.hasOwnProperty('createdAt') ? `${moment(itemNews.createdAt).format('ll')}` : null,
                            type: itemNews.type,
                            publish: (
                                <Switch
                                    onChange={() => handlePublishClick(itemNews)}
                                    size="small"
                                    checked={(itemNews.published && new Date(itemNews.published).getTime() < Date.now())}
                                    color="primary"
                                />
                            ),
                            highlight: (
                                <Switch
                                    onChange={() => handleHighlightClick(itemNews)}
                                    size="small"
                                    checked={itemNews.featured}
                                    color="primary"
                                />
                            ),
                            publishAt: itemNews.publishAt,
                            publishDate: (itemNews.hasOwnProperty('publishAt') && itemNews?.publishAt && itemNews.published && new Date(itemNews.published).getTime() < Date.now()) ? `${moment(itemNews.publishAt).format('ll')}` : '---',
                        }
                    })
            }
            setData(setDataTable())   
        }
 
        setupData()
        return () => {
            //cleanup
        }
    }, [news, i18n.language])

  


    const handleHighlightClick = (data) => {
        const info = data.published ? 'are you sure to unhighlight this item' : 'are you sure to highlight this item'
        confirm({ description: t(info) })
            .then(async () => {
                try {
                    setLoading(true)
                    await apiService.featureBlog(data._id);
                    const resp = await apiService.getBlogs('news');
                    setNews(resp.data.content)
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                }
            })
            .catch(() => { });
    }

    const handlePublishClick = (data) => {
        const info = data.published ? 'are you sure to unpublish this item' : 'are you sure to publish this item'
        confirm({ title: t('are you sure'), description: t(info) })
            .then(async () => {
                try {
                    setLoading(true)
                    const postData = {
                        published: !data.published,
                        publishAt: new Date()
                    }
                    await apiService.publishBlog(data._id, postData);
                    const resp = await apiService.getBlogs('news');
                    setNews(resp.data.content)
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                }
            })
            .catch(() => { });
    }

    const handleDeleteClick = (data) => {
        confirm({ title: t('are you sure'), description: t('are you sure to delete this item') })
            .then(async () => {
                try {
                    setLoading(true)
                    await apiService.deleteBlog(data.id);
                    const resp = await apiService.getBlogs('news');
                    setNews(resp.data.content)
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                }
            })
            .catch(() => { });
    }

    const handleSchedulePublishClick = (data) => {
        setNewsData(data)
        setOpenModalDateTime(true)
    }

    const hanldeSchedulePublishConfirm = async (date) => {
        setOpenModalDateTime(false)
        try {
            setLoading(true)
            const postData = {
                published: !newsData.isPublished,
                publishAt: new Date(date)
            }
            await apiService.publishBlog(newsData.id, postData);
            const resp = await apiService.getTrainings();
            setNewsData(resp.data.content)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.iconBox}>
                    <BookIcon />
                </div>
                <Grid container justify="space-between" spacing={3}>
                    <Grid item className={classes.gridHeader} >
                        <h3>{t('news')}</h3>
                    </Grid>
                    <Grid item >
                        <Fab onClick={() => history.push('/news/create')}>
                            <AddIcon />
                        </Fab>
                    </Grid>
                </Grid>
                <Box className={classes.boxRoot}>
                    <Table
                        title={null}
                        columns={columns}
                        actions={actions}
                        data={data}
                    />
                </Box>
            </div>
            {loading ? <LoadingScreen /> : null}
            {openModalDateTime ? <ModalDateTime
                data={newsData}
                onCancel={() => setOpenModalDateTime(false)}
                onSelect={hanldeSchedulePublishConfirm}/> : null}
        </>
        
    )
}

List.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(List)



