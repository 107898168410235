export default {
    root: {
        color: 'white',
        backgroundColor: '#f44336',
        borderColor: '#f44336',
        boxShadow: '0 2px 2px 0 rgba(244,67,54,.14), 0 3px 1px -2px rgba(244,67,54,.2), 0 1px 5px 0 rgba(244,67,54,.12)',
        '&:hover': {
            color: 'white',
            backgroundColor: '#f44336',
            borderColor: '#f44336',
            boxShadow: 'box-shadow: 0 14px 26px -12px rgba(244,67,54,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(244,67,54,.2)'
        }
    }
};
