const classes =  theme => ({
    root: {},
    paperSimpleMessage: {
        margin: '20px 20px',
        padding: '30px'
    },
    noDisplay: {
        display: 'none'
    }
})
export default classes;