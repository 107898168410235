import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import {
    withStyles,
    Tabs,
    Tab
} from '@material-ui/core';
import flagPolandIcon from '../../assets/icons/poland.png';
import flagPortugalIcon from '../../assets/icons/portugal.png';
import flagSpainIcon from '../../assets/icons/spain.png';
import flagUnitedKingdomIcon from '../../assets/icons/united-kingdom.png';


const languages = [
    { id: 'pt', name: 'Português', icon: <img src={flagPortugalIcon} alt="" /> },
    { id: 'en', name: 'English', icon: <img src={flagUnitedKingdomIcon} alt="" /> },
    { id: 'es', name: 'Espanhol', icon: <img src={flagSpainIcon} alt="" /> },
    { id: 'pl', name: 'Polska', icon: <img src={flagPolandIcon} alt="" /> }
]

const LanguageTabs = ({
    classes,
    onChangeLanguage
}) => {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        onChangeLanguage(languages[newValue])
        setValue(newValue);
    };



    return (
        <Tabs
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
        >

            {languages.map( (lng, index) => (
                <Tab className={classes.tab}
                    key={lng.id}
                    label={lng.name}
                    icon={lng.icon}
                    {...a11yProps(index)} />
            ))}
        </Tabs>
    )
}

const a11yProps = (index) => {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

LanguageTabs.propTypes = {
    classes: PropTypes.object,
    onChangeLanguage: PropTypes.func
}

export default compose(
    withStyles(styles)
)(LanguageTabs)
