
const classes =  theme => ({
    root: {
        color: 'white'
    },
    menuList: {
        textAlign: 'center',
        width: '200px',
        display: 'flex',
        padding: '10px 30px', 
        cursor: 'pointer',
        justifyContent: 'center',
        '&:hover': {
            color: 'white',
            backgroundColor: '#9c27b0',
        }
    },
    buttonMenu: {
        color: 'white'
    }
})
export default classes;