
export default theme => ({
    root: {
        padding: '20px 20px',
        position: 'relative',
        backgroundColor: 'white',
        margin: '70px 50px 20px',
        border: '1px solid #eee',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0, 0, 0, .12)',
        borderRadius: '6px'
    },
    iconBox: {
        background: 'linear-gradient(60deg,#ffa726,#fb8c00)',
        boxShadow: '0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(255,152,0,.4)',
        borderRadius: '5px',
        marginTop: '-65px',
        padding: '15px',
        display: 'flex',
        zIndex: 1,
        position: 'absolute',
        fontSize: '3.5rem',
        minWidth: '80px',
        minHeight: '80px',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        '& svg': {
            fontSize: '3.5rem'
        }
    },
    headerBox: {
        paddingLeft: '130px !important',
        paddingBottom: '30px',
        '& h1': {
            fontWeight: 300,
            lineHeight: '1.4em',
            marginTop: '-10px'
        }
    },
    contentBox: {
        marginTop: '20px'
    },
    uploadContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    boxPhoto: {
        height: '122px',
        lineHeight: '122px',
        width: '122px',
        marginBottom: '0px'
    },
    labelUploadFoto: {
         margin: '10px 0 20px',
         cursor: 'pointer'
    },
    photoContainerRounded: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        borderRadius: '50%',
        borderColor: 'red',
        boxShadow: '0 0.46875rem 2.1875rem rgba(59,62,102,.03), 0 0.9375rem 1.40625rem rgba(59,62,102,.03), 0 0.25rem 0.53125rem rgba(59,62,102,.05), 0 0.125rem 0.1875rem rgba(59,62,102,.03)',
        '& img': {
            maxWidth: '100%',
            height: '100%',
            width: '100%',
            objectFit: 'cover'
        }
    }
})