import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
    black,
    white,
    primary: {
        main: '#E35205',
        light: ' #CEB888',     
        dark: '#94A596',
        contrastText: '#fff',
    },
    secondary: {
        main: '#3f50b5',
        light: colors.red[500],
        dark: '#002884',
        contrastText: '#fff',
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[700],
        light: colors.red[400]
    },
}

