
export default {
        paper: {
            borderRadius: '6px'
    },
    MuiDialogTitle: {
        root: {

        }
    },
    MuiDialogContent: {
        root: {
            paddingTop: '24px'
        }
    }
};

