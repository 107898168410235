import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import MaterialTable from 'material-table';

const Table = ({
    classes,
    title,
    columns,
    data,
    actions
    
}) => {

    const { t } = useTranslation();



    return (
        <div>
            <MaterialTable
                title={title}
                columns={columns}
                data={data}
                actions={actions}
                options={{
                    selection: false,
                    actionsColumnIndex: -1,
                    exportButton: false,
                    pageSizeOptions: [10, 20, 30],
                    pageSize: 10,
                    draggable: false
                }}
                localization={{
                    header: {
                        actions: t('actions')
                    },
                    toolbar: {
                        searchPlaceholder: t('search')
                    },
                    pagination: {
                        labelRowsSelect: t('rows'),
                        labelDisplayedRows: `{from}-{to} ${t('of')} {count}`,
                    },
                    body: {
                        emptyDataSourceMessage: t('no records to dysplay')
                    }
                }}
                components={{
                    Container: props => ( <div {...props}></div>)
                }}
            />
        </div>
    )
}

Table.propTypes = {
    classes: PropTypes.object,
    columns: PropTypes.array,
    data: PropTypes.array,
    actions: PropTypes.array,
    title: PropTypes.string
}

export default compose(
    withStyles(styles)
)(Table)


