import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { 
    withStyles,
    Button,
    Fab
} from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MessageIcon from '@material-ui/icons/Email';
import SendMessageicon from '@material-ui/icons/QuestionAnswer'
import LoadingScreen from './../../../components/LoadingScreen';
import RecMessages from './components/RecMessages';
import SentMessages from './components/SentMessages';
import * as apiService from './../../../utils/api';


const Message = ({
    classes,
    match
}) => {

    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)
    const [messageFromClient, setMessageFromClient] = useState([])
    const [newsMsgFromClient, setNewsMsgFromClient] = useState(0)

    const [messageToClient, setMessageToClient] = useState([])
    const [showReceivedMessages, setShowReceivedMessages] = useState(true)



    useEffect(() => {
        const fetchUserMessages = async () => {
            const userId = match.params.id
            return apiService.getUserMessages(userId);
        }
        const fetchUser = async () => {
            const id = match.params.id
            return apiService.getUser(id)
        }
        const fetchData = async () => {
            try {
                setLoading(true)
                const [res1, res2] = await Promise.all([fetchUserMessages(), fetchUser()]);
                const msgs = res1.data.content
                const usr = res2.data.content
                const msgFromClient = msgs.filter(msg => msg.sender._id === match.params.id)
                const msgToClient = msgs.filter(msg => msg.sender._id !== match.params.id)
                setMessageFromClient(msgFromClient)
                setMessageToClient(msgToClient)
                const count = msgFromClient.filter(it => (!it.read && it.receiver.email === 'admin')).length

                setNewsMsgFromClient(count)
                setUser(usr)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchData()
        return () => {
            //
        }
    }, [match.params.id])


    const refreshMsg = async () => {
        try {
            const userId = match.params.id
            const resp = await  apiService.getUserMessages(userId);
            const msgs = resp.data.content
            const msgFromClient = msgs.filter(msg => msg.sender._id === match.params.id)
            const msgToClient = msgs.filter(msg => msg.sender._id !== match.params.id)
            setMessageFromClient(msgFromClient)
            setMessageToClient(msgToClient)
            const count = msgFromClient.filter(it => (!it.read && it.receiver.email === 'admin')).length
            setNewsMsgFromClient(count)
        } catch (error) {
            return null
        }
    }


    if (loading) {
        return <LoadingScreen />
    }

    const renderReceivedMessages = () => {
        return (
            <div>
                {messageFromClient.map(msg => 
                    <RecMessages user={user} msg={msg} key={msg._id} onRefreh={refreshMsg}/>
                )}
            </div>
        )
    }

    const renderSentMessages = () => {
        return (
            <div>
                {messageToClient.map(msg =>
                    <SentMessages user={user} msg={msg} key={msg._id} />
                )}
            </div>
        )
    }




    return (
        <div className={classes.root}>
            <div className={classes.iconBox}>
                <MailIcon />
            </div>
            <div className={classes.headerBox}>
                <h3>
                    <span>{user && (user.firstName && user.lastName) ? `${user.firstName} ${user.lastName}` : null}</span>
                </h3>
                <Fab onClick={() => history.push('/client')}>
                    <ArrowBackIcon />
                </Fab>
            </div>
            <div className={classes.contentBox}>
                <div className={classes.card}>
                    <div className={classes.cardHeaderBox}>
                        <h4>{t('messages')}:</h4>
                        <Button
                            variant="contained"
                            disableElevation={true}
                            className={clsx({ [classes.buttonCard]: true }, { [classes.activeButton]: showReceivedMessages })}
                            startIcon={<MessageIcon />}
                            onClick={() => setShowReceivedMessages(true)}
                        >
                            {newsMsgFromClient} {t('new messages')}
                        </Button>
                        <Button
                            variant="contained"
                            disableElevation={true}
                            className={clsx({ [classes.buttonCard]: true }, { [classes.activeButton]: !showReceivedMessages })}
                            startIcon={<SendMessageicon />}
                            onClick={() => setShowReceivedMessages(false)}
                        >
                            {t('sent messages')}
                        </Button>
                    </div>
                    {showReceivedMessages ? renderReceivedMessages() : renderSentMessages()}
                </div>
            </div>
        </div>
    )
}

Message.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Message)
