import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Formik} from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import {
    withStyles,
    Container,
    FormControl,
    Grid,
    TextField,
    FormHelperText,
    Button,
    Box
} from '@material-ui/core';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import { Context } from './../../context';
import flagPolandIcon from '../../assets/icons/poland.png';
import flagPortugalIcon from '../../assets/icons/portugal.png';
import flagSpainIcon from '../../assets/icons/spain.png';
import flagUnitedKingdomIcon from '../../assets/icons/united-kingdom.png';
import SubmitButton from '../../components/SubmitButton';
import ModalAvatars from './components/ModalAvatars';
import LoadingScreen from './../../components/LoadingScreen';
import {  toBase64 } from './../../utils/util'
import * as apiService from './../../utils/api'
import * as storageService from './../../utils/storage'
import * as actionTypes from './../../actions';
import { getFileDto } from './../../utils/util';

const languages = [
    { id: 'en', name: 'English', icon: <img src={flagUnitedKingdomIcon} alt="" /> },
    { id: 'pt', name: 'Português', icon: <img src={flagPortugalIcon} alt="" /> },
    { id: 'es', name: 'Espanhol', icon: <img src={flagSpainIcon} alt="" /> },
    { id: 'pl', name: 'Polska', icon: <img src={flagPolandIcon} alt="" /> }
]



const defaultInitialValues = {
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirm: '',
    language: ''
}

const API_URL = process.env.REACT_APP_DOMAIN;


const UserSettings = ({
    classes
}) => {

    const { state, dispatch } = useContext(Context);

    const { t } = useTranslation();
    const [showAvatars, setShowAvatars] = useState(false)
    const [foto, setFoto] = useState({})
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const [newPhoto, setNewPhoto] = useState(false)


    const setAvatarClickHandler = () => {
        setShowAvatars(true)
    }

    const handleChangeUploadFoto = async event => {
        if (event.target.files.length) {
            const file = event.target.files[0]
            const preview = URL.createObjectURL(file)
            const resp = await toBase64(file)
            setFoto({
                file: resp,
                type: file.type,
                filename: file.name,
                preview: preview
            })
            setNewPhoto(true)
        }
    }

    const onSelectAvatarHander = async (item) => {
        setFoto({
            type: 'image/png',
            filename: item.name,
            preview: item.base64,
            file: item.base64
        })
        setShowAvatars(false)
        setNewPhoto(true)
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let user = await storageService.getUser()
                if (user) {
                    const resp = await apiService.getUser(user._id)
                    user = resp.data.content
                    setUser(user)
                }
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchData()
        return () => {
            //
        }
    }, [])

    useEffect(() => {
        if (user && user.image) {
            setFoto({
                file: user.image.file,
                type: user.image.type,
                filename: user.image.filename,
                preview: `${API_URL}/warehouse/${user.image.filename}`
            })
        }
        return () => {
            //
        }
    }, [user])


    if (loading) {
        return <LoadingScreen />
    }

    return (
        <>
         <Formik
            enableReinitialize={true}
            initialValues={ user===null ? { ...defaultInitialValues } : { ...user } }
            validationSchema={
                Yup.object().shape({
                    firstName: Yup.string()
                        .required(t('required field')),
                    lastName: Yup.string()
                        .required(t('required field')),
                    password: Yup.string(""),
                    confirmPassword: Yup.string("enter your password")
                        .oneOf([Yup.ref("password")], t('password does not match'))
                })
            }
                onSubmit={async (values, { resetForm }) => {
                    const base64 = await getFileDto(foto)
                    let dataPost = {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        language: values.language,
                    
                        }
                    if (base64 && newPhoto) {
                            dataPost['image'] = base64
                        }
                try {
                    const resp = await apiService.updateUser(user._id, dataPost)
                    const userUpdated = resp.data.content
                    await storageService.setUser(userUpdated)
                    setUser(userUpdated)
                    setNewPhoto(false)
                    dispatch({ type: actionTypes.SET_USER, payload: userUpdated })
                    dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })
                } catch (error) {
                    dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('updated error') } })
                }
            }}
        >
            {props => {
                    return (
                        <form onSubmit={props.handleSubmit}>
                            <div className={classes.root}>
                                <div className={classes.iconBox}>
                                    <SettingsInputComponentIcon />
                                </div>
                                <div className={classes.headerBox}>
                                    <h3>{t('user settings')}</h3>
                                </div>
                                <div className={classes.contentBox}>
                                    <Container maxWidth="md">
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <div className={classes.uploadContainer}>
                                                    <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '30px' }}>
                                                        <div className={classes.boxPhoto} >
                                                            <div className={classes.photoContainerRounded}>
                                                                <img src={foto.preview} alt="" id="image-avatar" />
                                                            </div>
                                                        </div>
                                                        <label className={classes.labelUploadFoto} htmlFor="upload-button">{t('load photo')}</label>
                                                        <input
                                                            type="file"
                                                            id="upload-button"
                                                            style={{ display: "none" }}
                                                            onChange={handleChangeUploadFoto}
                                                        />
                                                    </Box>
                                                    <div style={{ marginBottom: '50px', marginLeft: '20px' }}>
                                                        <Button onClick={setAvatarClickHandler}>{t('load avatar info')}</Button>
                                                    </div>

                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="firstName"
                                                                placeholder=""
                                                                autoComplete="off"
                                                                margin="dense"
                                                                variant="outlined"
                                                                label={`${t('firstName')} *`}
                                                                value={props.values.firstName}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                error={props.errors.firstName && props.touched.firstName}
                                                                helperText={(props.errors.firstName && props.touched.firstName) && props.errors.firstName}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="lastName"
                                                                placeholder=""
                                                                autoComplete="off"
                                                                margin="dense"
                                                                variant="outlined"
                                                                label={`${t('lastName')} *`}
                                                                value={props.values.lastName}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                error={props.errors.lastName && props.touched.lastName}
                                                                helperText={(props.errors.lastName && props.touched.lastName) && props.errors.lastName}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="password"
                                                                type="password"
                                                                placeholder={`${t('password')} *`}
                                                                variant="outlined"
                                                                margin="dense"
                                                                fullWidth
                                                                autoComplete="off"
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                error={props.errors.password && props.touched.password}
                                                                helperText={(props.errors.password && props.touched.password) && props.errors.password}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="confirmPassword"
                                                                type="password"
                                                                placeholder={`${t('confirm password')} *`}
                                                                variant="outlined"
                                                                margin="dense"
                                                                fullWidth
                                                                autoComplete="off"
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                error={props.errors.confirmPassword && props.touched.confirmPassword}
                                                                helperText={(props.errors.confirmPassword && props.touched.confirmPassword) && props.errors.confirmPassword}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={4} >
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                disabled
                                                                id="language"
                                                                select
                                                                margin="normal"
                                                                SelectProps={{
                                                                    native: true,
                                                                }}
                                                                variant="outlined"
                                                                value={props.values.language}
                                                                onChange={props.handleChange('language')}
                                                            >
                                                                <option key={-1} value={-1}>
                                                                    {t('select language')}
                                                                </option>
                                                                {languages.map((language) => (
                                                                    <option key={language.id} value={language.id}>
                                                                        {language.name}
                                                                    </option>
                                                                ))}
                                                            </TextField>
                                                            {(props.errors.language && props.touched.language) && props.errors.language ? (
                                                                <FormHelperText error>{props.errors.language}</FormHelperText>) : null
                                                            }
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <SubmitButton
                                                            isSubmitting={props.isSubmitting}>
                                                            {t('save')}
                                                        </SubmitButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </div>
                            </div>
                        </form>
                )
            }}
            </Formik>
            {showAvatars ? <ModalAvatars onClose={() => setShowAvatars(false)} onSelect={onSelectAvatarHander}/> : null}
            </>
        
    )
}

UserSettings.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(UserSettings)
