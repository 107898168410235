import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { useFormik } from 'formik';
import moment from 'moment'
import styles from './styles';
import {
    withStyles,
    Grid,
    FormControl,
    TextField
} from '@material-ui/core';
import { Context } from '../../../../../../../../context';



const defaultInitialValues = {
    weight: '',
    muscleMass: '',
    bodyFat: '',
    bmi: '',
    chest: '',
    abdominal: '',
    hip: '',
    arm: '',
    thigh: '',
    client: '',
    createdAt: '',
    updatedAt: ''
}



const MeasurementInfo = ({
    classes,
    measurementInfo
}) => {


    const { t } = useTranslation();
    const { state, dispatch } = useContext(Context);


    const formik = useFormik({
        validateOnMount: false,
        initialValues: measurementInfo ? measurementInfo  : defaultInitialValues
    })

    return (
        <div className={classes.root}>
            <form  onSubmit={formik.handleSubmit}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id="createdAt"
                                label={t('date')}
                                defaultValue={t('date')}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                value={moment(formik.values.createdAt).format('lll')}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                id="weight"
                                label={t('weight')}
                                defaultValue={t('weight')}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                value={formik.values.weight}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                id="muscleMass"
                                label={t('muscle mass')}
                                defaultValue={t('muscle mass')}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                value={formik.values.muscleMass}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                id="bodyFat"
                                label={t('body fat')}
                                defaultValue={t('body fat')}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                value={formik.values.bodyFat}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                id="bmi"
                                label={t('bmi')}
                                defaultValue={t('bmi')}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                value={formik.values.bmi}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                id="chest"
                                label={t('chest')}
                                defaultValue={t('chest')}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                value={formik.values.chest}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                id="abdominal"
                                label={t('abdominal')}
                                defaultValue={t('abdominal')}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                value={formik.values.abdominal}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                id="hip"
                                label={t('hip')}
                                defaultValue={t('hip')}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                value={formik.values.hip}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                id="arm"
                                label={t('arm')}
                                defaultValue={t('arm')}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                value={formik.values.arm}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                id="thigh"
                                label={t('thigh')}
                                defaultValue={t('thigh')}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                value={formik.values.thigh}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        </div>
        
    )
}

MeasurementInfo.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(MeasurementInfo)
