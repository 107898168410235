import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import {
    withStyles,
    Container,
    FormGroup,
    FormControlLabel,
    Switch,
    Fab
} from '@material-ui/core';
import BookIcon from '@material-ui/icons/Book';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LanguageTabs from './../../../components/LanguageTabs';
import Form from './../Form'

const Create = ({
    classes
}) => {

    const { t, i18n } = useTranslation();
    const [languageTab, setLanguageTab] = useState('pt')
    const [directLink, setDirectLink] = useState(false)
    const history = useHistory();


    const onChangeLanguageHandler = (lng) => {
        setLanguageTab(lng.id)
    }

    const handleChange = (event) => {
        setDirectLink(event.target.checked)
    };




    return (

        <div className={classes.root}>
            <div className={classes.iconBox}>
                <BookIcon />
            </div>
            <div className={classes.headerBox}>
                <h3>{t('news')}</h3>
                <Fab onClick={() => history.push('/news')}>
                    <ArrowBackIcon />
                </Fab>
            </div>
            <div className={classes.contentBox}>
                <Container maxWidth="xl">
                    <FormGroup row style={{ 'justifyContent': 'flex-end' }}>
                        <FormControlLabel
                            control={<Switch checked={directLink} onChange={handleChange} color="primary" />}
                            label={t('direct link')}
                        />
                    </FormGroup>
                    <LanguageTabs onChangeLanguage={onChangeLanguageHandler} />
                    <Form lng={languageTab} directLink={directLink} edit={false} news={{}} />
                </Container>
            </div>
        </div>
    )
}

Create.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Create)
