import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { 
    withStyles,
    Grid,
    Box,
    Switch,
    Fab
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import Table from './../../../components/Table'
import EditIcon from '@material-ui/icons/Edit';
import ModalDateTime from './../../../components/ModalDateTime';
import LoadingScreen from './../../../components/LoadingScreen'
import * as apiService from './../../../utils/api'

const List = ({
    classes
}) => {

    const { t, i18n } = useTranslation();
    const history = useHistory();
    const confirm = useConfirm();
    const [data, setData] = useState([])
    const [steps, setSteps] = useState([])
    const [openModalDateTime, setOpenModalDateTime] = useState(false)
    const [loading, setLoading] = useState(true)


    const actions = [
        {
            icon: 'delete',
            tooltip: t('delete'),
            onClick: (event, rowData) => { handleDeleteClick(rowData) }
        },
        {
            icon: () => <EditIcon />,
            tooltip: t('detail'),
            onClick: (event, rowData) => {
                history.push(`/steps/edit/${rowData.id}`)
            }
        }
    ];

    let columns = [
        { title: t('id'), field: 'id', hidden: true },
        { title: t('number'), field: 'number' },
        { title: t('title'), field: 'title' },
        { title: t('publish'), field: 'publish' }
    ]


    useEffect(() => {
        const fetchSteps = async () => {
            try {
                setLoading(true)
                const resp = await apiService.getSteps();
                setSteps(resp.data.content)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchSteps()
        return () => {
            //cleanup
        }
    }, [])

    useEffect(() => {
        const setupData = () => {
            const setDataTable = () => {
                return steps.map(step => {
                    return {
                        id: step._id,
                        number: step[`number_${i18n.language}`],
                        title: step[`title_${i18n.language}`],
                        publish: (
                            <Switch
                                onChange={() => handlePublishClick(step)}
                                size="small"
                                checked={step.published}
                                color="primary"
                            />
                        )
                    }
                })
            }
            setData(setDataTable())
        }
        setupData()
        return () => {
            //
        }
    }, [i18n.language, steps])

  

    const handlePublishClick = (data) => {
        const info = data.published ? 'are you sure to unpublish this item' : 'are you sure to publish this item'
        confirm({ title: t('are you sure'), description: t(info) })
            .then(async () => {
                try {
                    setLoading(true)
                    await apiService.publishStep(data._id);
                    const resp = await apiService.getSteps();
                    setSteps(resp.data.content)
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                }
            })
            .catch(() => { });
    }

    const handleDeleteClick = (data) => {
        confirm({ title: t( 'are you sure' ), description: t( 'are you sure to delete this item') })
            .then(async () => {
                try {
                    setLoading(true)
                    await apiService.deleteStep(data.id);
                    const resp = await apiService.getSteps();
                    setSteps(resp.data.content)
                    setLoading(false)
                } catch (error) {

                }
            })
            .catch(() => { });
    }

    const handleSchedulePublishClick = (data) => {
        setOpenModalDateTime(true)
    }

    const hanldeSchedulePublishConfirm = (date) => {
        setOpenModalDateTime(false)
    }




    return (
        <>
            <div className={classes.root}>
                <div className={classes.iconBox}>
                    <AllInclusiveIcon />
                </div>
                <Grid container justify="space-between" spacing={3} >
                    <Grid item className={classes.gridHeader}>
                        <h3>{t('steps')}</h3>
                    </Grid>
                    <Grid item >
                        <Fab onClick={() => history.push('/steps/create')}>
                            <AddIcon />
                        </Fab>
                    </Grid>
                </Grid>
                <Box className={classes.boxRoot}>
                    <Table
                        title={null}
                        columns={columns}
                        actions={actions}
                        data={data}
                    />
                </Box>
                {loading ? <LoadingScreen /> : null}
            </div>
            {openModalDateTime ? <ModalDateTime
                onCancel={() => setOpenModalDateTime(false)}
                onSelect={hanldeSchedulePublishConfirm}/> : null}
        </>
        
    )
}

List.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(List)



