import React from 'react';
import {
    Page,
    Text,
    View,
    Image,
    Document,
    StyleSheet,
    Font
} from '@react-pdf/renderer';

import OpenSansBold from './../../assets/fonts/OpenSans/OpenSans-Bold.ttf';
import OpenSansSemiBold from './../../assets/fonts/OpenSans/OpenSans-SemiBold.ttf';
import FuturaPtBold from './../../assets/fonts/OpenSans/Poppins-Bold.ttf';

import BackgroundImage from './../../assets/images/code-background.png';

// Register font
Font.register({ family: 'OpenSansBold', src: OpenSansBold });
Font.register({ family: 'OpenSansSemiBold', src: OpenSansSemiBold });
Font.register({ family: 'FuturaPtBold', src: FuturaPtBold });


// Create styles
const styles = StyleSheet.create({
    body: {
        paddingTop: 40,
        paddingBottom: 20,
        paddingHorizontal: 40,
        display: "flex",
        flexDirection: 'row',
        backgroundColor: 'white'
    },
    image: {
        marginVertical: 1,
        marginHorizontal: 1,
        width: '91mm',
        height: '54mm'
    },
      viewRow: {
        display: "flex",
        flexDirection: 'row',
        flexGrow: 1
    },
    imageContainer: {
        position: 'relative'
    },
    infoContainer: {
        position: 'absolute',
        textAlign: 'center',
        top: 60,
        left: 30
    },
    stepName: {
        fontFamily: 'FuturaPtBold',
        color: '#fff',
        paddingBottom: 8,
        fontSize: 13,
        marginRight: 'auto',
        marginLeft: 'auto',
        textTransform: 'uppercase'
    },
    codeLabel: {
        fontFamily: 'OpenSansBold',
        color: '#828282',
        fontWeight: 'bold',
        paddingBottom: 2
    },
    code: {
        fontFamily: 'FuturaPtBold',
        color: '#000',
        paddingBottom: 10,
        fontSize: 16,
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    nameContainer: {
        position: 'absolute',
        bottom: 3,
        justifyContent: 'center',
        width: '91mm'
    },
    codigoContainer: {
        position: 'absolute',
        bottom: 49,
        justifyContent: 'center',
        width: '91mm'
    }
});


// Create Document Component
const PdfStepCode = ({
    step,
    data
}) => {




   
    const renderCodes = () => {
        if (!data) {
            return null
        }
        const half = Math.ceil(data.length / 2);
        const firstHalf = data.splice(0, half)
        const secondHalf = data.splice(-half)
        return (
            firstHalf.map((it, index) => (
                    <View style={styles.viewRow} key={`row-${index}`}>
                    {renderContent(firstHalf[index])}
                    {renderContent(secondHalf[index])}
                    </View>
                ))
        )
    }

    const renderContent = (data) => {
        return (
            <View style={styles.imageContainer} >
                <Image style={styles.image} src={BackgroundImage} />
                <View style={styles.nameContainer}>
                    <Text style={styles.stepName}>{step.title_pt}</Text>
                </View>
                <View style={styles.codigoContainer}>
                    <Text style={styles.code}>{data.code}</Text>
                </View>
            </View>
        )
    }
        
    return (
        <Document>
            <Page size="A4" style={styles.body} wrap>
                <View>
                    {renderCodes()}
                </View>
            </Page>
        </Document>
    );
}
export default PdfStepCode


