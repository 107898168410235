
export default theme => ({
    root: {},
    dialog: {
        '& .MuiDialogActions-root': {
            padding: '30px'
        }
    },
    listAvatar: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center', 
        marginBottom: '30px'
    },
    boxAvatar: {
        cursor: 'pointer',
        margin: '20px',
        height: '122px',
        width: '122px',
        overflow: 'hidden',
        borderRadius: '50%',
        borderColor: 'red',
        boxShadow: '0 0.46875rem 2.1875rem rgba(59,62,102,.03), 0 0.9375rem 1.40625rem rgba(59,62,102,.03), 0 0.25rem 0.53125rem rgba(59,62,102,.05), 0 0.125rem 0.1875rem rgba(59,62,102,.03)',
        '& img': {
            maxWidth: '100%',
            height: '100%',
            width: '100%',
            objectFit: 'cover'
        }
    }
})