export default theme => ({
    root: {
        color: 'white'
    },
    itemWithChild: {
        display: 'block',
        paddingTop: 0,
        paddingBottom: 0,
        '& .MuiButtonBase-root': {
            color: 'white',
            padding: '10px 8px',
            justifyContent: 'flex-start',
            textTransform: 'none',
            letterSpacing: 0,
            width: '100%'
        }
    },
    icon: {
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    expandIcon: {
        marginLeft: 'auto',
        height: 16,
        width: 16
    },
    item: {
        color: 'white',
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
        '& .MuiButtonBase-root': {
            color: 'white',
            padding: '10px 8px',
            justifyContent: 'flex-start',
            textTransform: 'none',
            letterSpacing: 0,
            width: '100%'
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
        }
    },
    active: {
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: '#e91e63',
        boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(233, 30, 99, .4)',
        '& $icon': {
            color: 'white'
        },
        '&:hover': {
            backgroundColor: '#e91e63'
        }
    }
})