import backgroundImage from './../../../assets/images/login-background.jpg';

export default theme => ({
    root: {
        backgroundSize: 'cover',
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: 'gray',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
    },
    card: {
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
        borderRadius: '6px',
        padding: '10px 20px',
        overflow: 'unset'
    },
    cardHeader: {
        textAlign: 'center',
        padding: '20px',
        background: '#cc1214',
        boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4)',
        margin: '-20px 15px 0',
        marginTop: '-40px',
        marginBottom: '20px',
        '& h2': {
            marginTop: '10px',
            marginBottom: '10px',
            fontWeight: 700,
            color: '#FFFFFF'
        }
    },
    form: {
        margin: '40px 0'
    },
    link: {
        color: '#000000',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    alert: {
        marginBottom: '20px'
    },
    boxFooter: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        margin: '0 40px 40px'
    }
})

