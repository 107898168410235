import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/pl';
import 'moment/locale/es';
import { useConfirm } from 'material-ui-confirm';
import { 
    withStyles,
    Grid,
    Fab,
    Box,
    Switch
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Table from './../../../components/Table'
import EditIcon from '@material-ui/icons/Edit';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import flagPolandIcon from '../../../assets/icons/poland.png';
import flagPortugalIcon from '../../../assets/icons/portugal.png';
import flagSpainIcon from '../../../assets/icons/spain.png';
import flagUnitedKingdomIcon from '../../../assets/icons/united-kingdom.png';
import flagPolandIconDisable from '../../../assets/icons/poland_bw.png';
import flagPortugalIconDisable from '../../../assets/icons/portugal_bw.png';
import flagSpainIconDisable from '../../../assets/icons/spain_bw.png';
import flagUnitedKingdomIconDisable from '../../../assets/icons/united-kingdom_bw.png';
import ModalDateTime from './../../../components/ModalDateTime';
import LoadingScreen from './../../../components/LoadingScreen'

import * as apiService from './../../../utils/api';
import * as actionTypes from './../../../actions';

import { Context } from './../../../context';

const List = ({
    classes
}) => {

    const { t, i18n } = useTranslation();
    const { state, dispatch } = useContext(Context);
    moment.locale(i18n.language);
    const history = useHistory();
    const confirm = useConfirm();
    const [data, setData] = useState([])
    const [trainings, setTrainings] = useState([])
    const [openModalDateTime, setOpenModalDateTime] = useState(false)
    const [loading, setLoading] = useState(true)
    const [challengData, setChallengData] = useState(null)


    const actions = [
        {
            icon: 'scheduleIcon',
            tooltip: t('schedule'),
            onClick: (event, rowData) => { handleSchedulePublishClick(rowData) }
        },
        {
            icon: 'delete',
            tooltip: t('delete'),
            onClick: (event, rowData) => { handleDeleteClick(rowData) }
        },
        {
            icon: () => <EditIcon />,
            tooltip: t('edit'),
            onClick: (event, rowData) => {
                history.push(`/training/edit/${rowData.id}`)
            }
        }
    ];

    let columns = [
        { title: t('id'), field: 'id', hidden: true },
        { title: t('title'), field: 'title' },
        { title: t('create date'), field: 'createDate' },
        { title: t('publish'), field: 'publish' },
        { title: t('highlight'), field: 'highlight' },
        { title: t('translation'), field: 'translation' },
        { title: t('publishDate'), field: 'publishDate' }
    ]

    useEffect(() => {

        const fetchChallenge = async () => {
            try {
                setLoading(true)
                const resp = await apiService.getTrainings('training');
                setTrainings(resp.data.content)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchChallenge()
        return () => {
            //cleanup
        }
    }, [])


    useEffect(() => {
        const setupData = () => {
            const setDataTable = () => {
                return trainings.map(training => {
                        return {
                            id: training._id,
                            title: training[`title_${i18n.language}`],
                            createDate: training.hasOwnProperty('createdAt') ? `${moment(training.createdAt).format('ll')}` : null,
                            publish: (
                                <Switch
                                    onChange={() =>  handlePublishClick(training) }
                                    size="small"
                                    checked={(training.published && new Date(training.published).getTime() < Date.now())}
                                    color="primary"
                                />
                            ),
                            highlight: (
                                <Switch
                                    onChange={() => handleHighlightClick(training)}
                                    size="small"
                                    checked={training.featured}
                                    color="primary"
                                />
                            ),
                            translation: (
                                <div className={classes.listTranslate}>
                                    {training.hasOwnProperty('title_pl') && training.title_pl ? <img src={flagPolandIcon} alt="" /> : <img src={flagPolandIconDisable} alt="" />}
                                    {training.hasOwnProperty('title_pt') && training.title_pt ? <img src={flagPortugalIcon} alt="" /> : <img src={flagPortugalIconDisable} alt="" />}
                                    {training.hasOwnProperty('title_es') && training.title_es ? <img src={flagSpainIcon} alt="" /> : <img src={flagSpainIconDisable} alt="" />}
                                    {training.hasOwnProperty('title_en') && training.title_en ? <img src={flagUnitedKingdomIcon} alt="" /> : <img src={flagUnitedKingdomIconDisable} alt="" />}
                                </div>
                            ),
                            publishAt: training.publishAt,
                            publishDate: (training.hasOwnProperty('publishAt') && training?.publishAt && training.published && new Date(training.published).getTime() < Date.now()) ? `${moment(training.publishAt).format('llll')}` : '---',
                        }
                    })
            }
            setData(setDataTable())   
        }
 
        setupData()
        return () => {
            //cleanup
        }
    }, [trainings, classes.listTranslate, i18n.language])

  

    const handleHighlightClick = (data) => {
        const info = data.published ? 'are you sure to unhighlight this item' : 'are you sure to highlight this item'
        confirm({ title: t('are you sure'), description: t(info) })
            .then(async () => {
                try {
                    setLoading(true)
                    await apiService.featureTraining(data._id);
                    const resp = await apiService.getTrainings('training');
                    setTrainings(resp.data.content)
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                }
             })
            .catch(() => {  });
    }

    const  handlePublishClick  = (data) => {
        const info = data.published ? 'are you sure to unpublish this item' : 'are you sure to publish this item'
        confirm({ title: t('are you sure'), description: t(info) })
            .then(async () => {
                try {
                    setLoading(true)
                    const postData = {
                        published: !data.published,
                        publishAt: new Date()
                    }
                    await apiService.publishTraining(data._id, postData);
                    const resp = await apiService.getTrainings('training');
                    setTrainings(resp.data.content)
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                }
            })
            .catch(() => { });
    }

    const handleDeleteClick = (data) => {
        confirm({ title: t( 'are you sure' ), description: t( 'are you sure to delete this item') })
            .then(async () => {
                try {
                    setLoading(true)
                    await apiService.deleteTraining(data.id);
                    const resp = await apiService.getTrainings('training');
                    setTrainings(resp.data.content)
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                }
            })
            .catch(() => { });
    }


    const handleSchedulePublishClick = (data) => {
        setChallengData(data)
        setOpenModalDateTime(true)
    }

    const hanldeSchedulePublishConfirm = async (date) => {
        setOpenModalDateTime(false)
        try {
            setLoading(true)
            const postData = {
                published: !challengData.isPublished,
                publishAt: new Date(date)
            }
            await apiService.publishTraining(challengData.id, postData);
            const resp = await apiService.getTrainings('training');
            setTrainings(resp.data.content)
            setLoading(false)
            dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })
        } catch (error) {
            setLoading(false)
            dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('updated error') } })
        }
    }



    return (
        <>
            <div className={classes.root}>
                <div className={classes.iconBox}>
                    <DirectionsRunIcon />
                </div>
                <Grid container justify="space-between" spacing={3}>
                    <Grid item className={classes.gridHeader} >
                        <h3>{t('trainings')}</h3>
                    </Grid>
                    <Grid item >
                        <Fab onClick={() => history.push('/training/create')}>
                            <AddIcon />
                        </Fab>
                    </Grid>
                </Grid>
                <Box className={classes.boxRoot}>
                    <Table
                        title={null}
                        columns={columns}
                        actions={actions}
                        data={data}
                    />
                </Box>
                {loading ? <LoadingScreen /> : null}
            </div>
            {openModalDateTime ? <ModalDateTime
                data={challengData}
                onCancel={() => setOpenModalDateTime(false)}
                onSelect={hanldeSchedulePublishConfirm} /> : null}
        </>
        
    )
}

List.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(List)



