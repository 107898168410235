import React, { useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { Formik } from 'formik';
import styles from './styles';
import {
    withStyles,
    Grid,
    TextField,
    Button
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Autocomplete } from '@material-ui/lab';
import SubmitButton from '../../../components/SubmitButton';
import DownloadIcon from '@material-ui/icons/GetApp';
import * as apiService from './../../../utils/api';

import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfStepCodes from './../../../components/PdfStepCode';



const Generate = ({
    classes
}) => {

    const { t, i18n } = useTranslation();
    const [steps, setSteps] = useState([])
    const [stepCodeGenerated, setStepCodeGenerated] = useState(null)
    const [downloadPdf, setSownloadPdf] = useState(false)
    const [step, setStep] = useState(null)

    const refPdfDownloadLink = useRef(null)


    const downloaPdfHandle = () => {
        const a_ref = refPdfDownloadLink.current.querySelector('a');
        a_ref.click()
    }


    useEffect(() => {
        const fetchStepCodes = async () => {
            try {
                const resp = await apiService.getSteps();
                setSteps(resp.data.content)
            } catch (error) { }
        }
        fetchStepCodes()
        return () => {
            //cleanup
        }
    }, [])


    return (
        <>
        <Formik
            initialValues={{
                step: null
            }}
            onSubmit={async (values, { resetForm, setErrors }) => {
                try {
                            setSownloadPdf(false)
                            const step = values.step
                            if (!step) {
                                setErrors({ step: t('please select a step') })
                                return null
                            }
                            const resp = await apiService.generateStepCodes(step._id)
                            const stp = resp.data.content
                            setStepCodeGenerated(stp)
                            setSownloadPdf(true)
                        } catch (error) {
                            setSownloadPdf(false)
                        }
                        }}
                    >
                {props => {
                return (
                    <form onSubmit={props.handleSubmit}>
                        <div className={classes.root}>
                            {!!props.errors.step && (
                                <Alert severity="error" style={{marginBottom: '20px'}}>{props.errors.step}</Alert>
                            )}
                            <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    <Autocomplete
                                        id="step"
                                        options={steps}
                                        getOptionLabel={(option) => option[`title_${i18n.language}`]}
                                        style={{ width: 300 }}
                                        onChange={(event, newValue) => {
                                            props.setValues({ step: newValue })
                                            setStep(newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} label={t('select step')} variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', margin: '30px 0 0' }}>
                                    {downloadPdf ? (
                                        <Button
                                            variant="contained"
                                            color="default"
                                            className={classes.buttonDownloadPdf}
                                            startIcon={<DownloadIcon />}
                                            onClick={downloaPdfHandle}
                                        >
                                            {t('get pdf')}
                                        </Button>
                                    ) : null}
                                    <SubmitButton
                                        isSubmitting={props.isSubmitting}>
                                        {t('generate')}
                                    </SubmitButton>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                    )
                }}

            </Formik>
            {downloadPdf ? (
                <div ref={refPdfDownloadLink}>
                    <PDFDownloadLink
                        id="PDFDownloadLink"
                        className={classes.downloadLink}
                        document={<PdfStepCodes data={stepCodeGenerated} step={step}/>}
                        fileName="codes.pdf">
                    </PDFDownloadLink>
                </div>
            ) : null}
            </>
    )
}

Generate.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Generate)
