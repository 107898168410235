const classes =  theme => ({
    root: {
        padding: '30px 10px',
        borderBottom: '1px solid #dedede'
    },
    content: {
        padding: '30px 20px 0px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    boxActions: {
        width: '100px',
        '& svg': {
            margin: '0 5px'
        }
    },
    buttonCloseArea: {
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0'
    },
    buttonClose: {
    },
    noRead: {
        fontWeight: 'bold'
    },
    bodyMessage: {
        cursor: 'pointer'
    }
})
export default classes;