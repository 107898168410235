import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Context } from '../../../context';
import RichEditorText from '../../../components/RichEditorText';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    withStyles,
    FormControl,
    Grid,
    TextField,
    Box
} from '@material-ui/core';
import { Formik, } from 'formik';
import FileDropzone from '../../../components/FileDropzone';
import SubmitButton from '../../../components/SubmitButton';
import { toBase64, getFileDto } from '../../../utils/util'
import * as apiService from '../../../utils/api';
import * as actionTypes from '../../../actions';


const defaultInitialValues = {
    title_pt: '',
    text_pt: '',
    link_pt: '',
    title_en: '',
    text_en: '',
    link_en: '',
    title_es: '',
    text_es: '',
    link_es: '',
    title_pl: '',
    text_pl: '',
    link_pl: '',
    published: false
}



const Form = ({
    classes,
    lng,
    product,
    edit
}) => {

    const { state, dispatch } = useContext(Context);
    const { t } = useTranslation();
    const [disabled, setDisabled] = useState(false)
    const [images, setImages] = useState({
        image_pt: null,
        image_en: null,
        image_pl: null,
        image_es: null
    })


    const onUploadImages = async (fls) => {
        const fl = fls[0]
        let preview = null;
        let resp = null;
        let file = null;
        if (fl) {
            preview = URL.createObjectURL(fl) 
            resp = await toBase64(fl)
            file = {
                file: resp,
                type: fl.type,
                filename: `${new Date().getTime()}_${fl.name}`,
                preview: preview
            }
        }
        let filesCopy = { ...images }
        filesCopy[`image_${lng}`] = file
        setImages(filesCopy)
    }

  
    useEffect(() => {
        const setupPermissionEdit = () => {
            if (state?.role === 'admin') {
                const disabled = (state?.user.language !== lng)
                setDisabled(disabled)
            }
        }
        if (product) {
            const imgs = {
                image_pt: product.image_pt,
                image_en: product.image_en,
                image_pl: product.image_pl,
                image_es: product.image_es
            }
            setImages(imgs)
        }
        setupPermissionEdit()


    }, [lng, product, state?.role, state?.user.language])


    return (
        <Formik
            enableReinitialize={true}
            initialValues={product && Object.keys(product).length !== 0 ? getDataForm(product) : defaultInitialValues}
            onSubmit={async (values, { resetForm }) => {
                try {
                    const imgs = {
                        image_pt: await getFileDto(images.image_pt),
                        image_en: await getFileDto(images.image_en),
                        image_pl: await getFileDto(images.image_pl),
                        image_es: await getFileDto(images.image_es)
                    }

                    const data = {
                        ...getDto(values),
                        ...getImages(imgs)
                    }
                    if (edit) {
                        await apiService.updateProduct(product._id, data)
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })

                    } else {
                        await apiService.createProduct(data)
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })

                    }
                } catch (error) {
                    if (edit) {
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('updated error') } })
                    } else {
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('updated error') } })
                    }
                }
            }}
        >   
            {props => {
                return (
                    <form onSubmit={props.handleSubmit}>
                        <div className={classes.root}>
                            <Box id="box-pt" className={clsx({ [classes.noDisplay]: lng !== 'pt' })}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                variant="outlined"
                                                id="title_pt"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_pt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_pt && props.touched.title_pt}
                                                helperText={(props.errors.title_pt && props.touched.title_pt) && props.errors.title_pt}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                variant="outlined"
                                                id="link_pt"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('link')}`}
                                                value={props.values.link_pt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.link_pt && props.touched.link_pt}
                                                helperText={(props.errors.link_pt && props.touched.link_pt) && props.errors.link_pt}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <RichEditorText
                                                name="text_pt"
                                                label={t('text')}
                                                value={props.values.text_pt}
                                                onChange={data => props.setFieldValue('text_pt', data)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} pdf={false} onAcceptFiles={onUploadImages} data={product.image_pt}/>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box id="box-en" className={clsx({ [classes.noDisplay]: lng !== 'en' })}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                variant='outlined'
                                                id="title_en"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_en}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_en && props.touched.title_en}
                                                helperText={(props.errors.title_en && props.touched.title_en) && props.errors.title_en}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                id="link_en"
                                                placeholder=""
                                                autoComplete="off"
                                                variant='outlined'
                                                margin="dense"
                                                label={`${t('link')}`}
                                                value={props.values.link_en}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.link_en && props.touched.link_en}
                                                helperText={(props.errors.link_en && props.touched.link_en) && props.errors.link_en}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <RichEditorText
                                                name="text_en"
                                                label={t('text')}
                                                value={props.values.text_en}
                                                onChange={data => props.setFieldValue('text_en', data)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} pdf={false} onAcceptFiles={onUploadImages} data={product.image_en}/>
                                    </Grid>
                                </Grid>
                            </Box>
                           
                            <Box id="box-es" className={clsx({ [classes.noDisplay]: lng !== 'es' })}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                variant='outlined'
                                                id="title_es"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_es}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_es && props.touched.title_es}
                                                helperText={(props.errors.title_es && props.touched.title_es) && props.errors.title_es}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                id="link_es"
                                                placeholder=""
                                                autoComplete="off"
                                                variant='outlined'
                                                margin="dense"
                                                label={`${t('link')}`}
                                                value={props.values.link_es}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.link_es && props.touched.link_es}
                                                helperText={(props.errors.link_es && props.touched.link_es) && props.errors.link_es}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <RichEditorText
                                                name="text_es"
                                                label={t('text')}
                                                value={props.values.text_es}
                                                onChange={data => props.setFieldValue('text_es', data)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} pdf={false} onAcceptFiles={onUploadImages} data={product.image_es}/>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box id="box-pl" className={clsx({ [classes.noDisplay]: lng !== 'pl' })}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                variant='outlined'
                                                id="title_pl"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_pl}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_pl && props.touched.title_pl}
                                                helperText={(props.errors.title_pl && props.touched.title_pl) && props.errors.title_pl}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField disabled={disabled}
                                                id="link_pl"
                                                placeholder=""
                                                autoComplete="off"
                                                variant='outlined'
                                                margin="dense"
                                                label={`${t('link')}`}
                                                value={props.values.link_pl}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.link_pl && props.touched.link_pl}
                                                helperText={(props.errors.link_pl && props.touched.link_pl) && props.errors.link_pl}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <RichEditorText
                                                name="text_pl"
                                                label={t('text')}
                                                value={props.values.text_pl}
                                                onChange={data => props.setFieldValue('text_pl', data)}
                                             />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} pdf={false} onAcceptFiles={onUploadImages} data={product.image_pl}/>
                                    </Grid>
                                </Grid>
                            </Box>
                           
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', margin: '30px 0' }}>
                                <SubmitButton
                                    isSubmitting={props.isSubmitting}>
                                    {t('save')}
                                </SubmitButton>
                            </Grid>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

Form.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Form)

const getDataForm = (step) => {
    return step
} 



const getDto = (values) => {
    const data = {
        title_pt: values.title_pt,
        text_pt: values.text_pt,
        link_pt: values.link_pt,
        title_en: values.title_en,
        text_en: values.text_en,
        link_en: values.link_en,
        title_es: values.title_es,
        text_es: values.text_es,
        link_es: values.link_es,
        title_pl: values.title_pl,
        text_pl: values.text_pl,
        link_pl: values.link_pl,
        published: values.published

    }
    return data;
}

const getImages = (images) => {
    const lng = ['pt', 'en', 'es', 'pl']
    const data = {}
    for (let index = 0; index < lng.length; index++) {
        const image = images[`image_${lng[index]}`]
        if (image && !image.hasOwnProperty('_id')) {
            data[`image_${lng[index]}`] = images[`image_${lng[index]}`]
        }
    }
    return data
}


const getPdfs = (pdfs, step) => {
    const lng = ['pt', 'en', 'es', 'pl']
    const data = {}
    for (let index = 0; index < lng.length; index++) {
        if (pdfs[`pdf_${lng[index]}`]) {
            data[`pdf_${lng[index]}`] = pdfs[`pdf_${lng[index]}`]
        }
    }
    return data
}



