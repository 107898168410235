import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Context } from './../../context';
import { compose } from 'recompose';
import styles from './styles';
import clsx from 'clsx';
import {
    withStyles,
    DialogTitle,
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core';
import * as actionTypes from './../../actions';




const DialogInfo = ({
    classes
}) => {

    const { dispatch, state } = useContext(Context);
    const dialogInfo = { ...state.dialogInfo }

    const handleClose = () => {
        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: false, type: -1, title: "", message: "" } })
    }


    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={dialogInfo.open} maxWidth="sm" disableBackdropClick={true}>
            <DialogTitle id="simple-dialog-title"
                className={clsx(
                    {[classes.success]: dialogInfo.type === 1 },
                    {[classes.error]: dialogInfo.type === 0}
                )}>
                {dialogInfo.title}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {dialogInfo.message}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}

DialogInfo.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(DialogInfo)
