import React  from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './styles';
import {
    withStyles,
    Grid,
    FormControl,
    TextField,
    Container,
    Card,
    CardContent,
} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import Alert from '@material-ui/lab/Alert';
import SubmitButton from '../../../components/SubmitButton';
import Footer from './../../../layouts/Main/components/Footer';
import { requestPasswordReset } from '../../../utils/api';


const RecoverPassword = ({
    classes
}) => {

    const { t } = useTranslation();




    const formik = useFormik({
        validateOnMount: false,
        initialValues: {
            email: 'pedrotorre5@hotmail.com'
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email(t('invalid email'))
                .required(t('required field'))
        }),
        onSubmit: async (values) => {
            try {
            const data = {
                email: values.email,
                redirectUrl: "https://dominio_bo/confirm-password-reset"
            }
                const resp = await requestPasswordReset(data)
            } catch (error) {
                formik.setErrors({ passwordReset: t('general request password reset') });
               
            }
        }
    })

    return (
        <>
            <div className={classes.root}>
                <Container maxWidth="sm">
                    <Card className={classes.card}>
                        <div className={classes.cardHeader}>
                            <h2>{t('recover password')}</h2>
                        </div>
                        <CardContent>
                            {!!formik.errors.passwordReset && (
                                <Alert severity="error">{formik.errors.passwordReset}</Alert>
                            )}

                            <form className={classes.form} onSubmit={formik.handleSubmit}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="email"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        margin="normal"
                                                        variant="standard"
                                                        label="Email"
                                                        value={formik.values.email}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.errors.email && formik.touched.email}
                                                        helperText={(formik.errors.email && formik.touched.email) && formik.errors.email}
                                                        InputProps={{
                                                            endAdornment: <EmailIcon />
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container justify="flex-end" alignItems="center" spacing={4}>
                                            <Grid item >
                                                <Link
                                                    className={classes.link}
                                                    to="/auth/login">{t('login')}</Link>
                                            </Grid>
                                            <Grid item>
                                                <SubmitButton
                                                    isSubmitting={formik.isSubmitting}>
                                                    {t('recover password')}
                                                </SubmitButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>

                    </Card>
                </Container>
                <div className={classes.boxFooter}>
                    <Footer transparent={true}/>
                </div>
            </div>


        </>
    )
}

RecoverPassword.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(RecoverPassword)
