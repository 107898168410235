import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import YouTube from 'react-youtube';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Context } from './../../../context';
import {
    withStyles,
    FormControl,
    Grid,
    TextField,
    Box,
    Paper
} from '@material-ui/core';
import { Formik } from 'formik';
import RichEditorText from './../../../components/RichEditorText';
import FileDropzone from './../../../components/FileDropzone';
import SubmitButton from '../../../components/SubmitButton';
import { toBase64, getFileDto, getImages } from './../../../utils/util'
import * as actionTypes from './../../../actions';
import * as apiService from './../../../utils/api';
import * as util from './../../../utils/util';



const defaultInitialValues = {
    title_pt: '',
    subtitle_pt: '',
    text_pt: '',
    videoLink_pt: '',
    title_en: '',
    subtitle_en: '',
    text_en: '',
    videoLink_en: '',
    title_es: '',
    subtitle_es: '',
    text_es: '',
    videoLink_es: '',
    title_pl: '',
    subtitle_pl: '',
    text_pl: '',
    videoLink_pl: '',
    published: false,
    featured: false,
}

const Form = ({
    classes,
    lng,
    challenge,
    edit
}) => {




    const { state, dispatch } = useContext(Context);
    const history = useHistory();
    const { t } = useTranslation();
    const [disabled, setDisabled] = useState(false)
    const [images, setImages] = useState({
        image_pt: null,
        image_en: null,
        image_pl: null,
        image_es: null
    })



    const onUploadImages = async (fls) => {
        const fl = fls[0]
        let preview = null;
        let resp = null;
        let file = null;
        if (fl) {
            preview = URL.createObjectURL(fl)
            resp = await toBase64(fl)
            file = {
                file: resp,
                type: fl.type,
                filename: `${new Date().getTime()}_${fl.name}`,
                preview: preview
            }
        }
        let filesCopy = { ...images }
        filesCopy[`image_${lng}`] = file
        setImages(filesCopy)
    }




    useEffect(() => {
        const setupPermissionEdit = () => {
            if (state?.role === 'admin') {
                const disabled = (state?.user.language !== lng)
                setDisabled(disabled)
            }
        }
        if (challenge) {
            const imgs = {
                image_pt: challenge.image_pt,
                image_en: challenge.image_en,
                image_pl: challenge.image_pl,
                image_es: challenge.image_es
            }
            setImages(imgs)
        }
        setupPermissionEdit()
        return () => {
            //
        }
    }, [])


    return (
        <Formik
            initialValues={challenge ? { ...defaultInitialValues, ...challenge } : defaultInitialValues}
            validationSchema={
                Yup.object().shape({})
            }
            onSubmit={async (values, { resetForm }) => {
                try {
                    const imgs = {
                        image_pt: await getFileDto(images.image_pt),
                        image_en: await getFileDto(images.image_en),
                        image_pl: await getFileDto(images.image_pl),
                        image_es: await getFileDto(images.image_es)
                    }
                    const data = {
                        ...getDto(values),
                        ...getImages(imgs)
                    }
                    if (edit) {
                        await apiService.updateTraining(challenge._id, data)
                        history.push('/challenges')
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })

                    } else {
                        await apiService.createTraining(data)
                        history.push('/challenges')
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })
                    }

                } catch (error) {
                    if (edit) {
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('updated error') } })
                    } else {
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('updated error') } })
                    }
                }
            }}
        >   
            {props => {
                return (
                    <form onSubmit={props.handleSubmit}>
                        <div className={classes.root}>
                            <Box id="box-pt" className={clsx({ [classes.noDisplay]: lng !== 'pt' })}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                id="title_pt"
                                                placeholder=""
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_pt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_pt && props.touched.title_pt}
                                                helperText={(props.errors.title_pt && props.touched.title_pt) && props.errors.title_pt}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                id="subtitle_pt"
                                                placeholder=""
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('subtitle')}`}
                                                value={props.values.subtitle_pt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.subtitle_pt && props.touched.subtitle_pt}
                                                helperText={(props.errors.subtitle_pt && props.touched.subtitle_pt) && props.errors.subtitle_pt}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} onAcceptFiles={onUploadImages} pdf={false}  data={challenge.image_pt}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box style={{ height: '100%' }}>
                                            <FormControl fullWidth>
                                                    <TextField disabled={disabled}
                                                    id="videoLink_pt"
                                                    placeholder=""
                                                    variant='outlined'
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('videoLink')}`}
                                                    value={props.values.videoLink_pt}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.videoLink_pt && props.touched.videoLink_pt}
                                                    helperText={(props.errors.videoLink_pt && props.touched.videoLink_pt) && props.errors.videoLink_pt}
                                                />
                                            </FormControl>
                                            <Paper elevation={2} style={{backgroundColor: 'rgba(0, 0,0, 0.8)'}}>
                                                <YouTube
                                                    className={classes.iframe}
                                                    videoId={util.getIdYoutubeUrl(props.values.videoLink_pt)}
                                                />
                                            </Paper>
                                            
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RichEditorText
                                            name="text_pt"
                                            label={t('text')}
                                            value={props.values.text_pt}
                                            onChange={data => props.setFieldValue('text_pt', data)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box id="box-en" className={clsx({ [classes.noDisplay]: lng !== 'en' })}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                id="title_en"
                                                placeholder=""
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_en}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_en && props.touched.title_en}
                                                helperText={(props.errors.title_en && props.touched.title_en) && props.errors.title_en}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                id="subtitle_en"
                                                placeholder=""
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('subtitle')}`}
                                                value={props.values.subtitle_en}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.subtitle_en && props.touched.subtitle_en}
                                                helperText={(props.errors.subtitle_en && props.touched.subtitle_en) && props.errors.subtitle_en}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} onAcceptFiles={onUploadImages} pdf={false} data={challenge.image_en}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box style={{ height: '100%' }}>
                                            <FormControl fullWidth>
                                                    <TextField disabled={disabled}
                                                    id="videoLink_en"
                                                    placeholder=""
                                                    variant='outlined'
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('videoLink')}`}
                                                    value={props.values.videoLink_en}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.videoLink_en && props.touched.videoLink_en}
                                                    helperText={(props.errors.videoLink_en && props.touched.videoLink_en) && props.errors.videoLink_en}
                                                />
                                            </FormControl>
                                            <Paper elevation={2} style={{ backgroundColor: 'rgba(0, 0,0, 0.8)' }}>
                                                <YouTube
                                                    className={classes.iframe}
                                                    videoId={util.getIdYoutubeUrl(props.values.videoLink_en)}
                                                />
                                            </Paper>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RichEditorText
                                            name="text_en"
                                            label={t('text')}
                                            value={props.values.text_en}
                                            onChange={data => props.setFieldValue('text_en', data)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box id="box-es" className={clsx({ [classes.noDisplay]: lng !== 'es' })}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                id="title_es"
                                                placeholder=""
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_es}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_es && props.touched.title_es}
                                                helperText={(props.errors.title_es && props.touched.title_es) && props.errors.title_es}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                id="subtitle_es"
                                                placeholder=""
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('subtitle')}`}
                                                value={props.values.subtitle_es}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.subtitle_es && props.touched.subtitle_es}
                                                helperText={(props.errors.subtitle_es && props.touched.subtitle_es) && props.errors.subtitle_es}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} onAcceptFiles={onUploadImages} pdf={false} data={challenge.image_es}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box style={{ height: '100%' }}>
                                            <FormControl fullWidth>
                                                    <TextField disabled={disabled}
                                                    id="videoLink_es"
                                                    placeholder=""
                                                    variant='outlined'
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('videoLink')}`}
                                                    value={props.values.videoLink_es}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.videoLink_es && props.touched.videoLink_es}
                                                    helperText={(props.errors.videoLink_es && props.touched.videoLink_es) && props.errors.videoLink_es}
                                                />
                                            </FormControl>
                                            <Paper elevation={2} style={{backgroundColor: 'rgba(0, 0,0, 0.8)'}}>
                                                <YouTube
                                                    className={classes.iframe}
                                                    videoId={util.getIdYoutubeUrl(props.values.videoLink_es)}
                                                />
                                            </Paper>
                                           
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RichEditorText
                                            name="text_es"
                                            label={t('text')}
                                            value={props.values.text_es}
                                            onChange={data => props.setFieldValue('text_es', data)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box id="box-pl" className={clsx({ [classes.noDisplay]: lng !== 'pl' })}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                id="title_pl"
                                                variant='outlined'
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_pl}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_pl && props.touched.title_pl}
                                                helperText={(props.errors.title_pl && props.touched.title_pl) && props.errors.title_pl}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                                <TextField disabled={disabled}
                                                id="subtitle_pl"
                                                placeholder=""
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('subtitle')}`}
                                                value={props.values.subtitle_pl}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.subtitle_pl && props.touched.subtitle_pl}
                                                helperText={(props.errors.subtitle_pl && props.touched.subtitle_pl) && props.errors.subtitle_pl}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} onAcceptFiles={onUploadImages} pdf={false} data={challenge.image_pl}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box style={{ height: '100%' }}>
                                            <FormControl fullWidth>
                                                    <TextField disabled={disabled}
                                                    id="videoLink_pl"
                                                    placeholder=""
                                                    variant='outlined'
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('videoLink')}`}
                                                    value={props.values.videoLink_pl}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.videoLink_pl && props.touched.videoLink_pl}
                                                    helperText={(props.errors.videoLink_pl && props.touched.videoLink_pl) && props.errors.videoLink_pl}
                                                />
                                            </FormControl>
                                            <Paper elevation={2} style={{ backgroundColor: 'rgba(0, 0,0, 0.8)' }}>
                                                <YouTube
                                                    className={classes.iframe}
                                                    videoId={util.getIdYoutubeUrl(props.values.videoLink_pl)}
                                                />
                                            </Paper>
                                            
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RichEditorText
                                            name="text_pl"
                                            label={t('text')}
                                            value={props.values.text_pl}
                                            onChange={data => props.setFieldValue('text_pl', data)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', margin: '30px 0' }}>
                                <SubmitButton
                                    isSubmitting={props.isSubmitting}>
                                    {t('save')}
                                </SubmitButton>
                            </Grid>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

Form.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Form)

const getDto = (values) => {
    const data = {
        videoLink_en: values.videoLink_en,
        videoLink_es: values.videoLink_es,
        videoLink_pl: values.videoLink_pl,
        videoLink_pt: values.videoLink_pt,
        subtitle_en: values.subtitle_en,
        subtitle_es: values.subtitle_es,
        subtitle_pl: values.subtitle_pl,
        subtitle_pt: values.subtitle_pt,
        text_en: values.text_en,
        text_es: values.text_es,
        text_pl: values.text_pl,
        text_pt: values.text_pt,
        title_en: values.title_en,
        title_es: values.title_es,
        title_pl: values.title_pl,
        title_pt: values.title_pt,
        section: 'challenge',
        bottom_pt: "",
        bottom_en: "",
        bottom_es: "",
        bottom_pl: "",
        published: values.published,
        featured: values.featured,
    }
    return data;
}

