import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import clsx from 'clsx';
import TopBar from './components/TopBar';
import SideBar from './components/SideBar';
import Footer from './components/Footer';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { Context } from './../../context';

const Main = ({
  children,
  classes
}) => {

  const { state } = useContext(Context)

  const [openDrawer, setOpenDrawer] = useState(false)

  const openDrawerSideBarHandler = () => {
    setOpenDrawer(true)
  }


 // const topBarRender = state.isAuthenticated ? <TopBar openDrawerSideBar={openDrawerSideBarHandler} /> : null 
  

  const sideBarRender = state.isAuthenticated ?
    <SideBar
      closeDrawer={() => setOpenDrawer(false)}
      openDrawer={openDrawer} /> : null
  
  const footerRender = state.isAuthenticated ?
    <Footer /> : null
  
  
  if (!state.checkedAuthentication) {
    return null
  }


  return (
        <div className={classes.root}>
          { /* topBarRender */ }
          <div className= {classes.container}>
          {sideBarRender }
          <main className={clsx({
            [classes.main]: true,
            [classes.mainPadding]: state.isAuthenticated
          })} >
                  {children}
              </main>
          </div>
         { /* footerRender */}
        </div>
  )
}

Main.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.any
}

export default compose(
  withStyles(styles)
)(Main)



