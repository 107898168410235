import React, { useReducer, createContext } from "react";

import * as actionTypes from '../actions';

export const Context = createContext();



const initialState = {
    accessToken: null,
    checkedAuthentication: false,
    isAuthenticated: false,
    user: null,
    establishment: null,
    dialogInfo: {
        open: false,
        type: 1,
        title: "",
        message: ""
    },
    role: null
}


export const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SESSION_CHECK: {
            return {
                ...state,
                checkedAuthentication: true
            }
        }
        case actionTypes.SESSION_LOGIN: {
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                accessToken: action.payload.token,
                role: action.payload.user.role
            }
        }
        case actionTypes.SET_USER: {
            return {
                ...state,
                user: action.payload
            }
        }
        case actionTypes.SESSION_LOGOUT: {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                accessToken: null
            }
        }
        case actionTypes.DIALOG_INFO: {
            return {
                ...state,
                dialogInfo: { ...action.payload }
            }
        }
        default:
            return state;
    }
}


const ContextProvider = (props) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };

    return (
        <Context.Provider value={value}>
            {props.children}
        </Context.Provider>
    )
}
export default ContextProvider;

