import React, { useContext } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import styles from './styles';
import { useHistory } from 'react-router-dom';
import {
 withStyles,
  AppBar,
  Toolbar,
  IconButton,
  Hidden
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import { Link as RouterLink } from 'react-router-dom';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import logo from './../../../../assets/images/logo.png';
import { deleteToken, deleteUser } from './../../../../utils/storage';
import * as actionTypes from './../../../../actions';
import { Context } from './../../../../context';


const TopBar = ({
  classes,
  openDrawerSideBar
}) => {


  const history = useHistory();
  const { state, dispatch } = useContext(Context);


  const onClickLogoutHandle = async () => {
    await deleteToken()
    await deleteUser()
    dispatch({ type: actionTypes.SESSION_LOGOUT })
    history.push('/')
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <RouterLink to="/">
            <div className={classes.logo}>
              <img src={logo} alt="" />
            </div>
          </RouterLink>
         
          <div className={classes.content}>
            <Hidden lgUp>
              <IconButton onClick={openDrawerSideBar}>
                <MenuIcon />
              </IconButton>
            </Hidden >
          </div>
          <IconButton className={classes.logout}>
            <LogoutIcon onClick={onClickLogoutHandle}/>
          </IconButton>
        
         
        </Toolbar>
      </AppBar>
    </>
  )
}

TopBar.propTypes = {
  classes: PropTypes.object
}

export default compose(
  withStyles(styles)
)(TopBar)
