import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import moment from 'moment';
import styles from './styles';
import {
    withStyles
} from '@material-ui/core';

const SentMessages = ({
    classes,
    msg
}) => {

    const { t } = useTranslation();


    if (!msg) {
        return null
    }

    return (
        <div className={classes.root}>
            <div className={classes.dateBox}>
                {moment(msg.createdAt).format('lll')}
            </div>
            <div className={classes.content}>
                <div className={classes.bodyMessage}>
                    {msg.text}
                </div>
            </div>
        </div>
    )
}

SentMessages.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(SentMessages)
