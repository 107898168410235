import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { useFormik } from 'formik';
import moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/pl';
import 'moment/locale/es';
import styles from './styles';
import {
    withStyles,
    Grid,
    FormControl,
    InputLabel,
    Input,
    InputAdornment
} from '@material-ui/core';
import WakeTimeIcon from '@material-ui/icons/FreeBreakfast';
import BreakfastIcon from '@material-ui/icons/EmojiFoodBeverage';
import LunchIcon from '@material-ui/icons/Fastfood';
import AfternoonSnackIcon from '@material-ui/icons/LocalDrink';
import DinnerIcon from '@material-ui/icons/Restaurant';
import SupperIcon from '@material-ui/icons/AvTimer';
import TrainingTimeIcon from '@material-ui/icons/DirectionsRun';
import WaterIcon from '@material-ui/icons/InvertColors';
import BowelMovementIcon from '@material-ui/icons/Wc';
import BedTimeIcon from '@material-ui/icons/LocalHotel';
import SupplementsIcon from '@material-ui/icons/LocalHospital';
import ObservationsIcon from '@material-ui/icons/DirectionsRun';
import MorningSnackIcon from '@material-ui/icons/EmojiFoodBeverage';

const defaultInitialValues = {
    afternoonSnack: '',
    bowelMovement: '',
    morningSnack: '',
    breakfast: '',
    dinner: '',
    lunch: '',
    observations: '',
    supper: '',
    supplements: '',
    trainingTime: '',
    user: '',
    water: '',
    wakeTime: '',
    createdAt: '',
    bedTime: '',
    date: '',
    updatedAt: '',
    breakfastTime: '',
    morningSnackTime: '',
    lunchTime: '',
    afternoonSnackTime: '',
    dinnerTime: '',
    supperTime: ''

}



const DiaryInfo = ({
    classes,
    diaryInfo
}) => {



    const { t, i18n } = useTranslation();
    moment.locale(i18n.language);


    const formik = useFormik({
        validateOnMount: false,
        initialValues: diaryInfo ? diaryInfo  : defaultInitialValues
    })

    return (
        <div className={classes.root}>
            <form>
                <Grid container spacing={5}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="wakeTime">{t('wakeTime')}</InputLabel>
                            <Input
                                id="wakeTime"
                                label={t('wakeTime')}
                                value={moment(formik.values.wakeTime).format('lll')}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <WakeTimeIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="breakfast">{t('breakfast')}</InputLabel>
                            <Input
                                id="breakfast"
                                label={t('breakfast morning')}
                                value={formik.values.breakfast}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <BreakfastIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="breakfast">{t('breakfast time')}</InputLabel>
                            <Input
                                id="breakfastTime"
                                label={t('breakfast time')}
                                value={moment(formik.values.breakfastTime).format('lll')}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <BreakfastIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>

                    

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="morningSnack">{t('morningSnack')}</InputLabel>
                            <Input
                                id="morningSnack"
                                label={t('morningSnack')}
                                value={formik.values.morningSnack}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <MorningSnackIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="morningSnackTime">{t('morning snack time')}</InputLabel>
                            <Input
                                id="morningSnackTime"
                                label={t('morning snack time')}
                                value={moment(formik.values.morningSnackTime).format('lll')}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <MorningSnackIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="lunch">{t('lunch')}</InputLabel>
                            <Input
                                id="lunch"
                                label={t('lunch')}
                                value={formik.values.lunch}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <LunchIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="lunch">{t('lunch time')}</InputLabel>
                            <Input
                                id="lunchTime"
                                label={t('lunch time')}
                                value={moment(formik.values.lunchTime).format('lll')}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <LunchIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>


                    
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="afternoonSnack">{t('afternoonSnack')}</InputLabel>
                            <Input
                                id="afternoonSnack"
                                label={t('afternoonSnack')}
                                value={formik.values.afternoonSnack}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <AfternoonSnackIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="afternoonSnackTime">{t('afternoon snack time')}</InputLabel>
                            <Input
                                id="afternoonSnackTime"
                                label={t('morning snack time')}
                                value={moment(formik.values.afternoonSnackTime).format('lll')}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <AfternoonSnackIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="dinner">{t('dinner')}</InputLabel>
                            <Input
                                id="dinner"
                                label={t('dinner')}
                                value={formik.values.dinner}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <DinnerIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="dinnerTime">{t('dinner time')}</InputLabel>
                            <Input
                                id="dinnerTime"
                                label={t('dinner time')}
                                value={moment(formik.values.dinnerTime).format('lll')}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <DinnerIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="supper">{t('supper')}</InputLabel>
                            <Input
                                id="supper"
                                label={t('supper')}
                                value={formik.values.supper}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SupperIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="supperTime">{t('supper time')}</InputLabel>
                            <Input
                                id="supperTime"
                                label={t('supper time')}
                                value={moment(formik.values.supperTime).format('lll')}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SupperIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="trainingTime">{t('trainingTime')}</InputLabel>
                            <Input
                                id="trainingTime"
                                label={t('trainingTime')}
                                value={formik.values.trainingTime}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <TrainingTimeIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="water">{t('water')}</InputLabel>
                            <Input
                                id="water"
                                label={t('water')}
                                value={formik.values.water}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <WaterIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>

                   

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="bedTime">{t('bedTime')}</InputLabel>
                            <Input
                                id="bedTime"
                                label={t('bedTime')}
                                value={moment(formik.values.bedTime).format('lll')}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <BedTimeIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="bowelMovement">{t('bowelMovement')}</InputLabel>
                            <Input
                                id="bowelMovement"
                                label={t('bowelMovement')}
                                value={formik.values.bowelMovement}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <BowelMovementIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="supplements">{t('supplements')}</InputLabel>
                            <Input
                                multiline
                                rows={2}
                                id="supplements"
                                label={t('supplements')}
                                value={formik.values.supplements}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SupplementsIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="observations">{t('observations')}</InputLabel>
                            <Input
                                multiline
                                rows={2}
                                id="observations"
                                label={t('observations')}
                                value={formik.values.observations}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <ObservationsIcon />
                                    </InputAdornment>
                                }
                                readOnly={true}
                            />
                        </FormControl>
                    </Grid>

                </Grid>
            </form>
        </div>
        
    )
}

DiaryInfo.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(DiaryInfo)
