import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import {
    withStyles,
    Container,
    Fab
} from '@material-ui/core';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LanguageTabs from './../../../components/LanguageTabs';
import Form  from './../Form'

const Create = ({
    classes
}) => {

    const { t, i18n } = useTranslation();
    const [languageTab, setLanguageTab] = useState('pt')
    const history = useHistory();


    const onChangeLanguageHandler = (lng) => {
        setLanguageTab(lng.id)
    }

  

    return (
        <div className={classes.root}>
            <div className={classes.iconBox}>
                <DirectionsRunIcon />
            </div>
            <div className={classes.headerBox}>
                <h3>{t('trainings')}</h3>
                <Fab onClick={() => history.push('/training')}>
                    <ArrowBackIcon />
                </Fab>
            </div>
            <div className={classes.contentBox}>
                <Container maxWidth="xl">
                    <LanguageTabs onChangeLanguage={onChangeLanguageHandler} />
                    <Form lng={languageTab} edit={false} training={{}} />
                </Container>
            </div>
        </div>
    )
}

Create.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Create)
