import MuiButton from './MuiButton';
import MuiAppBar from './MuiAppBar';
import MuiDialog from './MuiDialog';
import MuiAlert from './MuiAlert';
import MuiFab from './MuiFab'
import MuiSwitch from './MuiSwitch'

export default {
    MuiButton,
    MuiAppBar,
    MuiDialog,
    MuiAlert,
    MuiFab,
    MuiSwitch
}