import React, { useRef, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import {
    withStyles,
    Button,
    ClickAwayListener,
    Grow,
    Paper,
    Popper
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Context } from './../../context';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const SettingsUserMenu = ({
    classes
}) => {

    const history = useHistory();
    const { t, i18n } = useTranslation();
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [language, setLanguage] = useState({})
    const { state, dispatch } = useContext(Context);

    const {user} = state

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (lang) => (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
        i18n.changeLanguage(lang.id);
    };



    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className={classes.root}>
                <Button
                    className={classes.buttonMenu}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    endIcon={<ArrowDropDownIcon />}
                    startIcon={language.icon}
                >
                    {`${user.firstName} ${user.lastName}`}
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 10 }}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center center' }}
                        >
                            <Paper>
                            
                                    <div className={classes.menuList}>
                                        <h6 onClick={() => {
                                            setOpen(false);
                                            history.push('/user/settings')
                                    }}>{t('settings')}</h6>
                                    </div>
                            
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    )
}

SettingsUserMenu.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(SettingsUserMenu)
