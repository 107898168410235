const classes = theme => ({
    tabs: {
        backgroundColor: 'white',
        borderBottom: '1px solid #E35205',
        marginBottom: '30px'
    },
    tab: {
        '& span': {
            '& img': {
                width: '1.5rem',
                height: '1.5rem'
            }
        }
    }

})
export default classes;