const classes = theme => ({
    root: {
        width: '350px',
        height: '150px',
        borderRadius: '6px',
        backgroundColor: 'white',
        boxShadow: '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
        margin: '30px 10px 80px',
        position: 'relative'
    },
    iconBox: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        color: 'white',
        width: '80px',
        height: '80px',
        marginTop: '-20px',
        marginLeft: '20px',
        borderRadius: '6px',
        boxShadow: '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
        '& svg': {
            width: '3rem !important',
            height: '3rem !important',
        }
    },
    headerBox: {
        position: 'absolute',
        right: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        marginRight: '20px',
        marginTop: '20px',
        textAlign: 'right',
        '& p': {
            color: '#999',
            fontSize: '14px'
        },
        '& span': {
            color: '#999',
            fontSize: '1.56rem'
        }
    },
    iconBoxUser: {
        boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(255 152 0 / 40%)',
        background: 'linear-gradient(60deg,#ffa726,#fb8c00)'
    },
    iconBoxStepPurchased: {
        boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 86 175 / 40%)',
        background: 'linear-gradient(60deg,#6694bb,#5b44a0)'
    },
    iconBoxPurchasedStep: {
        boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(244 67 54 / 40%)',
        background: 'linear-gradient(60deg,#ef5350,#e53935)'
    },
    iconBoxTotalMessageRevieved: {
        boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(12 125 50 / 40%)',
        background: 'linear-gradient(60deg,#159a69,#00c106)'
    },
    iconNotificationActive: {
        boxShadow: 'box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(172 16 195 / 40%)',
        background: 'linear-gradient(60deg,#d6135b, #e01184)'
    },
    iconBoxChallenges: {
        boxShadow: 'box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(255 54 0 / 40%)',
        background: 'linear-gradient(60deg, #d61313, #dc0a0a)'
    },
    iconBoxTraining: {
        boxShadow: 'box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(7 39 13 / 40%)',
        background: 'linear-gradient(60deg,  #0e7324, #5eaf2c)'
    },
    iconBoxBLog: {
        boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(208 199 35 / 40%)',
        background: 'linear-gradient(60deg,#efa850,#ffe001)'
    },
    iconBoxNews: {
        boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(24 7 39 / 40%)',
        background: 'linear-gradient(60deg, #5f0e73, #5e2caf)'
    },
    iconBoxDownload: {
        boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(121 12 125 / 40%)',
        background: 'linear-gradient(60deg,#9a1571,#740aab)'
    },
    iconBoxProduct: {
        boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(24 7 39 / 40%)',
        background: 'linear-gradient(60deg,#a70808, #e60c3e)'
    },
    infoBox: {
        margin: '30px 20px 0',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        paddingTop: '10px',
        fontSize: '12px',
        color: 'darkgray'
    }

})
export default classes;

