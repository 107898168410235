import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import moment from 'moment';
import clsx from 'clsx';
import { Formik } from 'formik';
import styles from './styles';
import { Context } from './../../../../context';
import { useTranslation } from 'react-i18next';
import {
    withStyles,
    Box,
    FormControl,
    TextField,
    Grid,
    Paper,
    FormLabel
} from '@material-ui/core';
import SubmitButton from '../../../../components/SubmitButton';
import * as apiService from '../../../../utils/api';
import * as actionTypes from '../../../../actions';




const defaultInitialValues = {
    type: 'manual',
    runAt: moment(new Date()).format("YYYY-MM-DDTkk:mm"),
    title_pt: '',
    body_pt: '',
    body_en: '',
    title_en: '',
    body_es: '',
    title_es: '',
    body_pl: '',
    title_pl: ''
}



const Form = ({
    classes,
    lng,
    notification,
    edit
}) => {


    const { state, dispatch } = useContext(Context);
    const { t } = useTranslation();

    return (
        <Formik
            initialValues={notification ? getNotificationDataForm(notification) : defaultInitialValues}
            onSubmit={async (values, { resetForm }) => { 
                try {
                    if (!edit) {
                        const dataPost = [values]
                        await apiService.createNotifications(dataPost)
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })
                        resetForm()
                    } else {
                        const dt = {
                            ...values,
                            _id: notification._id
                        }
                        const dataPost = [dt]
                        await apiService.updateNotification(dataPost)
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })
                    }
                } catch (error) {
                    dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('updated error') } })
                }
            }}
        >
            {props => {
                return (
                    <form onSubmit={props.handleSubmit}>
                        <div className={classes.root}>
                            <Grid item xs>
                                <FormControl fullWidth style={{ marginBottom: '20px' }}>
                                    <FormLabel >{t('date')}</FormLabel>
                                    <TextField
                                        id="runAt"
                                        type="datetime-local"
                                        className={classes.textField}
                                        value={props.values.runAt}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                    />
                                </FormControl>
                            </Grid>
                            <Box id="box-pt" className={clsx({ [classes.noDisplay]: lng !== 'pt' })}>
                                <Paper elevation={3} className={classes.paperSimpleMessage}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="title_pt"
                                                    variant='outlined'
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('title')}`}
                                                    value={props.values.title_pt}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    multiline
                                                    rows={4}
                                                    id="body_pt"
                                                    placeholder=""
                                                    variant='outlined'
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('message')}`}
                                                    value={props.values.body_pt}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.body_pt && props.touched.body_pt}
                                                    helperText={(props.errors.body_pt && props.touched.body_pt) && props.errors.body_pt}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Paper>   
                            </Box>
                            <Box id="box-en" className={clsx({ [classes.noDisplay]: lng !== 'en' })}>
                                <Paper elevation={3} className={classes.paperSimpleMessage}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="title_en"
                                                    variant='outlined'
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('title')}`}
                                                    value={props.values.title_en}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    multiline
                                                    rows={4}
                                                    id="body_en"
                                                    placeholder=""
                                                    variant='outlined'
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('message')}`}
                                                    value={props.values.body_en}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.body_en && props.touched.body_en}
                                                    helperText={(props.errors.body_en && props.touched.body_en) && props.errors.body_en}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Paper> 
                            </Box>
                            <Box id="box-es" className={clsx({ [classes.noDisplay]: lng !== 'es' })}>
                                <Paper elevation={3} className={classes.paperSimpleMessage}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="title_es"
                                                    variant='outlined'
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('title')}`}
                                                    value={props.values.title_es}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    multiline
                                                    rows={4}
                                                    id="body_es"
                                                    placeholder=""
                                                    variant='outlined'
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('message')}`}
                                                    value={props.values.body_es}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.body_es && props.touched.body_es}
                                                    helperText={(props.errors.body_es && props.touched.body_es) && props.errors.body_es}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Box>
                            <Box id="box-pl" className={clsx({ [classes.noDisplay]: lng !== 'pl' })}>
                                <Paper elevation={3} className={classes.paperSimpleMessage}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="title_pl"
                                                    variant='outlined'
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('title')}`}
                                                    value={props.values.title_pl}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    multiline
                                                    rows={4}
                                                    id="body_pl"
                                                    placeholder=""
                                                    variant='outlined'
                                                    autoComplete="off"
                                                    margin="dense"
                                                    label={`${t('message')}`}
                                                    value={props.values.body_pl}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.errors.body_pl && props.touched.body_pl}
                                                    helperText={(props.errors.body_pl && props.touched.body_pl) && props.errors.body_pl}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Paper>  
                            </Box>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', margin: '30px 0' }}>
                                <SubmitButton
                                    isSubmitting={props.isSubmitting}>
                                    {t('save')}
                                </SubmitButton>
                            </Grid>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

Form.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Form)

const getNotificationDataForm = (notification) => {
    let dataForm = { ...notification }
    dataForm['runAt'] = moment(new Date(notification.runAt)).format("YYYY-MM-DDTkk:mm")
    return dataForm
}