import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './styles';
import {
    withStyles,
    Grid,
    FormControl,
    TextField,
    Container,
    Card,
    CardContent
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import LockIcon from '@material-ui/icons/Lock';
import EmailIcon from '@material-ui/icons/Email';
import SubmitButton from '../../../components/SubmitButton';
import { Context } from './../../../context';
import * as actionTypes from './../../../actions';
import Footer from './../../../layouts/Main/components/Footer';
import { setToken, setUser } from './../../../utils/storage';
import { login } from '../../../utils/api';


const Login = ({
    classes
}) => {

    const { t } = useTranslation();

    const history = useHistory();

    const { state, dispatch } = useContext(Context);


    const formik = useFormik({
        validateOnMount: false,
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .required(t('required field')),
            password: Yup.string()
                .required(t('required field'))
        }),
        onSubmit: async (values) => {
            try {
                const resp = await login(values)
                const user = resp.data.content.user;
                if (user.role === 'client') {
                    formik.setErrors({ login: t('general error') });
                } else {
                    const token = resp.data.content.access_token;
                    await setToken(token)
                    await setUser(user)
                    dispatch({ type: actionTypes.SESSION_LOGIN, payload: { user, token } })
                    history.push('/dashboard')
                }
            } catch (error) {
                formik.setErrors({ login: t('general error') });
            }
        }
    })

    return (
        <>
            <div className={classes.root}>
                <Container maxWidth="sm">
                    <Card className={classes.card}>
                        <div className={classes.cardHeader}>
                            <h2>{t('login')}</h2>
                        </div>
                        <CardContent>
                            {!!formik.errors.login && (
                                <Alert severity="error">{formik.errors.login}</Alert>
                            )}
                            <form className={classes.form} onSubmit={formik.handleSubmit}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="email"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        margin="normal"
                                                        variant="standard"
                                                        label="Email"
                                                        value={formik.values.email}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.errors.email && formik.touched.email}
                                                        helperText={(formik.errors.email && formik.touched.email) && formik.errors.email}
                                                        InputProps={{
                                                            endAdornment: <EmailIcon />
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        type="password"
                                                        id="password"
                                                        autoComplete="off"
                                                        label="Password"
                                                        margin="normal"
                                                        variant="standard"
                                                        value={formik.values.password}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.errors.password && formik.touched.password}
                                                        helperText={(formik.errors.password && formik.touched.password) && formik.errors.password}
                                                        InputProps={{
                                                            endAdornment: <LockIcon />
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container justify="flex-end" alignItems="center" spacing={4}>
                                            {/*<Grid item >
                                                <Link
                                                    className={classes.link}
                                                    to="/auth/forgot-password">{t('recover password')}</Link>
                                            </Grid>
                                            */}
                                            <Grid item>
                                                <SubmitButton
                                                    isSubmitting={formik.isSubmitting}>
                                                    {t('login')}
                                                </SubmitButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                      
                    </Card>
                </Container>
                <div className={classes.boxFooter}>
                    <Footer transparent={true}/>                                       
                </div>
            </div>
            
            
        </>
    )
}

Login.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Login)
