import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useLocation, useHistory } from 'react-router-dom';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    withStyles,
    Grid,
    FormControl,
    TextField
} from '@material-ui/core';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
import SubmitButton from './../../../components/SubmitButton';
import * as apiService from './../../../utils/api';
import * as actionTypes from './../../../actions';
import { Context } from './../../../context';


const ReplyMessage = ({
    classes,
    match
}) => {
    
    const { state, dispatch } = useContext(Context);

    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory()
    const [message, setMessage] = useState(null)

  

 

    const formik = useFormik({
        validateOnMount: false,
        initialValues: {
            name: '',
            email: '',
            message: '',
            replyMessage: ''
        },
        validationSchema: Yup.object().shape({
            replyMessage: Yup.string()
                .required(t('required field'))
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    text: values.replyMessage,
                    receiver: match.params.id
                }
                const resp = await apiService.sendMessage(postData)
                dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('reply msg successfully') } })
                history.goBack()
            } catch (error) {
                formik.setErrors({ login: t('reply error msg') });
                dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('reply error msg') } })
            }
        }
    })

    useEffect(() => {
        const msg = location.state.message
       //setMessage(location.state.message)
        const dataForm = {
            name: msg.sender.email,
            email: msg.sender.email,
            message: msg.text,
            replyMessage: ''
        }
        formik.setValues(dataForm)
        return () => {
            //
        }
    }, [location.state.message])




    return (
        <div className={classes.root}>
            <div className={classes.iconBox}>
                <ModeCommentIcon />
            </div>
            <div className={classes.headerBox}>
                <h3>{t('reply message')}</h3>
            </div>
            <div className={classes.contentBox}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="name"
                                    label={t('name')}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={formik.values.name}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="email"
                                    label={t('email')}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={formik.values.email}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="message"
                                    label={t('message')}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    multiline
                                    rows={5}
                                    value={formik.values.message}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="replyMessage"
                                    label={t('reply message')}
                                    value={formik.values.replyMessage}
                                    onChange={formik.handleChange('replyMessage')}
                                    onBlur={formik.handleBlur('replyMessage')}
                                    multiline
                                    rows={5}
                                    error={formik.errors.replyMessage && formik.touched.replyMessage}
                                    helperText={(formik.errors.replyMessage && formik.touched.replyMessage) && formik.errors.replyMessage}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', margin: '30px 0' }}>
                            <SubmitButton
                                isSubmitting={formik.isSubmitting}>
                                {t('send')}
                            </SubmitButton>
                        </Grid>
                    </Grid>
                </form>    
            </div>
        </div>
    )
}

ReplyMessage.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(ReplyMessage)
