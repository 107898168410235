import backgroundImage from './../../../../assets/images/sidebar-background.jpg';

const classes =  theme => ({
    root: {
        height: '100%',
        overflowY: 'auto',
        width: '320px',
        minWidth: '320px',
        backgroundSize: 'cover',
        backgroundImage: `url(${backgroundImage})`,
        position: 'relative',
        color: 'white',
        boxShadow: '0 16px 38px -12px rgba(0, 0, 0, .56), 0 4px 25px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2)'
    },
    sideBarContent: {
        padding: theme.spacing(2),
        width: '100%',
        position: 'relative',
        zIndex: 2,
        paddingRight: '2px',
    },
    profileBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content'
    },
    avatar: {
        width: 60,
        height: 60,
        border: '2px solid white'
    },
    divider: {
        marginTop: theme.spacing(2)
    },
    navigation: {
        marginTop: theme.spacing(2),
        height: '560px',
        overflowY: 'auto'
    },
    boxLanguage: {
        padding: '10px 0'
    },
    name: {
        fontSize: '16px',
        padding: '5px 0', 
        fontWeigth: 500
    },
    menuUserBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderTop: '1px solid #ffffff3d',
        borderBottom: '1px solid #ffffff3d',
        width: '100%',
        paddingTop: '22px',
        paddingBottom: '22px'
    },
    overlay: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: '#191919a6',
        top: '0',
        zIndex: -1,
        right: 0
    },
    boxLogo: {
        width: '197px',
        paddingTop: '20px',
        paddingBottom: '20px',
        marginLeft: 'auto',
        marginRight: 'auto',
        '& img': {
            width: '100%',
            height: '100%'
        }
    },
    item: {
        color: 'white',
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
        '& .MuiButtonBase-root': {
            color: 'white',
            padding: '10px 8px',
            justifyContent: 'flex-start',
            textTransform: 'none',
            letterSpacing: 0,
            width: '100%'
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
        }
    },
    icon: {
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    }
})

export default classes;