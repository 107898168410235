import React, { useState, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
    withStyles,
    Collapse,
    ListItem,
    Button
} from '@material-ui/core';
import styles from './styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));



const NavigationItem = ({
    classes,
    title, 
    icon: Icon,
    link,
    depth,
    children
}) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen(open => !open);
    };

    let paddingLeft = 8;
    if (depth > 0) {
        paddingLeft = 32 + 8 * depth;
    }

    const style = {
        paddingLeft
    };


    let render = null;
    if (children) {
        render = (
            <ListItem
                className={classes.itemWithChild}
                disableGutters >
                <Button
                    onClick={handleToggle}
                    style={style}>
                     {Icon && <Icon className={classes.icon} />}
                     {t(title)}
                    {open ? (
                        <ExpandLess
                            className={classes.expandIcon}
                            color="inherit"
                        />
                    ) : (
                        <ExpandMore
                            className={classes.expandIcon}
                            color="inherit"
                        />
                        )}
                </Button>
                <Collapse in={open}>
                    {children}
                </Collapse>
            </ListItem>
        )
    } else {
        render = (
            <ListItem
                className={classes.item}
                disableGutters >
                <Button
                    activeClassName={classes.active}
                    component={CustomRouterLink}
                    to={link}
                    style={style}
                >
                    {Icon && <Icon className={classes.icon} />}
                    {t(title)}
                </Button>
            </ListItem>
        )
    }

    
 

    return (
        <>
            {render}
        </>
     )
}

NavigationItem.propTypes = {
    classes: PropTypes.object,
    page: PropTypes.object,
    children: PropTypes.node,
    className: PropTypes.string,
    depth: PropTypes.number.isRequired,
    link: PropTypes.string,
    icon: PropTypes.any,
    title: PropTypes.string.isRequired
}


export default compose (
    withStyles(styles)
)(NavigationItem)
