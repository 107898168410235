import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import clsx from 'clsx';
import {  Formik } from 'formik';
import styles from './styles';
import { Context } from './../../../../context';
import { useTranslation } from 'react-i18next';
import {
    withStyles,
    Box,
    FormControl,
    TextField,
    Grid,
    FormLabel,
    InputAdornment
} from '@material-ui/core';
import SubmitButton from '../../../../components/SubmitButton';
import * as apiService from '../../../../utils/api';
import * as actionTypes from '../../../../actions';







const defaultInitialValues = {
    type: 'auto-weight',
    titleLow_pt: '',
    bodyLow_pt: '',
    bodyHigh_pt: '',
    bodyBetween_pt: '',
    titleLow_en: '',
    bodyLow_en: '',
    bodyHigh_en: '',
    bodyBetween_en: '',
    titleLow_es: '',
    bodyLow_es: '',
    bodyHigh_es: '',
    bodyBetween_es: '',
    titleLow_pl: '',
    bodyLow_pl: '',
    bodyHigh_pl: '',
    bodyBetween_pl: '',
    low: '',
    high: '',
    betweenLow: '',
    betweenHigh: '',
    bodyLow_pt: '',
    titleHigh_pt: '',
    titleHigh_en: '',
    titleHigh_es: '',
    titleHigh_pl: '',
    titleBetween_pt: '',
    titleBetween_en: '',
    titleBetween_es: '',
    titleBetween_pl: '',
    step: null,
    week: 1
}




const Form = ({
    classes,
    lng,
    notification,
    edit
}) => {

    const { state, dispatch } = useContext(Context);
    const { t, i18n } = useTranslation();



 


    return (
        <Formik
            initialValues={defaultInitialValues}
            onSubmit={async (values, { resetForm }) => {
                try {
                    const dataLow = {
                        body_pt: values.bodyLow_pt,
                        body_en: values.bodyLow_en,
                        body_es: values.bodyLow_es,
                        body_pl: values.bodyLow_pt,
                        title_pt: values.titleLow_pt,
                        title_en: values.titleLow_en,
                        title_es: values.titleLow_es,
                        title_pl: values.titleLow_pt,
                        low: values.low,
                        type: values.type
                    }
                    const betweenLow = {
                        body_pt: values.bodyBetween_pt,
                        body_en: values.bodyBetween_en,
                        body_es: values.bodyBetween_es,
                        body_pl: values.bodyLow_pt,
                        title_pt: values.titleBetween_pt,
                        title_en: values.titleBetween_en,
                        title_es: values.titleBetween_es,
                        title_pl: values.titleBetween_pt,
                        low: values.betweenLow,
                        high: values.betweenHigh,
                        type: values.type
                    }
                    const dataHigh = {
                        body_pt: values.bodyHigh_pt,
                        body_en: values.bodyHigh_en,
                        body_es: values.bodyHigh_es,
                        body_pl: values.bodyHigh_pt,
                        title_pt: values.titleHigh_pt,
                        title_en: values.titleHigh_en,
                        title_es: values.titleHigh_es,
                        title_pl: values.titleHigh_pt,
                        high: values.high,
                        type: values.type
                    }
                   
                   
                    await apiService.createNotifications([dataLow, betweenLow, dataHigh ])
                    resetForm()
                    dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })
                } catch (error) {
                    dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('updated error') } })
                }
            }}
        >
            {props => {
                return (
                    <form onSubmit={props.handleSubmit}>
                        <div className={classes.root}>
                            <Box id="box-pt" className={clsx({ [classes.noDisplay]: lng !== 'pt' })}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="titleLow_pt"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.titlelow_pt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                            <FormLabel style={{ marginRight: '20px' }}>{t('less than')}</FormLabel>
                                            <TextField
                                                id="low"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.low}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                            />
                                            <TextField style={{ marginLeft: '30px', display: 'flex', flexGrow: 1 }}
                                                id="bodyLow_pt"
                                                label={t('message')}
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.bodyLow_pt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="titleBetween_pt"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.titleBetween_pt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <FormLabel style={{ marginRight: '20px' }}>{t('between')}</FormLabel>
                                            <TextField
                                                id="betweenLow"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.betweenLow}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                            />
                                            <FormLabel style={{ marginRight: '20px', marginLeft: '20px' }}>{t('and')}</FormLabel>
                                            <TextField
                                                id="betweenHigh"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.betweenHigh}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                            />
                                            <TextField style={{ marginLeft: '30px', display: 'flex', flexGrow: 1 }}
                                                id="bodyBetween_pt"
                                                label={t('message')}
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.bodyBetween_pt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="titleHigh_pt"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.titleHigh_pt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <FormLabel style={{ marginRight: '20px' }}>{t('more than')}</FormLabel>
                                            <TextField
                                                id="high"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.high}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                            />
                                            <TextField style={{ marginLeft: '30px', display: 'flex', flexGrow: 1 }}
                                                id="bodyHigh_pt"
                                                label={t('message')}
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.bodyHigh_pt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box id="box-en" className={clsx({ [classes.noDisplay]: lng !== 'en' })}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="titleLow_en"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_en}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <FormLabel style={{ marginRight: '20px' }}>{t('less than')}</FormLabel>
                                            <TextField
                                                id="low"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.low}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                            />
                                            <TextField style={{ marginLeft: '30px', display: 'flex', flexGrow: 1 }}
                                                id="bodyLow_en"
                                                label={t('message')}
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.bodyLow_en}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="titleBetween_en"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.titleBetween_en}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <FormLabel style={{ marginRight: '20px' }}>{t('between')}</FormLabel>
                                            <TextField
                                                id="betweenLow"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.betweenLow}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                            />
                                            <FormLabel style={{ marginRight: '20px', marginLeft: '20px' }}>{t('and')}</FormLabel>
                                            <TextField
                                                id="betweenHigh"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.betweenHigh}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                            />
                                            <TextField style={{ marginLeft: '30px', display: 'flex', flexGrow: 1 }}
                                                id="bodyBetween_en"
                                                label={t('message')}
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.bodyBetween_en}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="titleHigh_en"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.titleHigh_en}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <FormLabel style={{ marginRight: '20px' }}>{t('more than')}</FormLabel>
                                            <TextField
                                                id="high"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.high}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                            />
                                            <TextField style={{ marginLeft: '30px', display: 'flex', flexGrow: 1 }}
                                                id="bodyHigh_en"
                                                label={t('message')}
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.bodyHigh_en}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box id="box-es" className={clsx({ [classes.noDisplay]: lng !== 'es' })}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="titleLow_es"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_es}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <FormLabel style={{ marginRight: '20px' }}>{t('less than')}</FormLabel>
                                            <TextField
                                                id="low"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.low}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                            />
                                            <TextField style={{ marginLeft: '30px', display: 'flex', flexGrow: 1 }}
                                                id="bodyLow_es"
                                                label={t('message')}
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.bodyLow_es}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="titleBetween_es"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.titleBetween_pt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <FormLabel style={{ marginRight: '20px' }}>{t('between')}</FormLabel>
                                            <TextField
                                                id="betweenLow"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.betweenLow}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                            />
                                            <FormLabel style={{ marginRight: '20px', marginLeft: '20px' }}>{t('and')}</FormLabel>
                                            <TextField
                                                id="betweenHigh"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.betweenHigh}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                            />
                                            <TextField style={{ marginLeft: '30px', display: 'flex', flexGrow: 1 }}
                                                id="bodyBetween_es"
                                                label={t('message')}
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.bodyBetween_es}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="titleHigh_es"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.titleHigh_es}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <FormLabel style={{ marginRight: '20px' }}>{t('more than')}</FormLabel>
                                            <TextField
                                                id="high"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.high}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                            />
                                            <TextField style={{ marginLeft: '30px', display: 'flex', flexGrow: 1 }}
                                                id="bodyHigh_es"
                                                label={t('message')}
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.bodyHigh_es}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box id="box-pl" className={clsx({ [classes.noDisplay]: lng !== 'pl' })}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="titleLow_pl"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_pl}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <FormLabel style={{ marginRight: '20px' }}>{t('less than')}</FormLabel>
                                            <TextField
                                                id="low"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.low}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                            />
                                            <TextField style={{ marginLeft: '30px', display: 'flex', flexGrow: 1 }}
                                                id="bodyLow_pl"
                                                label={t('message')}
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.bodyLow_pl}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="titleBetween_pl"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.titleBetween_pt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <FormLabel style={{ marginRight: '20px' }}>{t('between')}</FormLabel>
                                            <TextField
                                                id="betweenLow"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.betweenLow}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                            />
                                            <FormLabel style={{ marginRight: '20px', marginLeft: '20px' }}>{t('and')}</FormLabel>
                                            <TextField
                                                id="betweenHigh"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.betweenHigh}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                            />
                                            <TextField style={{ marginLeft: '30px', display: 'flex', flexGrow: 1 }}
                                                id="bodyBetween_pl"
                                                label={t('message')}
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.bodyBetween_pl}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="titleHigh_pl"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.titleHigh_pt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <FormLabel style={{ marginRight: '20px' }}>{t('more than')}</FormLabel>
                                            <TextField
                                                id="high"
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.high}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                            />
                                            <TextField style={{ marginLeft: '30px', display: 'flex', flexGrow: 1 }}
                                                id="bodyHigh_pl"
                                                label={t('message')}
                                                variant='outlined'
                                                autoComplete="off"
                                                margin="dense"
                                                value={props.values.bodyHigh_pl}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', margin: '30px 0' }}>
                                <SubmitButton
                                    isSubmitting={props.isSubmitting}>
                                    {t('save')}
                                </SubmitButton>
                            </Grid>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

Form.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Form)