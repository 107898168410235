import palette from './../palette';

export default {
        root: {
           //backgroundColor: 'red'  
        },
        colorPrimary: {
            backgroundColor: '#cc3024' 
        }
};

