import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { FieldArray, Formik } from 'formik';
import styles from './styles';
import { Context } from './../../../../context';
import { useTranslation } from 'react-i18next';
import {
    withStyles,
    Box,
    FormControl,
    TextField,
    Grid,
    Paper,
    FormLabel
} from '@material-ui/core';
import SubmitButton from '../../../../components/SubmitButton';
//import RichEditorText from './../../../../components/RichEditorText';
import * as apiService from '../../../../utils/api';
import * as actionTypes from '../../../../actions';







const defaultInitialValues = {
    step: null,
    type: 'auto-step',
    title_pt: '',
    body_pt: new Array(30).fill(''),
    body_en: new Array(30).fill(''),
    title_en: '',
    body_es: new Array(30).fill(''),
    title_es: '',
    body_pl: new Array(30).fill(''),
    title_pl: ''
}








const Form = ({
    classes,
    lng,
    notifications,
    edit
}) => {

    const history = useHistory();
    const { state, dispatch } = useContext(Context);
    const { t, i18n } = useTranslation();
    const [steps, setSteps] = useState([])


    useEffect(() => {
        const fetchSteps = async () => {
            try {
                const resp = await apiService.getSteps();
                const stepsWithoutnotifications = resp.data.content.filter(step => !step.hasNotifications)
                setSteps(stepsWithoutnotifications)
            } catch (error) {
            }
        }
        fetchSteps()
    }, [])


    return (
        <Formik
            enableReinitialize={true}
            initialValues={notifications ? { ...getDataForm(notifications) }: defaultInitialValues}
            onSubmit={async (values, { resetForm }) => {
                try {
                    if (edit) {
                        const postData = values.body_pt.map( (item, index) => {
                            const data = {
                                body_pt: values.body_pt[index],
                                body_en: values.body_en[index],
                                body_es: values.body_es[index],
                                body_pl: values.body_pl[index],
                                step: values.step,
                                type: values.type,
                                _id: notifications[index]._id
                            }
                            return data
                        })
                        await apiService.updateNotification(postData)
                    } else {
                        const dataPost = values.body_pt.map((item, index) => {
                            const data = {
                                body_pt: values.body_pt[index],
                                body_en: values.body_en[index],
                                body_es: values.body_es[index],
                                body_pl: values.body_pl[index],
                                step: values.step,
                                type: values.type
                            }
                            return data;
                        })
                        await apiService.createNotifications(dataPost)
                    }
                    //resetForm()
                    history.push(`/notifications/steps`)
                    dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })
                } catch (error) {
                    dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('updated error') } })
                }
            }}
        >
            {props => {
                return (
                    <form onSubmit={props.handleSubmit}>
                        <div className={classes.root}>
                            <Box style={{ margin: '10px 0' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        id="step"
                                        name="step"
                                        select
                                        margin="dense"
                                        SelectProps={{
                                            native: true,
                                        }}
                                        variant="outlined"
                                        value={props.values.step}
                                        onChange={props.handleChange}
                                    >
                                        {steps.map((step) => (
                                            <option key={step._id} value={step._id}>
                                                { step[`title_${i18n.language}`]}
                                            </option>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Box>
                            <Box id="box-pt" className={clsx({ [classes.noDisplay]: lng !== 'pt' })}>
                                <div  className={classes.paperSimpleMessage}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FieldArray name="body_pt">
                                                {({ push, remove }) => (
                                                    <Paper elevation={3} className={classes.paper}>
                                                        <div className={classes.paperHeader}>
                                                            <FormLabel component="legend">{t('messages')}</FormLabel>
                                                        </div>
                                                        {props.values.body_pt.map((message, index) => {
                                                            const messageValue = `body_pt[${index}]`;
                                                            return (
                                                                <Grid container spacing={2}>
                                                                    {/* 
                                                                    <Grid item xs={12}>
                                                                        <RichEditorText
                                                                                id={messageValue}
                                                                                key={messageValue}
                                                                                name="text_pt"
                                                                                label={`${t('day')} ${index + 1}`}
                                                                                value={message}
                                                                                onChange={data => props.setFieldValue(messageValue, data)}
                                                                        />
                                                                    </Grid>
                                                                    
                                                                    */}
                                                                       
                                                                        
                                                                    <Grid item xs={12}>
                                                                        <FormControl fullWidth>
                                                                            <TextField
                                                                                multiline
                                                                                rows={3}
                                                                                id={messageValue}
                                                                                placeholder=""
                                                                                variant='outlined'
                                                                                autoComplete="off"
                                                                                margin="dense"
                                                                                label={`${t('day')} ${index + 1}`}
                                                                                value={message}
                                                                                onChange={props.handleChange(messageValue)}
                                                                                onBlur={props.handleBlur}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                    </Grid>
                                                            )
                                                        })}
                                                    </Paper>
                                                )}
                                            </FieldArray>
                                        </Grid>
                                    </Grid>
                                </div>   
                            </Box>
                            <Box id="box-en" className={clsx({ [classes.noDisplay]: lng !== 'en' })}>
                                <div className={classes.paperSimpleMessage}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FieldArray name="body_en">
                                                {({ push, remove }) => (
                                                    <Paper elevation={3} className={classes.paper}>
                                                        <div className={classes.paperHeader}>
                                                            <FormLabel component="legend">{t('messages')}</FormLabel>
                                                        </div>
                                                        {props.values.body_en.map((message, index) => {
                                                            const messageValue = `body_en[${index}]`;
                                                            return (
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12}>
                                                                        <FormControl fullWidth>
                                                                            <TextField
                                                                                multiline
                                                                                rows={3}
                                                                                id={messageValue}
                                                                                placeholder=""
                                                                                variant='outlined'
                                                                                autoComplete="off"
                                                                                margin="dense"
                                                                                label={`${t('day')} ${index + 1}`}
                                                                                value={message}
                                                                                onChange={props.handleChange(messageValue)}
                                                                                onBlur={props.handleBlur}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                        })}
                                                    </Paper>
                                                )}
                                            </FieldArray>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Box>
                            <Box id="box-es" className={clsx({ [classes.noDisplay]: lng !== 'es' })}>
                                <div className={classes.paperSimpleMessage}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FieldArray name="body_es">
                                                {({ push, remove }) => (
                                                    <Paper elevation={3} className={classes.paper}>
                                                        <div className={classes.paperHeader}>
                                                            <FormLabel component="legend">{t('messages')}</FormLabel>
                                                        </div>
                                                        {props.values.body_es.map((message, index) => {
                                                            const messageValue = `body_es[${index}]`;
                                                            return (
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12}>
                                                                        <FormControl fullWidth>
                                                                            <TextField
                                                                                multiline
                                                                                rows={3}
                                                                                id={messageValue}
                                                                                placeholder=""
                                                                                variant='outlined'
                                                                                autoComplete="off"
                                                                                margin="dense"
                                                                                label={`${t('day')} ${index + 1}`}
                                                                                value={message}
                                                                                onChange={props.handleChange(messageValue)}
                                                                                onBlur={props.handleBlur}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                        })}
                                                    </Paper>
                                                )}
                                            </FieldArray>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Box>
                            <Box id="box-pl" className={clsx({ [classes.noDisplay]: lng !== 'pl' })}>
                                <div className={classes.paperSimpleMessage}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FieldArray name="body_pl">
                                                {({ push, remove }) => (
                                                    <Paper elevation={3} className={classes.paper}>
                                                        <div className={classes.paperHeader}>
                                                            <FormLabel component="legend">{t('messages')}</FormLabel>
                                                        </div>
                                                        {props.values.body_pl.map((message, index) => {
                                                            const messageValue = `body_pl[${index}]`;
                                                            return (
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12}>
                                                                        <FormControl fullWidth>
                                                                            <TextField
                                                                                multiline
                                                                                rows={3}
                                                                                id={messageValue}
                                                                                placeholder=""
                                                                                variant='outlined'
                                                                                autoComplete="off"
                                                                                margin="dense"
                                                                                label={`${t('day')} ${index + 1}`}
                                                                                value={message}
                                                                                onChange={props.handleChange(messageValue)}
                                                                                onBlur={props.handleBlur}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                        })}
                                                    </Paper>
                                                )}
                                            </FieldArray>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Box>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', margin: '30px 0' }}>
                                <SubmitButton
                                    isSubmitting={props.isSubmitting}>
                                    {t('save')}
                                </SubmitButton>
                            </Grid>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

Form.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Form)


const getDataForm = (notifications) => {
    let dataForm = {
        step: notifications[0].step._id,
        type: 'auto-step',
        title_pt: notifications[0].title_pt,
        body_pt: notifications.map(it => it.body_pt),
        body_en: notifications.map(it => it.body_en),
        title_en: notifications[0].title_en,
        body_es: notifications.map(it => it.body_es),
        title_es: notifications[0].title_es,
        body_pl: notifications.map(it => it.body_pl),
        title_pl: notifications[0].title_pl
    }
    return dataForm
}