import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { 
    withStyles,
    Grid,
    Box,
    Switch,
    Fab
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import Table from '../../../../components/Table';
import EditIcon from '@material-ui/icons/Edit';
import LoadingScreen from '../../../../components/LoadingScreen'
import * as apiService from '../../../../utils/api';
import { NetworkWifiSharp } from '@material-ui/icons';

const List = ({
    classes
}) => {

    const { t, i18n } = useTranslation();
    const history = useHistory();
    const confirm = useConfirm();
    const [data, setData] = useState([])
    const [notifications, setNotifications] = useState([])
    const [loading, setLoading] = useState(true)
    const [steps, setSteps] = useState([])


    const actions = [
        {
            icon: () => <EditIcon />,
            tooltip: t('detail'),
            onClick: (event, rowData) => {
                history.push({
                    pathname: `/notifications/steps/edit/${rowData.id}`,
                    state: { step: rowData.step }
                })
            }
        }
    ];


    let columns = [
        { title: t('id'), field: 'id', hidden: true },
        { title: t('step'), field: 'step', hidden: true },
        { title: t('title'), field: 'title' },
        { title: t('type'), field: 'type' },
        { title: t('step'), field: 'stepTitle' },
        //{ title: t('day'), field: 'day' },

    ]


    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const params = { type: 'auto-step' }
                setLoading(true)
                const resp = await apiService.getNotifications(params);
                const ntfs = resp.data.content
                let steps = ntfs.map(it => it.step._id)
                let notifs =  []
                steps = [...new Set(steps)];
                steps.forEach(it => {
                    let nts = ntfs.filter(nt => nt.step._id === it)
                    notifs = [...notifs, nts[0]]
                   /* nts = nts.map((n, i) => {
                        let a = {
                            ...n,
                            day: i+1
                        }
                        return a
                    })
                    notifs = [...notifs, ...nts]
                    */
                })
                setNotifications(notifs)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }

        fetchNotifications()
        return () => {
            //cleanup
        }
    }, [])

    useEffect(() => {
        const setupData = () => {
            const setDataTable = () => {
                return notifications.map((notification) => {
                    return {
                        id: notification._id,
                        step: notification.step._id,
                        title: notification[`title_${i18n.language}`],
                        type: notification.type,
                        stepTitle: notification.step[`title_${i18n.language}`],
                        //day: notification.day
                    }
                })
            }
            setData(setDataTable())
        }
        setupData()
        return () => {
            //
        }
    }, [i18n.language, notifications])

  

  
   

    const handleDeleteClick = (data) => {
        confirm({ title: t( 'are you sure' ), description: t( 'are you sure to delete this item') })
            .then(async () => {
                try {
                    //setLoading(true)
                    //await apiService.deleteStep(data.id);
                    //const resp = await apiService.getSteps();
                    //setSteps(resp.data.content)
                    //setLoading(false)
                } catch (error) {

                }
            })
            .catch(() => { });
    }

 

    return (
        <>
            <div className={classes.root}>
                <div className={classes.iconBox}>
                    <InsertLinkIcon />
                </div>
                <Grid container justify="space-between" spacing={3} >
                    <Grid item className={classes.gridHeader}>
                        <h3>{t('notifications')}</h3>
                    </Grid>
                    <Grid item >
                        <Fab onClick={() => history.push('/notifications/steps/create')} >
                            <AddIcon />
                        </Fab>
                    </Grid>
                </Grid>
                <Box className={classes.boxRoot}>
                    <Table
                        title={null}
                        columns={columns}
                        actions={actions}
                        data={data}
                    />
                </Box>
                {loading ? <LoadingScreen /> : null}
            </div>
        </>
        
    )
}

List.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(List)



