import React, { useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import styles from './styles';
import {
    withStyles,
    FormLabel
} from '@material-ui/core';





const RichEditorText = ({
    classes,
    name,
    label,
    value,
    disabled,
    setFieldValue,
    onChange
}) => {


    const { i18n } = useTranslation();





    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const onEditorStateChange = (data) => {
        setEditorState(data)
    };

 

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        }, 600)

        return () => clearTimeout(delayDebounceFn)
    }, [editorState, onChange])


    useEffect(() => {
        if (value && value.length) {
            try {
                const data = EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(value)
                    )
                )
                setEditorState(data)
            } catch (error) {
                //console.log(error)
            }
        }
    }, [])
 

    


    return (
        <div className={classes.root}>
            <FormLabel>
                {label}
            </FormLabel>
            <div>
                <Editor
                    editorState={editorState}
                    wrapperClassName="editor-wrapper"
                    editorClassName="editor-input"
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        emoji: { className: "no-display" },
                        embedded: { className: "no-display" },
                        history: { inDropdown: true },
                        image: {
                            urlEnabled: true,
                            uploadEnabled: false,
                            alt: { present: true, mandatory: true },
                            previewImage: true,
                            alignmentEnabled: true,
                            defaultSize: { height: 250, width: 300 }
                        }
                    }}
                    localization={{
                        locale: i18n.language,
                    }}
                    readOnly={!!disabled}
                />
            </div>
        </div>
    )
}

RichEditorText.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(RichEditorText)
