import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { 
    withStyles,
    Grid,
    Box,
    Switch,
    Fab
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import LayersIcon from '@material-ui/icons/Layers';
import Table from '../../../components/Table'
import EditIcon from '@material-ui/icons/Edit';
import LoadingScreen from '../../../components/LoadingScreen'
import * as apiService from '../../../utils/api'

const List = ({
    classes
}) => {

    const { t, i18n } = useTranslation();
    const history = useHistory();
    const confirm = useConfirm();
    const [data, setData] = useState([])
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(true)


    const actions = [
        {
            icon: 'delete',
            tooltip: t('delete'),
            onClick: (event, rowData) => { handleDeleteClick(rowData) }
        },
        {
            icon: () => <EditIcon />,
            tooltip: t('detail'),
            onClick: (event, rowData) => {
                history.push(`/products/edit/${rowData.id}`)
            }
        }
    ];

    let columns = [
        { title: t('id'), field: 'id', hidden: true },
        { title: t('title'), field: 'title' },
        { title: t('link'), field: 'link' },
        { title: t('publish'), field: 'publish' }
    ]


    useEffect(() => {
        const fetchproducts = async () => {
            try {
                setLoading(true)
                const resp = await apiService.getProducts();
                setProducts(resp.data.content)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchproducts()
        return () => {
            //cleanup
        }
    }, [])

    useEffect(() => {
        const setupData = () => {
            const setDataTable = () => {
                return products.map(product => {
                    return {
                        id: product._id,
                        title: product[`title_${i18n.language}`],
                        link: product[`link_${i18n.language}`],
                        publish: (
                            <Switch
                                onChange={() => handlePublishClick(product)}
                                size="small"
                                checked={product.published}
                                color="primary"
                            />
                        )
                    }
                })
            }
            setData(setDataTable())
        }
        setupData()
        return () => {
            //
        }
    }, [i18n.language, products])

  

    const handlePublishClick = (data) => {
        const info = data.published ? 'are you sure to unpublish this item' : 'are you sure to publish this item'
        confirm({ title: t('are you sure'), description: t(info) })
            .then(async () => {
                try {
                    setLoading(true)
                    await apiService.publishProduct(data._id);
                    const resp = await apiService.getProducts();
                    setProducts(resp.data.content)
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                }
            })
            .catch(() => { });
    }

    const handleDeleteClick = (data) => {
        confirm({ title: t( 'are you sure' ), description: t( 'are you sure to delete this item') })
            .then(async () => {
                try {
                    setLoading(true)
                    await apiService.deleteProduct(data.id);
                    const resp = await apiService.getProducts();
                    setProducts(resp.data.content)
                    setLoading(false)
                } catch (error) {

                }
            })
            .catch(() => { });
    }

   

    return (
        <>
            <div className={classes.root}>
                <div className={classes.iconBox}>
                    <LayersIcon />
                </div>
                <Grid container justify="space-between" spacing={3} >
                    <Grid item className={classes.gridHeader}>
                        <h3>{t('products')}</h3>
                    </Grid>
                    <Grid item >
                        <Fab onClick={() => history.push('/products/create')}>
                            <AddIcon />
                        </Fab>
                    </Grid>
                </Grid>
                <Box className={classes.boxRoot}>
                    <Table
                        title={null}
                        columns={columns}
                        actions={actions}
                        data={data}
                    />
                </Box>
                {loading ? <LoadingScreen /> : null}
            </div>
        </>
        
    )
}

List.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(List)



