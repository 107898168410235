import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { 
    withStyles,
    Grid,
    Box,
    Checkbox,
    Fab
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import Table from '../../../components/Table'
import Generate from './../Generate';
import LoadingScreen from '../../../components/LoadingScreen'
import Modal from '../../../components/Modal'
import * as apiService from '../../../utils/api'

const List = ({
    classes
}) => {

    const { t, i18n } = useTranslation();
    const [data, setData] = useState([])
    const [stepCodes, setStepCodes] = useState([])
    const [loading, setLoading] = useState(true)
    const [showGenerate, setGhowGenerate] = useState(false)


    

    let columns = [
        { title: t('id'), field: 'id', hidden: true },
        { title: t('code'), field: 'code' },
        { title: t('step'), field: 'step' },
        { title: t('used'), field: 'used' },
        { title: t('create date'), field: 'createdAt' }
    ]

    const stepCodeCreateHandler = () => {
        setGhowGenerate(true)

    }

    const handleCloseGenerate = () => {
        setGhowGenerate(false)
    }


    useEffect(() => {
        const fetchStepCodes = async () => {
            try {
                setLoading(true)
                const resp = await apiService.getStepCodes();
                const stepsCode = resp.data.content
                const stpCodesSorted = stepsCode.sort((a, b) => {
                    return (new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                })
                setStepCodes(stpCodesSorted)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
      
        fetchStepCodes()
        return () => {
            //cleanup
        }
    }, [])

    useEffect(() => {
        const setupData = () => {
            const setDataTable = () => {
                return stepCodes.map(stepCode => {
                    return {
                        id: stepCode._id,
                        code: stepCode.code,
                        step: stepCode.step ? stepCode.step[`title_${i18n.language}`] : '',
                        createdAt: stepCode.hasOwnProperty('createdAt') ? `${moment(stepCode.createdAt).format('lll')}` : null,
                        used: (
                              <Checkbox
                                  style={{cursor: 'default'}}
                                  checked={stepCode.used}
                                  color="primary"
                            />
                         )
                    }
                })
            }
            setData(setDataTable())
        }
        setupData()
        return () => {
            //
        }
    }, [i18n.language, stepCodes])

  

  

    return (
        <>
            <div className={classes.root}>
                <div className={classes.iconBox}>
                    <ViewWeekIcon />
                </div>
                <Grid container justify="space-between" spacing={3}>
                    <Grid item className={classes.gridHeader}>
                        <h3>{t('step codes')}</h3>
                    </Grid>
                    <Grid item >
                          <Fab onClick={stepCodeCreateHandler}>
                            <AddIcon />
                        </Fab>
                    </Grid>
                </Grid>
                <Box className={classes.boxRoot}>
                    <Table
                        title={null}
                        columns={columns}
                        actions={null}
                        data={data}
                    />
                </Box>
                {loading ? <LoadingScreen /> : null}
                {showGenerate ? (
                    <Modal open={showGenerate} title={t('generate step codes')} onClose={handleCloseGenerate}>
                        <Generate />
                    </Modal>
                ) : null}
            </div>
        </>
        
    )
}

List.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(List)




