const classes = theme => ({
    root: {
        padding: '30px 10px',
        borderBottom: '1px solid #dedede'
    },
    content: {
        padding: '0px 20px',
        display: 'flex',
        justifyContent: 'space-between'
    }
})
export default classes;