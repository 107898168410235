import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Context } from '../../../context';
import RichEditorText from '../../../components/RichEditorText';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    withStyles,
    FormControl,
    Grid,
    TextField,
    Box
} from '@material-ui/core';
import { Formik, } from 'formik';
import FileDropzone from '../../../components/FileDropzone';
import SubmitButton from '../../../components/SubmitButton';
import { toBase64, getFileDto } from '../../../utils/util'
import * as apiService from '../../../utils/api';
import * as actionTypes from '../../../actions';




const RECEPES_TYPE = [
    { id: 'main', name: 'main' },
    { id: 'breakfast', name: 'breakfast' },
    { id: 'snack', name: 'snack' }
]





const defaultInitialValues = {
    type: 'main',
    title_pt: '',
    text_pt: '',
    title_en: '',
    text_en: '',
    title_es: '',
    text_es: '',
    title_pl: '',
    text_pl: '',
    published: false
}



const Form = ({
    classes,
    lng,
    recipe,
    edit
}) => {

    const { state, dispatch } = useContext(Context);
    const { t } = useTranslation();
    const [images, setImages] = useState({ image: null })
    const [disabled, setDisabled] = useState(false)










    const onUploadImages = async (fls) => {
        if (disabled) {
            return null
        }
        const fl = fls[0]
        let preview = null;
        let resp = null;
        let file = null;
        if (fl) {
            preview = URL.createObjectURL(fl) 
            resp = await toBase64(fl)
            file = {
                file: resp,
                type: fl.type,
                filename: `${new Date().getTime()}_${fl.name}`,
                preview: preview
            }
        }
        let filesCopy = { ...images }
        //filesCopy[`image_${lng}`] = file
        filesCopy[`image`] = file

        setImages(filesCopy)
    }

  

  
    useEffect(() => {
        const setupPermissionEdit = () => {
            if (state?.role === 'admin') {
                const disabled = (state?.user.language !== lng)
                setDisabled(disabled)
            }
        }
        if (recipe) {
            const imgs = {
                image: recipe.image
            }
            setImages(imgs)
        }
        setupPermissionEdit()

    }, [lng, recipe, state?.role, state?.user.language])



  

    
    return (
        <Formik
            enableReinitialize={false}
            initialValues={recipe && Object.keys(recipe).length !== 0 ? getDataForm(recipe) : defaultInitialValues}
            onSubmit={async (values, { resetForm }) => {
                try {
                    const imgs = {
                        image: await getFileDto(images.image)
                    }

                    let data = {
                        ...getDto(values),
                        ...getImages(imgs)
                    }
                    if (edit) {
                        data['_id'] = recipe._id
                        await apiService.updateRecipe(recipe._id, data)
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })

                    } else {
                        await apiService.createRecipe(data)
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t('updated successfully') } })

                    }
                } catch (error) {
                    if (edit) {
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('updated error') } })
                    } else {
                        dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t('updated error') } })
                    }
                }
            }}
        >   
            {props => {

             
              
                return (
                    <form onSubmit={props.handleSubmit}>
                        <div className={classes.root}>
                            <Box style={{ margin: '10px 0' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        id="type"
                                        name="type"
                                        select
                                        margin="dense"
                                        SelectProps={{
                                            native: true,
                                        }}
                                        variant="outlined"
                                        value={props.values.type}
                                        onChange={props.handleChange}
                                    >
                                        {RECEPES_TYPE.map((trainingType) => (
                                            <option key={trainingType.id} value={trainingType.id}>
                                                {t(trainingType.name)}
                                            </option>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Box>
                            <Box id="box-pt" className={clsx({ [classes.noDisplay]: lng !== 'pt'})}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                disabled={disabled}
                                                variant="outlined"
                                                id="title_pt"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_pt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_pt && props.touched.title_pt}
                                                helperText={(props.errors.title_pt && props.touched.title_pt) && props.errors.title_pt}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <RichEditorText
                                                name="text_pt"
                                                label={t('description')}
                                                value={props.values.text_pt}
                                                onChange={data => props.setFieldValue('text_pt', data)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} pdf={false} onAcceptFiles={onUploadImages} data={recipe.image}/>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box id="box-en" className={clsx({ [classes.noDisplay]: lng !== 'en' })}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                disabled={disabled}
                                                variant='outlined'
                                                id="title_en"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_en}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_en && props.touched.title_en}
                                                helperText={(props.errors.title_en && props.touched.title_en) && props.errors.title_en}
                                            />
                                        </FormControl>
                                    </Grid>
                                   
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <RichEditorText
                                                name="text_en"
                                                label={t('text')}
                                                value={props.values.text_en}
                                                onChange={data => props.setFieldValue('text_en', data)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} pdf={false} onAcceptFiles={onUploadImages} data={recipe.image}/>
                                    </Grid>
                                </Grid>
                            </Box>
                           
                            <Box id="box-es" className={clsx({ [classes.noDisplay]: lng !== 'es' })}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                disabled={disabled}
                                                variant='outlined'
                                                id="title_es"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_es}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_es && props.touched.title_es}
                                                helperText={(props.errors.title_es && props.touched.title_es) && props.errors.title_es}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <RichEditorText
                                                name="text_es"
                                                label={t('text')}
                                                value={props.values.text_es}
                                                onChange={data => props.setFieldValue('text_es', data)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} pdf={false} onAcceptFiles={onUploadImages} data={recipe.image}/>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box id="box-pl" className={clsx({ [classes.noDisplay]: lng !== 'pl' })}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                disabled={disabled}
                                                variant='outlined'
                                                id="title_pl"
                                                placeholder=""
                                                autoComplete="off"
                                                margin="dense"
                                                label={`${t('title')}`}
                                                value={props.values.title_pl}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.errors.title_pl && props.touched.title_pl}
                                                helperText={(props.errors.title_pl && props.touched.title_pl) && props.errors.title_pl}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <RichEditorText
                                                name="text_pl"
                                                label={t('text')}
                                                value={props.values.text_pl}
                                                onChange={data => props.setFieldValue('text_pl', data)}
                                             />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileDropzone disabled={disabled} title={t('upload image')} pdf={false} onAcceptFiles={onUploadImages} data={recipe.image}/>
                                    </Grid>
                                </Grid>
                            </Box>
                           
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', margin: '30px 0' }}>
                                <SubmitButton
                                    isSubmitting={props.isSubmitting}>
                                    {t('save')}
                                </SubmitButton>
                            </Grid>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

Form.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Form)

const getDataForm = (step) => {
    return step
} 



const getDto = (values) => {
    const data = {
        type: values.type,
        title_pt: values.title_pt,
        text_pt: values.text_pt,
        title_en: values.title_en,
        text_en: values.text_en,
        title_es: values.title_es,
        text_es: values.text_es,
        title_pl: values.title_pl,
        text_pl: values.text_pl,
        published: values.published

    }
    return data;
}

const getImages = (images) => {
     const data = {}
        const image = images[`image`]
        if (image && !image.hasOwnProperty('_id')) {
            data[`image`] = images[`image`]
        }
    return data
}



