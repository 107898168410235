import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import InfoIcon from '@material-ui/icons/Info';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HelpIcon from '@material-ui/icons/LiveHelp';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import BookIcon from '@material-ui/icons/Book';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import LayersIcon from '@material-ui/icons/Layers';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import TimelineIcon from '@material-ui/icons/Timeline';
import KitchenIcon from '@material-ui/icons/Kitchen';

export const navs_admin =  [
    {
        title: '',
        pages: [
            {
                title: 'dashboard',
                link: '/dashboard',
                icon: DashboardIcon,
            },
            {
                title: 'settings',
                link: '/settings',
                icon: SettingsIcon,
                children: [
                      {
                        title: 'about',
                        link: '/fitGym/about-us',
                        icon: InfoIcon,
                    },
                    {
                        title: 'terms and conditions',
                        link: '/settings/terms-and-conditions',
                        icon: AssignmentIcon,
                    },
                    {
                        title: 'help',
                        link: '/settings/help',
                        icon: HelpIcon,
                    }
                ]
            },
            {
                title: 'recipes',
                link: '/recipes',
                icon: KitchenIcon,
            },
            {
                title: 'steps',
                link: '/steps',
                icon: AllInclusiveIcon,
            },
            {
                title: 'challenges',
                link: '/challenges',
                icon: DirectionsRunIcon,
            },
            {
                title: 'training',
                link: '/training',
                icon: GpsFixedIcon,
            },
            {
                title: 'client',
                link: '/client',
                icon: EmojiPeopleIcon
            },
            {
                title: 'news',
                link: '/news',
                icon: BookIcon
            },
            {
                title: 'blogs',
                link: '/blog',
                icon: LocalLibraryIcon
            },
            {
                title: 'download',
                link: '/download',
                icon: SystemUpdateAltIcon,
            },
            {
                title: 'products',
                link: '/products',
                icon: LayersIcon,
            },
            {
                title: 'notifications',
                link: '/notifications',
                icon: NotificationsIcon,
                children: [
                    {
                        title: 'manual',
                        link: '/notifications/manual',
                        icon: TouchAppIcon,
                    },
                    {
                        title: 'steps',
                        link: '/notifications/steps',
                        icon: BrightnessAutoIcon,
                    },
                    {
                        title: 'progress',
                        link: '/notifications/progress',
                        icon: TimelineIcon,
                    }
                ]
            },
            {
                title: 'generated code',
                link: '/step-codes',
                icon: ViewWeekIcon
            }
        ]
    }
]

export const navs_moderator = [
    {
        title: '',
        pages: [
            {
                title: 'dashboard',
                link: '/dashboard',
                icon: DashboardIcon,
            },
            {
                title: 'client',
                link: '/client',
                icon: EmojiPeopleIcon
            },
        ]
    }
]



